<template>
  <div>
    <div
      class="select-element"
      :class="{
        'select-disabled': disabled,
        active: show,
        selected:
          (valueTitle && !multiple) ||
          (Array.isArray(valueTitle) && valueTitle.length && multiple),
        'select-small': small,
        'select-dark': dark,
      }"
      ref="select"
    >
      <div class="label" v-if="label" @click="showMenu()">{{ label }}</div>
      <div class="select-value-title" @click="showMenu()">
        <span v-if="rangeDateSelect"
          >{{ rangeDateSelect.start }} - {{ rangeDateSelect.end }}</span
        >
        <span v-if="!rangeDateSelect && valueTitle && !multiple">{{
          valueTitle
        }}</span>
        <div
          v-if="!rangeDateSelect && valueTitle && valueTitle.length && multiple"
          class="selected-multiple"
        >
          <span
            v-for="n in visibleItems"
            :key="n"
            class="selected-multiple-item"
            @click.prevent="cancelItem(n)"
            >{{ n }}</span
          >
          <span
            v-if="valueTitle.length > visible"
            class="selected-multiple-item additional"
            >+{{ valueTitle.length - visible }}</span
          >
        </div>

        <span
          v-if="!rangeDateSelect && !valueTitle && placeholder && !multiple"
          class="placeholder"
          >{{ placeholder }}</span
        >
        <span
          v-if="!rangeDateSelect && multiple && placeholder"
          class="placeholder"
          :class="{ active: valueTitle.length }"
          >{{ placeholder }}</span
        >
      </div>
      <div class="select-menu" :class="{ fromBottom: fromBottom }" v-if="show">
        <div class="select-menu-header" v-if="multiple">
          <div @click="selectAll()" class="select">{{ $t('buttons.13') }}</div>
          <div @click="resetAll()" class="reset">{{ $t('buttons.14') }}</div>
        </div>
        <template v-for="option in options" :key="option[title]">
          <div
            v-if="option[option_key] !== undefined"
            class="select-menu-item"
            :class="{
              active: selectedValue(option[option_key]),
              'select-menu-item-multiple': multiple,
            }"
            @click="select(option)"
          >
            {{ option[title] }}
          </div>
          <div v-else class="select-menu-title">
            {{ option[title] }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    options: Array,
    title: {
      type: String,
      default: 'value',
    },
    option_key: {
      type: String,
      default: 'value',
    },
    id: {
      default: 1,
    },
    deselect: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rangeDateSelect: {
      type: Object,
      Array,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Number,
      default: 2,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      show: false,

      fromBottom: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    visibleItems() {
      let result;
      if (this.valueTitle.length >= this.visible && this.multiple) {
        result = [];
        for (let index = 0; index < this.visible; index++) {
          result.push(this.valueTitle[index]);
        }
      } else {
        result = this.valueTitle;
      }
      return result;
    },
    valueTitle() {
      let result;
      if (!this.multiple) {
        this.options.forEach((el) => {
          if (el[this.option_key] == this.value) result = el[this.title];
        });
      }
      if (this.multiple) {
        result = [];
        this.options.forEach((el) => {
          if (this.value && this.value.includes(el[this.option_key]))
            result.push(el[this.title]);
        });
      }
      return result;
    },
  },

  created() {
    document.addEventListener('click', this.closeSelect);
  },

  methods: {
    showMenu() {
      let clientHeight = this.$refs.select.offsetParent.clientHeight;
      let offsetTop = this.$refs.select.offsetTop;
      if (!this.disabled) {
        if (clientHeight - offsetTop - 240 < 0) this.fromBottom = true;
        this.show = !this.show;
      }
    },
    select(e) {
      if (!this.multiple) {
        this.show = !this.show;
        if (this.value == e[this.option_key] && !this.deselect) {
          this.value = null;
          this.$emit('change', e);
          return;
        }
        this.value = e[this.option_key];
      }
      if (this.multiple) {
        if (this.value && this.value.includes(e[this.option_key])) {
          let index = this.value.findIndex((x) => x == e[this.option_key]);
          this.value.splice(index, 1);
          this.$emit('change', e);
          return;
        }
        this.value.push(e[this.option_key]);
      }
      this.$emit('change', e);
    },
    closeSelect(e) {
      let el = this.$refs.select;
      let target = e.target;
      if (el && el !== target && !el.contains(target)) {
        this.show = false;
      }
    },

    cancelItem(e) {
      const val = this.options.find((item) => item[this.title] === e);
      const index = this.value.findIndex((x) => x === val[this.option_key]);
      this.value.splice(index, 1);
      this.$emit('change');
    },

    selectedValue(option) {
      let result = false;
      if (!this.multiple && this.value == option) result = true;
      if (this.multiple && this.value && this.value.includes(option))
        result = true;
      return result;
    },

    selectAll() {
      this.options.forEach((option) => {
        if (
          option[this.option_key] !== undefined &&
          !this.value.includes(option[this.option_key])
        )
          this.value.push(option[this.option_key]);
      });
      this.show = false;
      this.$emit('change');
    },

    resetAll() {
      this.value = [];
      this.show = false;
      this.$emit('change');
    },
  },
};
</script>
