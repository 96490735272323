<template>
    <div v-if="device">

        <div class="treeview-padding">
            <div class="treeview-item" @click="setAction" :class="{'show': device.show_feeder_assets, 'last': last}">
                <img :src="'/svg/assets/' + item.AssetType + (active_asset == item.AssetType ? '_single_active.svg' : '_single.svg')" alt="">
                <div class="item-name"> {{device.name}}</div>
                <span class="loading" v-if="device.loading"></span>
            </div>
        </div>


        <div v-if="down">
            <!-- IF PARENT  IS NOT POLE - 104 -->
            <div v-if="device.assets && device.show_feeder_assets && device.AssetType != 104" class="child">
                <div v-for="asset in device.assets" :key="asset.AssetID" class="treeview-padding">
                    <treeview-asset :item="asset" :active_asset="active_asset" :down="true"></treeview-asset>
                </div>
            </div>

            <!-- IF PARENT IS  POLE - 104 -->
            <div v-if="device.assets && device.AssetType == 104">
                <div v-for="asset in device.assets" :key="asset.AssetID">
                    <treeview-asset :down="true" :active_asset="active_asset" :item="asset"></treeview-asset>
                </div>
            </div>

            <div v-if="device && (!device.assets || (device.assets && !device.assets.length) && device.show_feeder_assets)">
                <!-- TREEVIEW DOWN -->
                <treeview-item-down :item="device" :active_asset="active_asset" :ref="'treeview_' + device.AssetID"></treeview-item-down>
            </div>
        </div>

        <div v-if="up">
            <!-- IF PARENT  IS NOT POLE - 104 -->
            <div v-if="device.assets && device.show_feeder_assets && device.AssetType != 104" class="child">
                <div v-for="asset in device.assets" :key="asset.AssetID" class="treeview-padding">
                    <treeview-item-up :active_item="active_item" :active_asset="active_asset" v-if="asset.parent && up" :item="asset"></treeview-item-up>
                    <treeview-asset  :active_item="active_item" :active_asset="active_asset" :item="asset" :up="true" v-if="!asset.parent"></treeview-asset>
                </div>
            </div>

            <!-- IF PARENT IS  POLE - 104 -->
            <div v-if="device.assets && device.AssetType == 104">
                <div v-for="asset in device.assets" :key="asset.AssetID">
                    <treeview-item-up :active_item="active_item" :active_asset="active_asset" v-if="asset.parent && up" :item="asset"></treeview-item-up>
                    <treeview-asset  :active_item="active_item" :active_asset="active_asset" :item="asset" :up="true" v-if="!asset.parent"></treeview-asset>
                </div>
            </div>

            <div v-if="!device.assets || (device.assets && !device.assets.length)">
                <!--ITEM -->
                <div class="treeview-item active" @click="show_feeder_assets = !show_feeder_assets"
                     :class="{'show': show_feeder_assets, 'last': !active_item.childs.length}">
                    <img :src="'/svg/assets/' + active_item.deviceType + (active_asset == active_item.deviceType ? '_single_active.svg' : '_single.svg')" alt="">
                    <div class="item-name"> {{active_item.Name}}</div>
                </div>

                <!-- TREEVIEW DOWN -->
                <treeview-item-down v-if="show_feeder_assets" :active_asset="active_asset" :item="active_item" :load="true"></treeview-item-down>
            </div>
        </div>
    </div>
</template>

<script>
    import treeviewAsset from './TreeviewAsset';

    export default {
        props: {
            item: Object,
            active_item: Object,
            up: {
                type: Boolean,
                default: false
            },
            down: {
                type: Boolean,
                default: false
            },
            active_asset: {
                default: 1
            }
        },
        components: {
            treeviewAsset
        },

        data() {
            return {
                device: null,
                show_feeder_assets: true
            }
        },

        computed: {
            last() {
                let result = false;
                if (this.device && (!this.device.assets || (this.device.assets && !this.device.assets.length) && (this.device.AssetType != 100 && this.device.AssetType != 102 && this.device.AssetType != 111))) {
                    result = true;
                }
                if(this.device && this.device.AssetType == 104) result = true;
                return result;
            }
        },

        mounted() {
            this.device = this.item;
            console.log(this.active_item)
        },

        methods: {
            setAction() {
                if (this.device && (!this.device.assets || (this.device.assets && !this.device.assets.length)) && !this.device.show_feeder_assets) {
                    if (this.device.AssetType == 100 || this.device.AssetType == 102 || this.device.AssetType == 111) {
                        this.device.loading = true;
                        this.$nextTick(() => {
                            this.$refs['treeview_' + this.device.AssetID].getConnection(this.device, true, 'asset')
                                .then(result => {
                                    if (result) {
                                        this.device.loading = false;
                                    }
                                })

                        })
                    }
                }
                this.device.show_feeder_assets = !this.device.show_feeder_assets;
            }
        }
    }
</script>