<template>
    <div class="dialog-side left" id="dialog-asset">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div class="dialog-header-actions">
                    <div class="card-drag" id="drag-asset">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                    <svg @click="collapseItem()" v-if="!getTreeviewModal.collapse" class="card-collapse"
                         viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#909194">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"><title>collapse-fullscreen-solid</title>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="invisible_box" data-name="invisible box">
                                    <rect width="48" height="48" fill="none"></rect>
                                </g>
                                <g id="icons_Q2" data-name="icons Q2">
                                    <g>
                                        <path d="M8,26a2,2,0,0,0-2,2.3A2.1,2.1,0,0,0,8.1,30h7.1L4.7,40.5a2,2,0,0,0-.2,2.8A1.8,1.8,0,0,0,6,44a2,2,0,0,0,1.4-.6L18,32.8v7.1A2.1,2.1,0,0,0,19.7,42,2,2,0,0,0,22,40V28a2,2,0,0,0-2-2Z"></path>
                                        <path d="M43.7,4.8a2,2,0,0,0-3.1-.2L30,15.2V8.1A2.1,2.1,0,0,0,28.3,6,2,2,0,0,0,26,8V20a2,2,0,0,0,2,2H39.9A2.1,2.1,0,0,0,42,20.3,2,2,0,0,0,40,18H32.8L43.4,7.5A2.3,2.3,0,0,0,43.7,4.8Z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg @click="collapseItem()" v-if="getTreeviewModal.collapse" class="card-collapse"
                         viewBox="0 0 24 24" fill="#909194" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" stroke="#909194"
                                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </div>

                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div class="dialog-title mb-3">
                    {{$t('map.53')}}
                </div>

                <div class="asset-pretitle">
                    <p class="mb-0 small">{{$t('map.59')}}</p>
                    <div class="button-details" @click="showSearchDialog()">
                        <span class="primary">{{$t('map.54')}}</span>
                        <img src="/svg/arrow-right.svg" alt="" class="icon-arrow">
                    </div>
<!--                    <i-button @click="showSearchDialog()" small text :title="$t('map.54')"></i-button>-->
                </div>
                <div class="asset-title">
                    <i-select v-model="asset" title="title" :small="true" :dark="true" :options="category_options"></i-select>
                    <i-input v-model="search" :small="true" :icon="'reset'" :placeholder="$t('map.59') + '...'"></i-input>
                </div>

                <div class="asset-body">
                    <div v-if="loading" class="text-center">
                        <i-loading></i-loading>
                    </div>

                    <div class="treeview" v-else>
                        <!-- TREEVIEW UP -->
                        <treeview-item-up v-if="feeders_up" :active_asset="asset" :item="feeders_up" :active_item="item"></treeview-item-up>

                        <div v-if="!feeders_up">
                            <!--ITEM -->
                            <div class="treeview-item active" @click="show_feeder_assets = !show_feeder_assets"
                                 :class="{'show': show_feeder_assets}">
                                <img :src="'/svg/assets/' + item.deviceType + (asset == item.deviceType ? '_single_active.svg' : '_single.svg')" alt="">
                                <div class="item-name"> {{item.Name}}</div>
                            </div>

                            <!-- TREEVIEW DOWN -->
                            <treeview-item-down v-if="sw_feeder_assets" :item="item" :active_asset="asset" :load="true"></treeview-item-down>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            item: Object,
        },

        data() {
            return {
                loading: true,
                device: null,

                show_feeder_assets: true,
                feeders_up: null,

                search: null,
                asset: 1,

                category_options: [
                    {value: 1, title: 'All'},
                    {value: 100, title: 'Substation'},
                    {value: 102, title: 'Transformer'},
                    {value: 104, title: 'Pole'},
                    {value: 111, title: 'Cabin'},
                    {value: 115, title: 'Pillar'},
                    {value: 113, title: 'Box'},
                    {value: 112, title: 'SP'},
                ]
            }
        },

        computed: {
            devicesList() {
                return this.$store.getters.getSettings.devicesList;
            },
            getTreeviewModal() {
                return this.$store.getters.getTreeviewModal;
            },
        },

        watch: {
            search() {
                this.searchText();
            }
        },

        mounted() {
            this.init(this.item);

            Draggable.create("#dialog-asset", {
                trigger: "#drag-asset",
                bounds: ".main-page",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
                onDragEnd: function () {
                    let position = `${this.x}px, ${this.y}px, 0px`;
                    window.store.commit('setTreeviewPosition', position);
                }
            });

            this.emitter.on("closeAssets", e => {
                if (e) this.closeDialog();
            });
        },

        methods: {
            init() {
                this.getConnectionUp(this.item);
                this.getConnection(this.item);
            },

            getConnection(e) {
                let url = `getFeederConnection/${e.deviceType}/${e.deviceID}`;
                this.axios.get(url)
                    .then(response_feeder => {
                        this.feederConnection = response_feeder.data;
                        this.feederConnection.forEach(feeeder => {
                            feeeder.show_feeder_assets = false;
                            feeeder.feeder_assets = null;
                            feeeder.feeder_loading = false;
                        });
                    })
            },
            getConnectionUp(e) {
                e.parentsArray.forEach(feeder => {
                    this.loading = true;
                    if (feeder.FeederID && feeder.ParentID && feeder.MainFeeder) {
                        /*set feeder*/
                        feeder.show_feeder_assets = true;
                        feeder.feeder_loading = false;
                        feeder.feederID = feeder.FeederID;
                        feeder.feederType = feeder.FeederType;

                        feeder.parent = null;

                        let url_assets = `getFeederAssets`;
                        let params = {
                            assetID: e.deviceID || e.AssetID,
                            assetType: e.deviceType || e.AssetType,
                            feederID: feeder.FeederID,
                            feederType: feeder.FeederType,
                            direction: 'up'
                        };
                        this.axios.get(url_assets, {params: params})
                            .then(response_assets => {
                                let assets = response_assets.data;
                                if (assets && assets.length) {
                                    /*find parent*/
                                    assets.forEach(asset => {
                                        if (!asset.ParentID && !asset.ParentType) {
                                            let url = `getInfoDevice/${asset.AssetType}/${asset.AssetID}`;
                                            this.axios.get(url)
                                                .then(response => {
                                                    if (response) {
                                                        feeder.parent = response.data;
                                                        feeder.parent.show_feeder_assets = true;
                                                        feeder.assets = this.findChilds(feeder, assets, e);

                                                        if (feeder) this.feeders_up = feeder;

                                                        this.loading = false;
                                                        this.getConnectionUp(feeder.parent, true);
                                                    }
                                                })
                                        }
                                    });
                                }
                            })
                    } else this.loading = false;
                })
            },

            findChilds(feeder, assets) {
                let result = [];

                const dataObj = assets.reduce((acc, item) => {
                    let parent_id = null;
                    if (this.feeders_up && this.feeders_up.parent && this.feeders_up.parent.parentsArray.length == 1) parent_id = this.feeders_up.parent.parentsArray[0].ParentID;
                    if (this.feeders_up && this.feeders_up.parent && this.feeders_up.parent.parentsArray.length > 1) {
                        this.feeders_up.parent.parentsArray.forEach(parent => {
                            if (parent.MainFeeder) parent_id = parent.ParentID;
                        })
                    }

                    let assets = [];
                    if (item.AssetID == parent_id) assets.push(this.feeders_up);
                    acc[item.AssetID] = {
                        AssetID: item.AssetID,
                        AssetType: item.AssetType,
                        name: item.name,
                        show_feeder_assets: true,
                        loading: false,
                        last: false,
                        feederID: feeder.feederID,
                        feederType: feeder.feederType,
                        assets: assets
                    };
                    return acc
                }, {});

                result = assets.reduce((acc, item) => {
                    if (item.ParentID == feeder.parent.deviceID) acc.push(dataObj[item.AssetID]);
                    else if (dataObj[item.ParentID] && dataObj[item.AssetID]) dataObj[item.ParentID].assets.push(dataObj[item.AssetID]);

                    return acc;
                }, []);

                return result;
            },

            showType(type) {
                let result;
                if (this.devicesList) {
                    this.devicesList.forEach(device => {
                        if (device.id == type) result = device.name
                    })
                }
                return result
            },

            showSearchDialog() {
                this.emitter.emit("showSearchDialog", true);
            },

            searchText() {
                let text = document.querySelectorAll('.treeview-item .item-name');
                if (text) {
                    text.forEach((textOriginal, index) => {
                        if (this.search && textOriginal.outerText.toLowerCase().includes(this.search.toLowerCase()) && this.search) {
                            let textNew = '';
                            let textArr = textOriginal.outerText.toLowerCase().split(this.search.toLowerCase());

                            let i = 0;
                            let count = 0;
                            let textArrLength = textArr.length - 1;

                            for (; i < textArrLength; i++) {
                                textNew += textOriginal.outerText.slice(count, count + textArr[i].length);
                                count += textArr[i].length;
                                textNew += "<span class='text-background'>" + textOriginal.outerText.slice(count, count + this.search.length) + "</span>";
                                count += this.search.length;
                            }
                            textNew += textOriginal.outerText.slice(count, count + textArr[i].length);

                            if (textArr && textArr.length) text[index].innerHTML = textNew;
                            else text[index].innerHTML = textOriginal.outerText;
                        } else text[index].innerHTML = textOriginal.outerText;
                    })
                }
            },

            closeDialog() {
                this.$emit('close');
            },
            collapseItem() {
                this.$store.commit('setTreeviewZoom', !this.getTreeviewModal.collapse);
            }
        }
    }
</script>

<style lang="sass">
    #dialog-asset
        .asset-pretitle
            display: flex
            align-items: center
            justify-content: space-between
            padding-right: 18px
        .asset-title
            display: grid
            grid-template-columns: 96px calc(100% - 96px)
            .select-element
                .select-value-title
                    border-radius: 8px 0 0 8px
                    border-left: 1px solid #76767F
                    border-top: 1px solid #76767F
                    border-bottom: 1px solid #76767F
                    border-right: none
            .input-element
                input
                    border-radius: 0 8px 8px 0
        .asset-body
            overflow-x: scroll
            overflow-y: scroll
</style>