<template>
  <div class="chip-el">
    <div class="d-flex align-center">
      <span class="chip-el__name">
        {{ item[title] }}
      </span>
      <button
        v-if="closable"
        @click="onClose(item)"
        class="chip-el__close ml-2"
      >
        <img src="/svg/close-filled.svg" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'value',
    },
  },
  emits: ['chip:close'],
  data() {
    return {};
  },
  methods: {
    onClose(value) {
      this.$emit('chip:close', value);
    },
  },
};
</script>

<style lang="scss">
.chip-el {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 8px 16px;
  background-color: var(--background-common);
  text-wrap: nowrap;

  &__name {
    font-weight: 600;
    color: var(--color-primary-dark);
    font-size: 14px;
    line-height: 1;
  }
  &__close {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background-color: unset;
    line-height: 0;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
