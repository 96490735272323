<template>
    <div id="map" :style="{height: map_header}">
        <div class="google-map" ref="googleMap"></div>
        <div class="loading" v-if="loading"></div>

        <div v-if="add_new_marker_contextmenu.visible"
             :style="{left: add_new_marker_contextmenu.left, top: add_new_marker_contextmenu.top}"
             class="contextmenu">
            <ul>
                <li @click="selectCreation()">{{$t('map.64')}}</li>
            </ul>
        </div>

        <div class="controls zoom-control" :style="zoomStyle">
            <button class="zoom-control-in" title="Zoom In" :class="{'disabled': mapConfig.scale == 22}" :disabled="mapConfig.scale == 22">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H4V4H0V6H4V10H6V6H10V4H6V0Z" fill="#45464E"/>
                </svg>
            </button>
            <button class="zoom-control-out" :class="{'disabled': mapConfig.scale == 3}" :disabled="mapConfig.scale == 3" title="Zoom Out">
                <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2V0H10V2H0Z" fill="#45464E"/>
                </svg>
            </button>
        </div>

        <div class="maptype-control maptype-control-is-map">
            <button class="maptype-control-map maptype-control" title="Show road map">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.49 19.0048L5.12 13.2748L3.5 14.5348L12.5 21.5348L21.5 14.5348L19.87 13.2648L12.49 19.0048ZM12.5 16.4648L19.86 10.7348L21.5 9.46484L12.5 2.46484L3.5 9.46484L5.13 10.7348L12.5 16.4648ZM12.5 4.99484L18.24 9.46484L12.5 13.9348L6.76 9.46484L12.5 4.99484Z" fill="#000000"/>
                </svg>
                <span>Map</span>
            </button>
            <button class="maptype-control-satellite maptype-control" title="Show satellite imagery">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.49 19.0048L5.12 13.2748L3.5 14.5348L12.5 21.5348L21.5 14.5348L19.87 13.2648L12.49 19.0048ZM12.5 16.4648L19.86 10.7348L21.5 9.46484L12.5 2.46484L3.5 9.46484L5.13 10.7348L12.5 16.4648ZM12.5 4.99484L18.24 9.46484L12.5 13.9348L6.76 9.46484L12.5 4.99484Z" fill="white"/>
                </svg>
                <span>Layers</span>
            </button>
        </div>

        <create-asset ref="createAsset"
                      @hide_creation="hideCreation"
                      @set_new_asset="setNewAsset"
                      @confirm_asset_position="confirmAssetPosition"
                      @set_step="setStepCreation"
                      @show_feeder="showFeederNewMarker"
                      @set_feeder="setFeederNewMarker"
                      @hide_feeder="hideFeederNewMarker"
                      @hide_selected_feeder="hideSelectedFeederNewMarker"
                      @remove_feeder="removeFeederNewMarker"
                      @set_selected_feeders="setSelectedFeederNewMarker"
                      @show_selected_feeder="showSelectedFeederNewMarker"
                      :add_new_marker="add_new_marker"
                      :marker="new_marker.marker"
                      :marker_parents="new_marker.parents">

        </create-asset>
    </div>
</template>

<script>
    import {toRaw} from "vue";
    import mapStyle from '../../assets/json/map_style';
    import {Loader} from '@googlemaps/js-api-loader';
    import CreateAsset from './CreateAsset'

    export default {
        props: {
            mapConfig: Object,
            mapData: Object,
            activeAsset: {
                default: null
            },
            activeItem: {
                default: null
            },
            map_header: {
                default: '100px'
            }
        },
        components: {
            CreateAsset
        },
        data() {
            return {
                loading: false,

                google: null,
                map: null,

                markers: [],
                lines: [],
                clusters: [],
                feeder_active_lines: [],

                marker_feeder_input: null,
                marker_feeder_output: null,

                feederLinesUp: [],
                feederLinesDown: [],

                /*new item data*/
                add_new_marker: false,
                new_marker: {
                    step: 0,
                    asset: null,
                    marker: null,
                    parents: [],
                    feeders: [],
                    selected_feeders: [],
                    selected_feeder: null
                },
                add_new_marker_feeders: [],
                add_new_marker_contextmenu: {
                    left: 0,
                    top: 0,
                    visible: false
                },
            }
        },

        computed: {
            zoomStyle() {
                let result;
                if (this.activeItem) result = {'right': '357px'};
                if (!this.activeItem) result = {'right': '16px'};
                return result;
            },
            user() {
                return this.$store.getters.getUser;
            }
        },

        watch: {
            mapData() {
                this.loadMapData();
            },
            activeItem() {
                this.setInputOutputActive();
            }
        },

        mounted() {
            const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
            const loaderMap = new Loader({
                apiKey: key,
                version: "weekly",
            });
            this.initializeMap(loaderMap);

            this.emitter.on("feederHover", e => {
                this.showHoverFeeder(e[0], e[1], e[2], e[3]);
            });
            this.emitter.on("feederLeave", () => {
                this.resetActiveHoverFeeder();
            });
        },

        methods: {
            initializeMap(loader) {
                const mapContainer = this.$refs.googleMap;
                let center;
                let map;
                let self = this;

                loader
                    .load()
                    .then((google) => {
                        this.google = google;

                        let address = this.user.city + ' ' + this.user.country;
                        let geocoder = new google.maps.Geocoder();

                        geocoder.geocode({
                            'address': address
                        }, function(results, status) {
                            if (status == google.maps.GeocoderStatus.OK) {
                                let Lat = results[0].geometry.location.lat();
                                let Lng = results[0].geometry.location.lng();
                                center = new google.maps.LatLng(Lat, Lng)
                            } else {
                                center = new google.maps.LatLng(53.9, 27.56667)
                            }

                            map = new google.maps.Map(mapContainer, {
                                mapTypeIds: 'styled_map',
                                streetViewControl: false,
                                zoom: 10,
                                minZoom: 3,
                                maxZoom: 22,
                                center: center,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                styles: mapStyle
                            });

                            self.map = map;

                            self.initZoomControl(self.map);
                            self.initMapTypeControl(self.map);

                            self.map.addListener('idle', () => {
                                let bounds = self.map.getBounds();
                                let southWest = bounds.getSouthWest();
                                let northEast = bounds.getNorthEast();
                                self.reset(southWest, northEast, self.map.zoom);
                                self.$emit('map_dragend', southWest, northEast, self.map.zoom);
                                if (self.selectMarker) self.setActiveMarker(self.selectMarker);
                            });

                            /* left click */
                            self.map.addListener('click', (e) => {
                                self.addNewMarker(e);
                            });

                            /* Right click */
                            self.map.addListener('contextmenu', () => {

                            });

                            self.markers = [];
                            self.loadMapData();
                        });
                    });
            },

            /*INIT BUTTON FOR ZOOM MAP*/
            initZoomControl(map) {
                document.querySelector(".zoom-control-in").onclick = function () {
                    map.setZoom(map.getZoom() + 1);
                };

                document.querySelector(".zoom-control-out").onclick = function () {
                    map.setZoom(map.getZoom() - 1);
                };
            },
            /*INIT BUTTON FOR CHANGE TYPE MAP*/
            initMapTypeControl(map) {
                const mapTypeControlDiv = document.querySelector(".maptype-control");

                document.querySelector(".maptype-control-map").onclick = function () {
                    mapTypeControlDiv.classList.add("maptype-control-is-map");
                    mapTypeControlDiv.classList.remove("maptype-control-is-satellite");
                    map.setMapTypeId("roadmap");
                };

                document.querySelector(".maptype-control-satellite").onclick = function () {
                    mapTypeControlDiv.classList.remove("maptype-control-is-map");
                    mapTypeControlDiv.classList.add("maptype-control-is-satellite");
                    map.setMapTypeId("satellite");
                };

            },

            /*LOAD MARKERS AND LINES*/
            loadMapData() {
                if (!this.mapData.length) {
                    this.loading = false;
                    return;
                }
                this.getMarkers();
            },

            /*GENERATE A MAP MARKER*/
            getMarkers() {
                this.mapData.forEach(marker => {
                    /* CLUSTERS */
                    if (marker.deviceType == 11) {
                        if(this.activeItem) {
                            this.$emit('close_item_dialog');
                        }
                        /*set marker*/
                        this.addClusterMarker(marker);
                        /* cluster lines */
                        this.addLine(marker, 'cluster');
                    }

                    /* FINAL DEVICES */
                    if (marker.deviceType == 100 || marker.deviceType == 102 || marker.deviceType == 104
                        || marker.deviceType == 111 || marker.deviceType == 115 || marker.deviceType == 113 || marker.deviceType == 112) {

                        /*set marker*/
                        this.addMarker(marker);

                        /* map lines */
                        this.addLine(marker);
                    }
                });
            },

            addMarker(marker) {
                let icon = {
                    anchor: null,
                    url: null,
                    zIndex: 1
                };
                if (!this.selectMarker || this.selectMarker && marker.deviceid != this.selectMarker.deviceId) {
                    icon.anchor = new this.google.maps.Point(17, 17);
                    icon.url = `./../../../svg/assets/${marker.deviceType}_default.png`;
                    icon.zIndex = 1
                }
                if ((this.activeAsset && this.activeAsset == marker.deviceType) && (!this.selectMarker || this.selectMarker && marker.deviceType != this.selectMarker.deviceType)) {
                    icon.anchor = new this.google.maps.Point(17, 17);
                    icon.url = `./../../../svg/assets/${marker.deviceType}_active.png`;
                    icon.zIndex = 6;
                }
                if (this.selectMarker && marker.deviceid == this.selectMarker.deviceId) {
                    icon.anchor = new this.google.maps.Point(28, 28);
                    icon.url = `./../../../svg/assets/${marker.deviceType}_selected.png`;
                    icon.zIndex = 10
                }

                if (this.add_new_marker && this.new_marker.parents && this.new_marker.parents.length) {
                    this.new_marker.parents.forEach(new_parent => {
                        if (new_parent.deviceId == marker.deviceid) {
                            icon.anchor = new this.google.maps.Point(17, 17);
                            icon.url = `./../../../svg/assets/${marker.deviceType}_parent.png`;
                            icon.zIndex = 10
                        }
                    })
                }

                const new_marker = new this.google.maps.Marker({
                    position: new this.google.maps.LatLng(
                        marker.latitude,
                        marker.longitude
                    ),
                    lat: marker.latitude,
                    lng: marker.longitude,
                    // map: this.map,
                    title: marker.name,
                    deviceId: marker.deviceid,
                    deviceType: marker.deviceType,
                    parentsArray: marker.parentsArray,
                    zIndex: icon.zIndex,
                    icon: {
                        anchor: icon.anchor,
                        url: icon.url,
                        zIndex: icon.zIndex
                    },
                });
                toRaw(new_marker).setMap(this.map)
                // if(this.activeItem && this.activeItem.deviceID == marker.deviceid) this.setActiveMarker(new_marker);
                /* marker click listener */
                new_marker.addListener('click', () => {
                    this.setActiveMarker(new_marker)
                });

                this.markers.push(new_marker);
            },

            addLine(marker, type) {
                if (marker.parentsArray && marker.parentsArray.length && marker.latitude && marker.parentsArray[0].parentID) {
                    let data = {
                        lat: marker.latitude,
                        lng: marker.longitude
                    };

                    /*CHECK PARENT ELEMENTS*/
                    marker.parentsArray.forEach(parent => {
                        const flightPlanCoordinates = [];
                        /*SECOND CYCLE - find parents coordinates*/
                        this.mapData.forEach(cycle_marker => {
                            if (cycle_marker.deviceid == parent.parentID && cycle_marker.latitude && cycle_marker.longitude) {
                                let parent_data = {
                                    lat: cycle_marker.latitude,
                                    lng: cycle_marker.longitude,
                                };
                                flightPlanCoordinates.push(data);
                                flightPlanCoordinates.push(parent_data);
                                const flightPath = new this.google.maps.Polyline({
                                    path: flightPlanCoordinates,
                                    geodesic: true,
                                    strokeColor: "#2F3033",
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                    zIndex: 2,
                                    id: parent.feederID,
                                    parentId: parent.parentID,
                                    parentLat: cycle_marker.latitude,
                                    parentLng: cycle_marker.longitude,
                                    type: type == 'cluster' ? type : 'marker',
                                    childLat: marker.latitude,
                                    childLng: marker.longitude,
                                    childId: marker.deviceid,
                                    // map: this.map
                                });
                                toRaw(flightPath).setMap(this.map);
                                this.lines.push(flightPath);
                            }
                        })
                    })
                }
            },

            addClusterMarker(marker) {
                const mapCluster = new this.google.maps.Marker({
                    position: new this.google.maps.LatLng(
                        marker.latitude,
                        marker.longitude
                    ),
                    label: {
                        text: String(marker.numOfDevices),
                        color: '#ffffff',
                        fontSize: '12px',
                    },
                    lat: marker.latitude,
                    lng: marker.longitude,
                    map: this.map,
                    title: marker.name,
                    animation: this.google.maps.Animation.DROP,
                    deviceType: marker.deviceType,
                    deviceId: marker.deviceid,
                    numOfDevices: marker.numOfDevices,
                    icon: {
                        anchor: new this.google.maps.Point(23, 23),
                        url: `./../../../svg/cluster_primary.svg`,
                    },
                });
                this.clusters.push(mapCluster);

                /* cluster click listener */
                mapCluster.addListener('click', () => {
                    this.clusters.forEach(cluster => {
                        toRaw(cluster).setIcon({
                            anchor: new this.google.maps.Point(23, 23),
                            url: `./../../../svg/cluster_primary.svg`,
                        });
                        toRaw(cluster).setLabel({
                            text: String(cluster.numOfDevices),
                            color: '#ffffff',
                            fontSize: '12px',
                        });
                    });
                    if(this.map.zoom > 17) {
                        toRaw(mapCluster).setIcon({
                            anchor: new this.google.maps.Point(40, 40),
                            url: `./../../../svg/cluster_active.svg`,
                        });
                        toRaw(mapCluster).setLabel({
                            text: String(mapCluster.numOfDevices),
                            color: '#002201',
                            fontSize: '12px',
                        });
                        this.$emit('set_cluster', mapCluster);
                    }
                });
            },


            /*SET ACTIVE ELEMENT AFTER CLICK*/
            setActiveMarker(marker) {
                this.resetInputOutputCount();
                if (!this.add_new_marker) {
                    this.resetActiveFeeder();
                    if (!this.selectMarker || marker != this.selectMarker) {
                        this.resetActiveSelectedFeeder();
                        this.resetActiveHoverFeeder();
                    }

                    if (marker.title) {
                        this.loading = true;
                        this.$emit('set_active', marker);
                        this.selectMarker = marker;
                        this.markers.forEach(current_marker => {
                            if (current_marker.deviceId == marker.deviceId) {
                                toRaw(current_marker).setIcon({
                                    anchor: new this.google.maps.Point(28, 28),
                                    url: `./../../../svg/assets/${current_marker.deviceType}_selected.png`,
                                    zIndex: 10
                                });
                                toRaw(current_marker).setZIndex(10);
                            } else {
                                if (!this.activeAsset || this.activeAsset && current_marker.deviceType != this.activeAsset) {
                                    toRaw(current_marker).setIcon({
                                            anchor: new this.google.maps.Point(17, 17),
                                            url: `./../../../svg/assets/${current_marker.deviceType}_default.png`,
                                            zIndex: 1
                                        }
                                    );
                                    toRaw(current_marker).setZIndex(1);
                                }
                                if (this.activeAsset && this.activeAsset == current_marker.deviceType) {
                                    toRaw(current_marker).setIcon({
                                            anchor: new this.google.maps.Point(19, 17),
                                            url: `./../../../svg/assets/${current_marker.deviceType}_active.png`,
                                            zIndex: 1
                                        }
                                    );
                                    toRaw(current_marker).setZIndex(6);
                                }
                            }
                        });
                    }
                }
                if (this.add_new_marker && this.new_marker.step == 4) {
                    let check = this.new_marker.parents.find(value => value.deviceId == marker.deviceId);
                    if(!check) {
                        this.markers.forEach(current_marker => {
                            if (current_marker.deviceId == marker.deviceId) {
                                toRaw(current_marker).setIcon({
                                    anchor: new this.google.maps.Point(17, 17),
                                    url: `./../../../svg/assets/${current_marker.deviceType}_parent.png`,
                                    zIndex: 10
                                });
                                toRaw(current_marker).setZIndex(10);
                            }
                        });
                        this.new_marker.parents.push(marker);
                    }
                    if(check) {
                        this.new_marker.parents.forEach((added_marker, i) => {
                            if(added_marker.deviceId == marker.deviceId) {
                                toRaw(added_marker).setIcon({
                                        anchor: new this.google.maps.Point(17, 17),
                                        url: `./../../../svg/assets/${added_marker.deviceType}_default.png`,
                                        zIndex: 1
                                    }
                                );
                                toRaw(added_marker).setZIndex(1);
                                this.new_marker.parents.splice(i, 1)
                            }
                        })
                    }

                }
            },

            /*set output/input data near marker*/
            setInputOutputActive() {
                if (this.activeItem && this.selectMarker) {
                    let count_parent = 0;
                    if (this.activeItem.parentsArray && this.activeItem.parentsArray.length) {
                        this.activeItem.parentsArray.forEach(parent => {
                            if (parent.FeederID) count_parent += 1;
                        })
                    }
                    count_parent = count_parent.toString();
                    let count_child = this.activeItem.childs.length.toString();
                    this.marker_feeder_input = new this.google.maps.Marker({
                        position: new this.google.maps.LatLng(
                            this.activeItem.Latitude,
                            this.activeItem.Longitude
                        ),
                        // map: this.map,
                        zIndex: 11,
                        label: {
                            text: count_parent,
                            color: '#ffffff',
                            fontFamily: "'IBM Plex Mono', sans-serif",
                            fontSize: '14px',
                            fontWeight: '500',
                            className: 'input-count',
                        },
                        icon: {
                            anchor: new this.google.maps.Point(-8, 20),
                            url: `./../../../svg/input_count.svg`,
                            zIndex: 12
                        }
                    });
                    toRaw(this.marker_feeder_input).setMap(this.map);
                    this.marker_feeder_output = new this.google.maps.Marker({
                        position: new this.google.maps.LatLng(
                            this.activeItem.Latitude,
                            this.activeItem.Longitude
                        ),
                        // map: this.map,
                        zIndex: 11,
                        label: {
                            text: count_child,
                            color: '#ffffff',
                            fontFamily: "'IBM Plex Mono', sans-serif",
                            fontSize: '14px',
                            fontWeight: '500',
                            className: 'input-count',
                        },
                        icon: {
                            anchor: new this.google.maps.Point(-8, 0),
                            url: `./../../../svg/output_count.svg`,
                            zIndex: 12
                        }
                    });
                    toRaw(this.marker_feeder_output).setMap(this.map)
                }
            },

            /*SET ACTIVE FEEDERS*/
            showActiveFeeder(e, direction, active, count, request) {
                if (request == 1) this.feederLinesUp = [];
                if (request == 1) this.feederLinesDown = [];

                if (direction === 'up') {
                    e.forEach(item => {
                        e.forEach(parent => {
                            if (item.ParentID == parent.AssetID) this.setLine(item, parent, direction)
                        });
                        active.parentsArray.forEach(parent => {
                            if (parent.ParentID == item.AssetID) {
                                active.AssetID = active.ID;
                                this.setLine(active, item, direction)
                            }
                        })
                    });
                }

                if (direction === 'down') {
                    e.forEach(item => {
                        e.forEach(parent => {
                            if (item.ParentID == parent.AssetID && parent.Latitude) this.setLine(item, parent, direction)
                            if (item.ParentID == parent.AssetID && !parent.Latitude) {
                                active.childs.forEach(child => {
                                    if (parent.AssetID == child.childID) {
                                        parent.Latitude = child.Latitude;
                                        parent.Longitude = child.Longitude;
                                        active.AssetID = active.ID;
                                        this.setLine(item, parent, direction);
                                        this.setLine(parent, active, direction)
                                    }
                                })

                            }
                        });
                    });
                }

                if (count == request) this.loading = false;
            },

            setLine(item, parent, direction) {
                let path = [
                    {
                        lat: item.Latitude,
                        lng: item.Longitude,
                    },
                    {
                        lat: parent.Latitude,
                        lng: parent.Longitude,
                    }
                ];
                const flightPath = new this.google.maps.Polyline({
                    path: path,
                    geodesic: true,
                    strokeColor: (direction === 'up') ? '#35A636' : '#668AFF',
                    strokeWeight: 4,
                    zIndex: 3,
                    parentId: parent.AssetID,
                    childId: item.AssetID,
                    parentLat: parent.Latitude,
                    parentLng: parent.Longitude,
                    childLat: item.Latitude,
                    childLng: item.Longitude,
                    // map: this.map
                });
                toRaw(flightPath).setMap(this.map);
                if (direction === 'up') this.feederLinesUp.push(flightPath);
                if (direction === 'down') this.feederLinesDown.push(flightPath);
            },

            showHoverFeeder(e, direction) {
                this.resetActiveHoverFeeder();

                if (direction === 'up') {
                    this.feederLinesUp.forEach(line => {
                        e.forEach(parent => {
                            if (parent.ParentID == line.parentId && parent.AssetID == line.childId) this.setHoverLine(line, direction);
                        })
                    })
                }

                if (direction === 'down') {
                    this.feederLinesDown.forEach(line => {
                        e.forEach(child => {
                            if (child.ParentID == line.parentId && child.AssetID == line.childId) {
                                this.setHoverLine(line, direction);
                            }
                        })
                    })
                }

            },

            setHoverLine(line, direction) {
                let path = [
                    {
                        lat: line.parentLat,
                        lng: line.parentLng,
                    },
                    {
                        lat: line.childLat,
                        lng: line.childLng,
                    }
                ];
                const flightPath = new this.google.maps.Polyline({
                    path: path,
                    geodesic: true,
                    strokeColor: (direction === 'up') ? '#C7FFB9' : '#B9DDFF',
                    strokeOpacity: 1,
                    strokeWeight: 12,
                    zIndex: 1,
                    // map: this.map
                });
                toRaw(flightPath).setMap(this.map);
                this.feeder_active_lines.push(flightPath);
            },

            /*SET ACTIVE TYPE FROM FILTERS*/
            setActive(item) {
                this.markers.forEach(marker => {
                    if (item == marker.deviceType) {
                        if (!this.selectMarker || (this.selectMarker && marker.deviceId != this.selectMarker.deviceId)) {
                            toRaw(marker).setIcon({
                                anchor: new this.google.maps.Point(19, 17),
                                url: `./../../../svg/assets/${marker.deviceType}_active.png`,
                                zIndex: 6
                            });
                            toRaw(marker).setZIndex(6);
                        }
                    } else {
                        if (!this.selectMarker || (this.selectMarker && marker.deviceId != this.selectMarker.deviceId)) {
                            toRaw(marker).setIcon({
                                anchor: new this.google.maps.Point(17, 17),
                                url: `./../../../svg/assets/${marker.deviceType}_default.png`,
                                zIndex: 1
                            });
                            toRaw(marker).setZIndex(1);
                        }
                    }
                })
            },

            /*ADD NEW ITEM TO MAP*/
            addNewMarker(e) {
                if (!this.new_marker.marker && this.new_marker.step == 0 && e && e.pixel && e.pixel.x) {
                    this.new_marker.marker = new this.google.maps.Marker({
                        position: e.latLng,
                        map: this.map,
                    });
                    this.add_new_marker_contextmenu.left = e.pixel.x + 14 + 'px';
                    this.add_new_marker_contextmenu.top = e.pixel.y + 100 + 'px';
                    this.add_new_marker_contextmenu.visible = true;
                    this.new_marker.marker.setMap(this.map);
                    this.$emit('close_all_cards', true);
                    return;
                }
                if (this.new_marker.marker && this.new_marker.step == 0) {
                    toRaw(this.new_marker.marker).setMap(null);
                    this.add_new_marker_contextmenu.visible = false;
                    this.new_marker.marker = null;
                }
            },
            selectCreation() {
                this.add_new_marker_contextmenu.visible = false;
                this.add_new_marker = true;

                this.$refs.createAsset.setStep(1);
            },
            setNewAsset(id) {
                this.new_marker.asset = id;
                toRaw(this.new_marker.marker).setIcon({
                        anchor: new this.google.maps.Point(28, 28),
                        url: `./../../../svg/assets/${id}_new.png`
                    }
                );
                toRaw(this.new_marker.marker).setDraggable(true);
                this.emitter.emit("hideAssetsFilter", true);
            },
            confirmAssetPosition() {
                toRaw(this.new_marker.marker).setDraggable(false);
            },
            setStepCreation(step) {
                this.new_marker.step = step
            },
            showFeederNewMarker(feeder) {
                this.lines.forEach(line => {
                    if (line.id == feeder.feederID) {
                        line.setOptions({strokeColor: '#35A636', strokeWeight: 4, zIndex: 2});
                        if(!this.new_marker.feeders.length) this.add_new_marker_feeders.push(line);
                        if(this.new_marker.feeders.length) {
                            let create_new = true;
                            this.new_marker.feeders.forEach(line_selected => {
                                if(line_selected.id == feeder.feederID) {
                                    create_new = false;
                                    // line_selected.lines.map((ln) => toRaw(ln).setMap(this.map));
                                }
                            });
                            if(create_new) this.add_new_marker_feeders.push(line);
                        }
                    }
                })
            },
            setFeederNewMarker(feeder) {
                let data = {
                    id: feeder.feederID,
                    lines: []
                };
                this.add_new_marker_feeders.forEach(line => {
                    if (line.id == feeder.feederID) {
                        let path = [
                            {lat: line.parentLat, lng: line.parentLng},
                            {lat: line.childLat, lng: line.childLng},
                        ];
                        const flightPath = new this.google.maps.Polyline({
                            path: path,
                            geodesic: true,
                            strokeColor: '#C7FFB9',
                            strokeWeight: 12,
                            zIndex: 1,
                            id: line.id,
                            parentId: line.parentId,
                            childId: line.childId,
                            parentLat: line.parentLat,
                            parentLng: line.parentLng,
                            childLat: line.childLat,
                            childLng: line.childLng,
                            map: this.map
                        });
                        data.lines.push(flightPath);
                        this.new_marker.feeders.push(data);
                    }
                })
            },
            setSelectedFeederNewMarker(parents) {
                this.new_marker.selected_feeders = [];
                parents.forEach(parent => {
                    parent.selected_feeders.forEach(feeder => {
                        const lineSymbol = {
                            path: "M 0,-1 0,1",
                            strokeColor: '#2F3033',
                            strokeOpacity: 1,
                            scale: 4,
                        };
                        const line = new this.google.maps.Polyline({
                            path: [
                                { lat: feeder.lat, lng: feeder.lng },
                                this.new_marker.marker.position
                            ],
                            strokeOpacity: 0,
                            zIndex: 2,
                            icons: [
                                {
                                    icon: lineSymbol,

                                    offset: "0",
                                    repeat: "30px",
                                },
                            ],
                            map: this.map,
                        });
                        this.new_marker.selected_feeders.push(line)
                    })
                })
            },
            showSelectedFeederNewMarker(feeder) {
                if(this.new_marker.selected_feeder) toRaw(this.new_marker.selected_feeder).setMap(null);
                this.new_marker.selected_feeder = null;

                const lineSymbol = {
                    path: "M 0,-1 0,1",
                    strokeColor: '#8BFC80',
                    strokeOpacity: 1,
                    scale: 4,
                    strokeWeight: 12,
                };
                const line = new this.google.maps.Polyline({
                    path: [
                        { lat: feeder.lat, lng: feeder.lng },
                        this.new_marker.marker.position
                    ],
                    strokeOpacity: 0,
                    zIndex: 1,
                    icons: [
                        {
                            icon: lineSymbol,
                            offset: "0",
                            repeat: "30px",
                        },
                    ],
                    map: this.map,
                });
                this.new_marker.selected_feeder = line;
            },
            hideFeederNewMarker() {
                this.lines.forEach(line => {
                    line.setOptions({strokeColor: '#2F3033', strokeWeight: 4, zIndex: 2});
                });
                // this.new_marker.feeders.forEach(line => {
                //     if(line.lines) line.lines.map((ln) => toRaw(ln).setMap(null));
                // });
                // this.add_new_marker_feeders = [];
            },
            hideSelectedFeederNewMarker() {
                this.new_marker.feeders.forEach(line => {
                    if(line.lines) line.lines.map((ln) => toRaw(ln).setMap(null));
                })
            },
            removeFeederNewMarker(feeder) {
                this.new_marker.feeders.forEach((line, i) => {
                    if(line.id == feeder.feederID) {
                        if(line.lines) line.lines.map((ln) => toRaw(ln).setMap(null));
                        line.lines = null;
                        this.new_marker.feeders.splice(i, 1)
                    }

                })
            },
            hideCreation() {
                this.add_new_marker = false;
                toRaw(this.new_marker.marker).setMap(null);
                if(this.new_marker.selected_feeder) toRaw(this.new_marker.selected_feeder).setMap(null);
                this.add_new_marker_feeders.forEach(line => {
                    if(line) toRaw(line).setMap(null);
                });
                this.new_marker.selected_feeders.forEach(line => {
                    if(line) toRaw(line).setMap(null);
                });
                this.new_marker.feeders.forEach(line => {
                    if(line.lines) line.lines.map((ln) => toRaw(ln).setMap(null));
                });

                this.new_marker.marker = null;
                this.new_marker.asset = null;
                this.new_marker.step = 0;
                this.new_marker.parents = [];
                this.new_marker.selected_feeders = [];
                this.new_marker.selected_feeder = null;
                this.add_new_marker_feeders = [];
                this.new_marker.feeders = [];
                this.emitter.emit("showAssetsFilter", true);

            },

            /*RESET SELECTED DATA FROM MAP*/
            hideSelectedItem() {
                this.resetActiveMarker();
                this.resetActiveFeeder();
                this.resetActiveHoverFeeder();
                this.resetActiveSelectedFeeder();
            },

            /*reset color marker from map*/
            resetActiveMarker() {
                this.selectMarker = null;
                this.markers.forEach(marker => {
                    if (!this.activeAsset || (this.activeAsset && marker.deviceType != this.activeAsset)) {
                        toRaw(marker).setIcon({
                                anchor: new this.google.maps.Point(17, 17),
                                url: `./../../../svg/assets/${marker.deviceType}_default.png`
                            }
                        );
                        toRaw(marker).setZIndex(1);
                    }
                    if (marker.deviceType == this.activeAsset) {
                        toRaw(marker).setIcon({
                                anchor: new this.google.maps.Point(19, 17),
                                url: `./../../../svg/assets/${marker.deviceType}_active.png`
                            }
                        );
                        toRaw(marker).setZIndex(6);
                    }
                });
                this.resetInputOutputCount();
            },

            resetInputOutputCount() {
                if (this.marker_feeder_input) toRaw(this.marker_feeder_input).setMap(null);
                if (this.marker_feeder_output) toRaw(this.marker_feeder_output).setMap(null);
                this.marker_feeder_input = null;
                this.marker_feeder_output = null;
            },

            resetActiveFeeder() {
                this.lines.forEach(line => {
                    line.setOptions({strokeColor: '#2F3033', strokeWeight: 4, zIndex: 2});
                });
                this.loading = false;
            },

            hideActiveSelectedFeeder() {
                this.feederLinesUp.map((line) => toRaw(line).setMap(null));
                this.feederLinesDown.map((line) => toRaw(line).setMap(null));
            },
            resetActiveSelectedFeeder() {
                this.hideActiveSelectedFeeder();
                this.feederLinesUp = [];
                this.feederLinesDown = [];
            },
            hideActiveHoverFeeder() {
                this.feeder_active_lines.map((line) => toRaw(line).setMap(null));
            },
            resetActiveHoverFeeder() {
                this.hideActiveHoverFeeder();
                this.feeder_active_lines = [];
            },
            removeActiveCluster() {
                this.clusters.forEach(cluster => {
                    toRaw(cluster).setIcon({
                        anchor: new this.google.maps.Point(23, 23),
                        url: `./../../../svg/cluster_primary.svg`,
                    });
                    toRaw(cluster).setLabel({
                        text: String(cluster.numOfDevices),
                        color: '#ffffff',
                        fontSize: '12px',
                    });
                });
            },

            /*RESET ALL DATA FROM MAP*/
            reset() {
                this.markers.map((marker) => toRaw(marker).setMap(null));
                this.markers = [];
                this.lines.map((line) => toRaw(line).setMap(null));
                this.lines = [];
                this.clusters.map((line) => toRaw(line).setMap(null));
                this.clusters = [];
                this.resetInputOutputCount();
                this.hideActiveSelectedFeeder();
                this.hideActiveHoverFeeder();
            }
        }
    }
</script>

<style scoped lang="sass">
    #map
        width: 100%
        /*height: calc(100vh - 132px)*/
        position: relative

        .google-map
            width: 100%
            height: 100%

        .contextmenu
            position: fixed
            left: 0
            top: 0
            z-index: 20
            background: var(--background-light)
            padding: 8px 0
            border-radius: 8px
            width: 192px

            li
                list-style: none
                padding: 12px 16px
                font-size: 16px
                transition: 0.3s ease-in-out

                &:hover
                    cursor: pointer
                    background: var(--color-secondary-hover)

        .loading
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: rgba(255, 255, 255, 0.2)
            z-index: 4

        .controls
            position: fixed
            background: #FFFFFF
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
            border-radius: 8px
            z-index: 2

        .zoom-control
            width: 40px
            right: 16px
            bottom: 66px
            .zoom-control-in, .zoom-control-out
                width: 40px
                height: 40px
                display: flex
                align-items: center
                justify-content: center
                background: #FFFFFF
                border: none
                &:hover
                    path
                        fill: var(--color-primary)

            .zoom-control-in.disabled, .zoom-control-out.disabled
                background: var(--background-disabled)
                path
                    fill: var(--text-color-gray-dark)
                &:hover
                    cursor: default

            .zoom-control-in
                position: relative
                margin-bottom: 1px
                border-radius: 8px 8px 0 0
                &::before
                    content: ''
                    position: absolute
                    left: 4px
                    bottom: -1px
                    width: 32px
                    height: 1px
                    background: #E2E1EC
                    z-index: 2

            .zoom-control-out
                border-radius: 0 0 8px 8px

        .maptype-control
            z-index: 2
            position: fixed
            left: 16px
            bottom: 16px
            width: 96px
            height: 96px
            border-radius: 4px
            overflow: hidden
            border: 2px solid var(--text-color-white)

            .maptype-control
                width: 96px
                height: 96px
                padding-bottom: 12px
                border-radius: 4px
                display: flex
                align-items: flex-end
                justify-content: center
                span
                    display: inline-block
                    line-height: 24px
                    font-weight: 500
                    font-size: 11px

            .maptype-control-map
                background-image: url("/public/img/map/map-dafault.jpg")
            .maptype-control-satellite
                background-image: url("/public/img/map/map-satellite.jpg")
                span
                    color: var(--text-color-white)

        .maptype-control.maptype-control-is-map
            .maptype-control-map
                display: none
        .maptype-control.maptype-control-is-satellite
            .maptype-control-satellite
                display: none


</style>