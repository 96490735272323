<template>
    <div v-if="user">
        <div id="loading_items" v-if="loading_items && !loading">
            <i-loading></i-loading>
        </div>
        <div class="dialog" v-if="loading">
            <div class="dialog-card">
                <div class="dialog-header">
                    {{$t('map.1')}}
                </div>
                <div class="dialog-body">
                    <p>{{$t('map.2')}}</p>

                    <i-loading></i-loading>
                </div>
            </div>

        </div>

        <div class="filter-box" v-if="filter_box">
            <div class="container">
                <div class="search-container">
                    <i-input v-model="filterData.search"
                             id="search"
                             icon="search"
                             ref="search"
                             :loading="search_loading"
                             @on_search="onSearch"
                             :placeholder="$t('nav.4')"
                    ></i-input>

                    <div class="search-button">
                        <i-button @click="showSearchDialog()" small color="secondary" :title="$t('map.54')"></i-button>
                    </div>
                </div>

                <div v-if="assets && assets.length" class="assets-container">
                    <i-button v-for="asset in assets" :active="filterData.active_asset == asset.id ? true : false"
                              @click="setActive(asset)" :key="asset.id" chip :svg="asset.icon"
                              :title="asset.title_en"></i-button>
                </div>
            </div>
        </div>

        <div class="main-page">
            <map-loader ref="map"
                        :map_header="map_header"
                        :mapConfig="mapOptions"
                        :mapData="markers"
                        :activeItem="activeItem"
                        :activeAsset="filterData.active_asset"
                        @close_item_dialog="closeItemDialog"
                        @set_cluster="setCluster"
                        @close_all_cards="closeAllCards"
                        @map_dragend="setMapOptions"
                        @set_active="setActiveItem">

            </map-loader>


            <item-dialog :item="activeItem"
                         :childs="activeItemChilds"
                         :all_items="markers"
                         :asset_child="assetChild"
                         v-if="itemDialog"
                         ref="itemDialog"
                         :style="itemStyle"
                         @close="closeItemDialog" @showDetails="showDetailsDialog"
                         @showAssets="showAssetsDialog"></item-dialog>


            <details-dialog :item="detailsItem" :childs="activeItemChilds"
                            :style="detailsStyle"
                            ref="itemDetails"
                            @remove_device="removeDevice"
                            @update="setActiveItem"
                            @close="closeDetailsDialog" v-if="detailsDialog"></details-dialog>


            <assets-dialog :item="detailsItem"
                           :style="assetsStyle"
                           ref="itemAssets"
                           @close="closeAssetsDialog" v-if="assetsDialog"></assets-dialog>

            <group-of-assets :items="groupOfAssets"
                             v-if="groupDialog"
                             :style="groupStyle"
                             ref="groupDialog"
                             @set_active="setActiveItem"
                             @close="closeGroupDialog">

            </group-of-assets>

            <search-results ref="searchResult" @closeAssets="closeAssetsDialog" @showAssets="showAssetsDialog"></search-results>
            <advanced-search ref="search"></advanced-search>
        </div>

        <info-summary :style="infoSummaryStyle"></info-summary>

    </div>
</template>

<script>
    import assets from './../../assets/json/assets'
    // import MapBox from './MapBox';
    import ItemDialog from './ItemDialog';
    import DetailsDialog from './details/DetailsDialog';
    import AssetsDialog from "./AssetsDialog";
    import InfoSummary from "./InfoSummary";
    import MapLoader from "./GoogleMapLoader";
    import SearchResults from "./SearchResults";
    import AdvancedSearch from './../partial/AdvSearch'
    import GroupOfAssets from './GroupOfAssets'


    export default {
        components: {
            MapLoader,
            ItemDialog,
            DetailsDialog,
            AssetsDialog,
            InfoSummary,
            SearchResults,
            AdvancedSearch,
            GroupOfAssets
        },
        data() {
            return {
                loading: true,
                loading_items: false,
                search_loading: false,

                filter_box: true,

                mapOptions: {
                    longitude1: 27.456205903930677,
                    longitude2: 53.930356927889946,
                    latitude1: 53.869823430537075,
                    latitude2: 27.675932466430677,
                    // scale: 14,
                    scale: 10,
                    // center: {lat: 53.9, lng: 27.56667},
                },
                markers: [],

                filterData: {
                    search: null,
                    active_asset: null,
                },


                activeItem: null,
                detailsItem: null,
                activeItemChilds: null,
                assetChild: null,
                groupOfAssets: null,

                input_output_count: 0,
                input_output_request: 0,

                itemDialog: false,
                detailsDialog: false,
                assetsDialog: false,
                groupDialog: false
            }
        },

        computed: {
            assets() {
                return assets.assets;
            },
            itemStyle() {
                let result;
                // if (this.zoneCards.itemDialog == 1) result = {'left': this.getPosition(1), 'right': 'auto'};
                // if (this.zoneCards.itemDialog == 2) result = {'right': this.getPosition(1), 'left': 'auto'};
                return result;
            },
            detailsStyle() {
                let count = 0;
                let result = {'transform': `translate3d(${count}px, 0px, 0px)`};
                if ((this.getTreeviewModal.show && !this.getResultsModal.show && !this.getClusterModal.show) || (!this.getTreeviewModal.show && this.getResultsModal.show && !this.getClusterModal.show) || (!this.getTreeviewModal.show && !this.getResultsModal.show && this.getClusterModal.show)) count = 353;
                if ((this.getTreeviewModal.show && this.getResultsModal.show && !this.getClusterModal.show) || (!this.getTreeviewModal.show && this.getResultsModal.show && this.getClusterModal.show) || (this.getTreeviewModal.show && !this.getResultsModal.show && this.getClusterModal.show)) count = 706;
                if (this.getTreeviewModal.show && this.getResultsModal.show && this.getClusterModal.show) count = 1059;
                result = {'transform': `translate3d(${count}px, 0px, 0px)`};
                return result;
            },
            assetsStyle() {
                let count = this.getTreeviewModal.position;
                let height = 'calc(100vh - 136px)';
                let result = {'transform': `translate3d(${count})`, 'height': height};
                if (this.getResultsModal.show && (count === '0px, 0px, 0px' || !count)) count = '353px, 0px, 0px';
                if (!this.getResultsModal.show && count === '353px, 0px, 0px') count = '0px, 0px, 0px';
                this.$store.commit('setTreeviewPosition', count);
                if (this.getTreeviewModal.collapse) height = 'calc((100vh - 144px) / 2)';
                result = {'transform': `translate3d(${count})`, 'height': height};
                return result;
            },
            infoSummaryStyle() {
                let result;
                if (this.itemDialog) result = {'right': '357px'};
                if (!this.itemDialog) result = {'right': '16px'};
                return result;
            },
            groupStyle() {
                let count = 0;
                let result = {'transform': `translate3d(${count}px, 0px, 0px)`};
                if ((this.getTreeviewModal.show && !this.getResultsModal.show) || (!this.getTreeviewModal.show && this.getResultsModal.show)) count = 353;
                if (this.getTreeviewModal.show && this.getResultsModal.show) count = 706;
                result = {'transform': `translate3d(${count}px, 0px, 0px)`};
                return result;
            },
            map_header() {
                let result = 'calc(100vh - 132px)';
                if (!this.filter_box) result = 'calc(100vh - 72px)';
                return result;
            },
            user() {
                return this.$store.getters.getUser;
            },
            getItemModal() {
                return this.$store.getters.getItemModal;
            },
            getDetailsModal() {
                return this.$store.getters.getDetailsModal;
            },
            getResultsModal() {
                return this.$store.getters.getResultsModal;
            },
            getTreeviewModal() {
                return this.$store.getters.getTreeviewModal;
            },
            getClusterModal() {
                return this.$store.getters.getClusterModal;
            }
        },

        mounted() {
            this.emitter.on("hideAssetsFilter", e => {
                if (e) this.filter_box = false;
            });
            this.emitter.on("showAssetsFilter", e => {
                if (e) this.filter_box = true;
            });
        },

        methods: {
            /*Load devices from current coordinates and zoom*/
            init() {
                if(!this.loading_items) {
                    this.loading_items = true;
                    let url = 'getAllDevicesByGIS';
                    // this.axios.get(url, {params: this.mapOptions})
                    //     .then(response => {
                    //         if (response) {
                    //             if (response.data.status) this.showErrorMap(response.data.message);
                    //             this.markers = response.data;
                    //             this.loading = false;
                    //             this.loading_items = false;
                    //         }
                    //
                    //     })
                    //     .catch((error) => {
                    //         if (error) this.showErrorMap(error.message);
                    //     })

                    this.axios.post(url, this.mapOptions)
                        .then(response => {
                            if (response) {
                                if (response.data.status) this.showErrorMap(response.data.message);
                                this.markers = response.data;
                                this.loading = false;
                                this.loading_items = false;
                            }

                        })
                        .catch((error) => {
                            if (error) this.showErrorMap(error.message);
                        })
                }

            },
            /*Load data after map drag*/
            setMapOptions(top, bottom, zoom) {
                this.mapOptions.longitude1 = top.lng();
                this.mapOptions.longitude2 = bottom.lng();
                this.mapOptions.latitude1 = top.lat();
                this.mapOptions.latitude2 = bottom.lat();
                this.mapOptions.scale = zoom;
                this.init();
            },

            /*show info about cluster*/
            setActiveCluster() {
                // console.log(e)
                // let url = `getInfoDevice/${e.deviceType}/${e.deviceId}`;
                // this.axios.get(url)

                // let url = 'getFeederNumberOfAssets'
                // let params = {
                //     assetID: e.deviceid,
                //     assetType: e.deviceType
                // }
                // this.axios.get(url, {params: params})
            },

            /*Set the active device after click them on map*/
            setActiveItem(e, details, cluster) {
                this.$refs.map.resetInputOutputCount();
                this.$refs.map.hideActiveSelectedFeeder();
                this.$refs.map.hideActiveHoverFeeder();

                this.input_output_count = 0;
                if (this.itemDialog) this.$refs.itemDialog.setLoading(true);
                if (this.activeItem && e.deviceId != this.activeItem.deviceID) this.closeAssetsDialog();
                let params = {
                    assetID: e.deviceId,
                    assetType: e.deviceType
                };

                /*get device child*/
                this.axios.get('getChilds', {params: params})
                    .then(response => {
                        if (response) {

                            this.activeItemChilds = response.data;
                            // this.input_output_count = this.activeItemChilds.length;
                            this.getInfoDevice(e, cluster);
                        }
                    });
                if (!details) this.closeDetailsDialog();
                if (this.groupDialog) this.$refs.groupDialog.setActiveIndex(e.deviceId);
            },

            getInfoDevice(e, cluster) {
                let url = `getInfoDevice/${e.deviceType}/${e.deviceId}`;
                this.axios.get(url)
                    .then(response => {
                        this.activeItem = response.data;
                        this.activeItem.childs = this.activeItemChilds;
                        this.itemDialog = true;
                        let commit = {
                            show: true,
                            collapse: false
                        };
                        this.$store.commit('setItemModal', commit);
                        this.$nextTick(() => {
                            this.$refs.itemDialog.setLoading(false);
                        });

                        if (!cluster) {
                            this.input_output_request = 0;
                            /*get parents feeders*/
                            if (e.parentsArray && e.parentsArray.length) {
                                // this.input_output_count += 1;
                                e.parentsArray.forEach((parent) => {
                                    if(parent && parent.feederID) {
                                        this.input_output_count += 1;
                                        this.getFeederAssetsUp(parent.feederID, parent.feederType);
                                    }
                                })
                            }

                            /*get child feeders*/
                            if (this.activeItemChilds && this.activeItemChilds.length) {
                                // if (this.activeItemChilds && this.activeItemChilds.length) this.getFeederAssetsDown(this.activeItem.FeederID, this.activeItem.FeederType, this.activeItem.deviceID, this.activeItem.deviceType);
                                this.activeItemChilds.forEach((child, index) => {
                                    if (child && child.FeederID) {
                                        this.input_output_count += 1;
                                        this.getFeederAssetsDown(child.FeederID, child.FeederType, child.childID, child.childType, index, child);
                                    }

                                    /*additional info for service_point and pillar*/
                                    if (response.data.deviceType == 113 || response.data.deviceType == 115) {
                                        let url_asset = `getInfoDevice/${child.childType}/${child.childID}`;
                                        this.axios.get(url_asset)
                                            .then(response_asset => {
                                                this.activeItemChilds[index].info = response_asset.data;
                                                if(this.detailsDialog && index+1 == this.activeItemChilds.length) {
                                                    this.$nextTick(() => {
                                                        this.$refs.itemDetails.updateChilds(this.activeItemChilds);
                                                    })
                                                }
                                            });

                                        if (index == 0) {
                                            let params = {
                                                assetID: child.childID,
                                                assetType: child.childType
                                            };
                                            this.axios.get('getChilds', {params: params})
                                                .then(response => {
                                                    if (response) this.assetChild = response.data;
                                                });
                                        }
                                    }
                                });

                            }
                        }
                    })
            },

            getFeederAssetsUp(fiderId, fiderType, id, type, child_index, child) {
                this.getFeederAssets('up', fiderId, fiderType, id, type, null, child_index, child)
            },
            getFeederAssetsDown(fiderId, fiderType, id, type, child_index, child) {
                this.getFeederAssets('down', fiderId, fiderType, id, type, null, child_index, child)
            },

            getFeederAssets(direction, fiderId, fiderType, id, type, action, child_index, child) {
                this.emitter.emit('deviceActiveHide');
                let url = `getFeederAssets`;
                let params = {
                    feederID: fiderId ? fiderId : this.activeItem.parentsArray[0].FeederID,
                    feederType: fiderType ? fiderType : this.activeItem.parentsArray[0].FeederType,
                    assetID: id ? id : this.activeItem.deviceID,
                    assetType: type ? type : this.activeItem.deviceType,
                    direction: direction
                };
                this.axios.get(url, {params: params})
                    .then(response => {
                        if (response) {
                            let result = response.data;
                            if (direction === 'down') {
                                result.unshift({
                                    'AssetID': id,
                                    'AssetType': type,
                                    'ParentID': this.activeItem.deviceID,
                                    'ParentType': this.activeItem.deviceType
                                });
                            }

                            //back-end для down и result.length == 1 отдает значение без координат, здесь подставляем координаты в ответ
                            if(direction === 'down' && result.length == 1) {
                                result[0].Latitude = child.Latitude;
                                result[0].Longitude = child.Longitude;

                                result.push({
                                    'AssetID': this.activeItem.deviceID,
                                    'AssetType': this.activeItem.deviceType,
                                    'Latitude': this.activeItem.Latitude,
                                    'Longitude': this.activeItem.Longitude,
                                })
                            }

                            if (result.length && !action) {
                                this.input_output_request += 1;
                                this.emitter.emit("deviceActive", [result, direction, this.activeItem]);
                                this.$refs.map.showActiveFeeder(result, direction, this.activeItem, this.input_output_count, this.input_output_request);
                            }
                            if (result.length && action === 'hover') this.emitter.emit("feederHover", [result, direction]);

                            if (direction === 'up') this.setHoverAssetsUp(result);
                            if (direction === 'down') this.setHoverAssetsDown(result, child_index);
                        }


                    })
            },

            /*set feeders for style on map - parents */
            setHoverAssetsUp(result) {
                this.activeItem.parentsArray.forEach(parent => {
                    parent.feeder = [];
                    parent.feeder.push({
                        'ParentID': parent.ParentID,
                        'AssetID': this.activeItem.deviceID,
                        'ParentType': parent.ParentType,
                        'FeederID': parent.FeederID,
                        'FeederType': parent.FeederType
                    });
                    let last_item = parent.ParentID;
                    result.forEach(item => {
                        if (item.AssetID == last_item && item.ParentID) {
                            parent.feeder.push({
                                'ParentID': item.ParentID,
                                'AssetID': item.AssetID,
                                'ParentType': item.ParentType
                            });
                            last_item = item.ParentID;
                        }
                    });
                })
            },

            /*set feeders for style on map - child */
            setHoverAssetsDown(result, child_index) {
                this.activeItemChilds[child_index].feeder = [];
                result.forEach(item => {
                    this.activeItemChilds[child_index].feeder.push({
                        'ParentID': item.ParentID,
                        'AssetID': item.AssetID
                    });
                });
            },

            closeAllCards() {
                this.closeItemDialog();
                this.closeDetailsDialog();
                this.closeAssetsDialog();
                this.closeSearchResultDialog();
            },

            closeItemDialog() {
                this.itemDialog = false;
                let commit = {
                    show: false,
                    collapse: false
                };
                this.$store.commit('setItemModal', commit);
                this.activeItem = null;
                this.$refs.map.hideSelectedItem();
                this.closeDetailsDialog();
                this.closeAssetsDialog();
                if (this.groupDialog) this.$refs.groupDialog.resetActive();
            },

            setActive(e) {
                if (!this.filterData.active_asset) {
                    this.filterData.active_asset = e.id;
                    this.$refs.map.setActive(this.filterData.active_asset);
                    return;
                }
                if (this.filterData.active_asset && this.filterData.active_asset == e.id) {
                    this.filterData.active_asset = null;
                    this.$refs.map.setActive(this.filterData.active_asset);
                    return;
                }
                if (this.filterData.active_asset && this.filterData.active_asset != e.id) {
                    this.filterData.active_asset = e.id;
                    this.$refs.map.setActive(this.filterData.active_asset);
                }
            },

            onSearch(e) {
                if (e) {
                    this.search_loading = true;
                    let url = 'getAllDevicesByParameters';
                    let params = {
                        AssetName: e
                    };
                    this.axios.post(url, params)
                        .then(response => {
                            if (response.data) {
                                this.emitter.emit("searchResultsData", response.data);
                                this.emitter.emit("closeAssets", true);
                                this.search_loading = false;
                                this.filterData.search = null;
                            }
                        })
                }
            },

            setCluster(e) {
                if (e) {
                    let url = `getListOfGroupAssets`;
                    let params = {
                        deviceID: e.deviceId
                    };
                    this.axios.get(url, {params: params})
                        .then(response => {
                            if (response) {
                                this.groupOfAssets = response.data;
                                this.groupDialog = true;
                                let commit = {
                                    show:  true,
                                    collapse: false
                                };
                                this.$store.commit('setClusterModal', commit);
                            }
                        })
                }
            },
            removeDevice(id) {
                this.activeItemChilds.forEach((item, index) => {
                    if (item.childID == id) this.activeItemChilds.splice(index, 1)
                })
            },

            showDetailsDialog(item) {
                this.detailsItem = item;
                this.detailsDialog = !this.detailsDialog;
                let commit = {
                    show:  true,
                    collapse: false
                };
                this.$store.commit('setDetailsModal', commit);
            },
            showAssetsDialog(item) {
                this.detailsItem = item;
                this.assetsDialog = true;
                this.$store.commit('setTreeviewModal', true);
            },
            showSearchDialog() {
                this.emitter.emit("showSearchDialog", true);
            },
            closeSearchResultDialog() {
                this.emitter.emit("closeSearchResultDialog", true);
            },
            closeDetailsDialog() {
                this.detailsDialog = false;
                let commit = {
                    show:  false,
                    collapse: false
                };
                this.$store.commit('setDetailsModal', commit);
            },
            closeAssetsDialog() {
                this.assetsDialog = false;
                this.$store.commit('setTreeviewModal', false);
            },
            showErrorMap(error) {
                this.mainNotification(error);
            },
            closeGroupDialog() {
                this.groupDialog = false;
                this.$refs.map.removeActiveCluster();
                let commit = {
                    show:  false,
                    collapse: false
                };
                this.$store.commit('setClusterModal', commit);
            }

        }
    }
</script>

<style scoped lang="sass">
    #loading_items
        position: fixed
        left: 20px
        bottom: 20px
        z-index: 10

    .filter-box
        background: var(--background-white)
        padding: 8px 0
        box-shadow: var(--shadow-100)

        .container
            display: grid
            align-items: center
            grid-template-columns: 324px auto
            grid-column-gap: 30px

            .search-container
                display: grid
                justify-content: space-between
                grid-template-columns: 214px 106px

            .search-button
                button.button
                    height: 40px
                    font-size: 14px

                    &:hover
                        cursor: pointer

            .assets-container
                display: flex
                padding: 0 4px

                button
                    flex: 1 1 auto
                    margin: 0 2px

        .drag-zone
            position: fixed
            top: 0
            bottom: 0
            height: 100vh
            width: 50vw
            pointer-events: none

        .drag-zone.zone-left
            left: 0

        .drag-zone.zone-right
            right: 0
</style>