<template>
    <div class="dialog-side" id="itemDialog">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div class="card-drag" id="drag-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                              fill="#909194"/>
                    </svg>
                </div>
                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div ref="deviceInfo">
                    <div class="asset-title asset-title-icon" v-if="item">
                        <div class="image-container"><img :src="'/svg/assets/' + item.deviceType + '_single.svg'"
                                                          alt=""></div>
                        <div>
                            <div class="dialog-title mb-1">{{item.Name}}</div>
                            <div class="small text-gray-light text-capitalize">{{showType(item.deviceType)}}</div>
                        </div>
                    </div>

                    <div class="pl-2" v-if="!loading">
                        <div :class="device && device.deviceType == 104 ? 'mb-4' : 'mb-1'">
                            <div class="button-details" @click="$emit('showDetails', device)">
                                <img src="/svg/details.svg" alt="" class="icon">
                                <span>{{$t('map.3')}}</span>
                                <img src="/svg/arrow-right.svg" alt="" class="icon-arrow">
                            </div>
                        </div>

                        <div class="mb-4" v-if="device && device.deviceType != 104">
                            <div class="button-details" @click="$emit('showAssets', device)">
                                <img src="/svg/treeview.svg" alt="" class="icon">
                                <span>{{$t('map.4')}}</span>
                                <img src="/svg/arrow-right.svg" alt="" class="icon-arrow">
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="loading" class="text-center">
                    <i-loading></i-loading>
                </div>
                <div v-else class="feeders-input-output" :style="{height:feederBoxHeight}">
                    <!-- ASSETS -->
                    <!-- box (113) or pillar (115) -->
                    <div v-if="device && (device.deviceType == 113 || device.deviceType == 115) && device && childs"
                         class="assets-data">
                        <div class="font-600 middle mb-3">{{$t('map.21')}}</div>

                        <div class="assets-data__nav">
                            <i-button round extrasmall
                                      :outline="activeAssetType == 116 || activeAssetType == 112"
                                      :title="$t('buttons.2') + '  ' + (assetsNav.gateway + assetsNav.service_point)"
                                      @click="activeAssetType = 'all'"></i-button>
                            <i-button :outline="activeAssetType === 'all' || activeAssetType == 112" round
                                      extrasmall :title="$t('map.24') + '  ' + assetsNav.gateway"
                                      @click="activeAssetType = 116"></i-button>
                            <i-button :outline="activeAssetType === 'all' || activeAssetType == 116" round extrasmall
                                      :title="$t('map.25') + '  ' + assetsNav.service_point"
                                      @click="activeAssetType = 112"></i-button>
                        </div>

                        <div v-for="child in childs" :key="child.childID">
                            <div class="assets-data__item"
                                 v-if="activeAssetType === 'all' || activeAssetType == child.childType">
                                <div class="image-container"><img :src="'/svg/assets/'+child.childType+'_single.svg'"
                                                                  alt=""
                                                                  width="18" height="18"></div>
                                <div>
                                    <div class="font-500">{{child.childID}}</div>
                                    <div class="text-gray-dark text-capitalize">{{showType(child.childType)}}</div>
                                </div>
                                <div class="text-end"
                                     v-if="child.childType == 112 && child.info && child.info.PhaseConnectionID">
                                    <div class="chip phase">{{showPhase(child.info.PhaseConnectionID)}}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div v-if="device && device.parentsArray && childs" class="feeders-data">
                        <!-- FEEDERS -->
                        <!-- box (113) or pillar (115) -->
                        <div v-if="device.deviceType == 113 || device.deviceType == 115" class="font-600 middle mb-3">
                            {{$t('map.22')}}
                        </div>

                        <div class="feeders-data__nav">
                            <i-button round extrasmall
                                      :outline="activeFeederType === 'input' || activeFeederType === 'output'"
                                      :title="$t('buttons.2') + '  ' + (parentLength + outputLength)"
                                      @click="activeFeederType = 'all'"></i-button>
                            <i-button :outline="activeFeederType === 'all' || activeFeederType === 'output'" round
                                      extrasmall :title="$t('buttons.3') + '  ' + parentLength"
                                      @click="activeFeederType = 'input'"></i-button>
                            <i-button :outline="activeFeederType === 'all' || activeFeederType === 'input'" round
                                      extrasmall
                                      :title="$t('buttons.4') + '  ' + outputLength"
                                      @click="activeFeederType = 'output'"></i-button>
                        </div>

                        <div>

                            <!-- INPUT -->
                            <div v-if="device.parentsArray && device.parentsArray.length && (activeFeederType === 'all' || activeFeederType === 'input')">
                                <div v-for="(parent, index) in device.parentsArray" :key="parent.parentID+'_'+index"
                                     @mouseover="setHoverFeeder(parent)" @mouseleave="removeHoverFeeder(parent)">
                                    <div v-if="parent.FeederID" class="feeders-data__item">
                                        <input type="radio" :id="parent.FeederID+'_'+index" v-model="assetDetails"
                                               :value="parent.FeederID+'_'+parent.ParentID" @click="checkAssetDetails(parent, 'up')"
                                               @input="selectDetails(parent, 'up')">
                                        <label :for="parent.FeederID+'_'+index">
                                            <div>
                                                <div class="font-500">{{showType(parent.FeederType)}} {{parent.FeederID}}
                                                    <div class="main" v-if="parent.MainFeeder">
                                                        <span class="title">MF</span>
                                                        <span class="help">Main Feeder</span>
                                                    </div>
                                                </div>
                                                <div class="text-gray-dark text-capitalize">
                                                    {{showType(parent.ParentType)}}
                                                    {{parent.ParentID}}
                                                </div>
                                            </div>
                                            <div class="chip input">{{$t('buttons.3')}}</div>
                                        </label>
                                    </div>

                                    <div v-if="assetDetails && assetDetails == parent.FeederID+'_'+parent.ParentID && assetDetailsData && assetDetailsData.length"
                                         class="asset_details">
                                        <div v-for="(asset, index) in assetDetailsData" :key="index"
                                             class="d-flex align-center"
                                             :class="{'mb-5': index + 1 < assetDetailsData.length}">
                                            <img :src="'/svg/assets/' + asset.deviceType + '_single.svg'" width="18"
                                                 alt="" class="me-2">
                                            <div class="font-500 me-3">{{asset.deviceTypeName}}</div>
                                            <span class="small text-gray-dark">{{asset.NumberOfAssets}}</span>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- OUTPUT -->
                            <!-- not box (113) and no pillar (115) -->
                            <div v-if="device.deviceType != 113 && device.deviceType != 115">
                                <div v-if="childs && childs.length && (activeFeederType === 'all' || activeFeederType === 'output')">
                                    <div v-for="child in childs" :key="child.childID" class="feeders-data__item">
                                        <input type="radio" :id="child.FeederID+'_'+child.childID" v-model="assetDetails"
                                               :value="child.FeederID+'_'+child.childID" @click="checkAssetDetails(child, 'down')"
                                               @input="selectDetails(child, 'down')">
                                        <label :for="child.FeederID+'_'+child.childID">
                                            <div>
                                                <div class="font-500">{{showType(child.FeederType)}} {{child.FeederID}}</div>
                                                <div class="text-gray-dark text-capitalize">
                                                    {{showType(child.childType)}}
                                                    {{child.childID}}
                                                </div>
                                            </div>
                                            <div class="chip output">{{$t('buttons.4')}}</div>
                                        </label>

                                        <div v-if="assetDetails && assetDetails == child.FeederID+'_'+child.childID && assetDetailsData && assetDetailsData.length"
                                             class="asset_details child">
                                            <div v-for="(asset, index) in assetDetailsData" :key="index"
                                                 class="d-flex align-center"
                                                 :class="{'mb-5': index + 1 < assetDetailsData.length}">
                                                <img :src="'/svg/assets/' + asset.deviceType + '_single.svg'" width="18"
                                                     alt="" class="me-2">
                                                <div class="font-500 me-3">{{asset.deviceTypeName}}</div>
                                                <span class="small text-gray-dark">{{asset.NumberOfAssets}}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- box (113) or pillar (115) -->
                            <div v-if="device.deviceType == 113 || device.deviceType == 115">
                                <div v-if="boxPillarChild && boxPillarChild.length && (activeFeederType === 'all' || activeFeederType === 'output')">
                                    <div v-for="child in boxPillarChild" :key="child.childID" class="feeders-data__item">
                                        <input type="radio" :id="child.childID" v-model="assetDetails"
                                               :value="child.childID" @click="checkAssetDetails(child, 'down')"
                                               @input="selectDetails(child, 'down')">
                                        <label :for="child.childID">
                                            <div>
                                                <div class="font-500">{{showType(child.FeederType)}} {{child.FeederID}}</div>
<!--                                                <div class="text-gray-dark text-capitalize">-->
<!--                                                    {{showType(child.childType)}}-->
<!--                                                    {{child.childID}}-->
<!--                                                </div>-->
                                            </div>
                                            <div class="chip output">{{$t('buttons.4')}}</div>
                                        </label>

                                        <div v-if="assetDetails && assetDetails == child.childID && assetDetailsData && assetDetailsData.length"
                                             class="asset_details child">
                                            <div v-for="(asset, index) in assetDetailsData" :key="index"
                                                 class="d-flex align-center"
                                                 :class="{'mb-5': index + 1 < assetDetailsData.length}">
                                                <img :src="'/svg/assets/' + asset.deviceType + '_single.svg'" width="18"
                                                     alt="" class="me-2">
                                                <div class="font-500 me-3">{{asset.deviceTypeName}}</div>
                                                <span class="small text-gray-dark">{{asset.NumberOfAssets}}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!--                            <div v-if="device">-->
                            <!--                                <hr>-->
                            <!--                                <div class="font-600">INPUT</div>-->
                            <!--                                <div v-for="parent in  device.parentsArray" :key="parent.parentID+'_'+index" class="mb-2">-->
                            <!--                                    {{parent}}-->
                            <!--                                </div>-->
                            <!--                                <hr>-->
                            <!--                                <div class="font-600">-->
                            <!--                                    Item ID - {{item.deviceId}} | Item type - {{item.deviceType}}-->
                            <!--                                    <div>Locations: lat - {{device.Latitude}}, lng - {{device.Longitude}}</div>-->
                            <!--                                </div>-->
                            <!--                                <hr>-->
                            <!--                                <div class="font-600">OUTPUT</div>-->
                            <!--                                <div v-for="child in  childs" :key="child.childID" class="mb-2">-->
                            <!--                                    {{child}}-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            item: Object,
            childs: Array,
            asset_child: Array,
            all_items: Object,

        },
        data() {
            return {
                loading: true,

                device: null,
                activeFeederType: 'all',
                activeAssetType: 'all',

                assetDetails: null,
                assetDetailsData: null,
                assetDetailsFeeder: null,
                assetChild: [],
            }
        },

        computed: {
            devicesList() {
                return this.$store.getters.getSettings.devicesList;
            },
            phaseOptions() {
                return this.$store.getters.getSettings.spPhaseConnection[0]
            },

            feederBoxHeight() {
                let header = this.$refs.header.offsetHeight;
                let deviceInfo = 0;
                if (this.$refs.deviceInfo && !this.loading) deviceInfo = this.$refs.deviceInfo.offsetHeight;
                // let feedersNav = 62;
                // if (this.$refs.feedersNav) feedersNav = this.$refs.feedersNav.offsetHeight;
                let result = window.innerHeight - 136 - 48 - 16 - 16 - 68 - header - deviceInfo;
                return result + 'px'
            },

            boxPillarChild() {
                let result = [];
                if(this.device.deviceType == 113 || this.device.deviceType == 115) {
                    result = this.childs.reduce((o, i) => {
                        if (!o.find(v => v.FeederID == i.FeederID)) {
                            o.push(i);
                        }
                        return o;
                    }, []);
                }
                return result;
            },

            parentLength() {
                let result = 0;
                if (this.device && this.device.parentsArray.length) {
                    this.device.parentsArray.forEach(parent => {
                        if (parent.FeederID) result += 1;
                    })
                }
                return result;
            },
            outputLength() {
                let result = 0;
                if (this.device.deviceType == 113 || this.device.deviceType == 115) {
                    if (this.boxPillarChild) result = this.boxPillarChild.length
                } else result = this.childs.length;

                return result;
            },

            assetsNav() {
                let result = {
                    service_point: 0,
                    gateway: 0,
                };
                if (this.childs && this.childs.length) {
                    this.childs.forEach(child => {
                        if (child.childType == 112) result.service_point += 1;
                        if (child.childType == 116) result.gateway += 1;
                    })
                }
                return result
            }
        },

        watch: {
            item() {
                this.init();
            },
            asset_child() {
                this.init();
            }
        },

        mounted() {
            this.init();
            Draggable.create("#itemDialog", {
                trigger:"#drag-item",
                bounds:".main-page",
                edgeResistance:0.65,
                inertia:true,
                autoScroll:1,
            });
        },

        methods: {
            init() {
                this.device = this.item;
                this.assetChild = this.asset_child;
            },

            setLoading(value) {
                this.loading = value;
            },

            showType(type) {
                let result;
                if (this.devicesList) {
                    this.devicesList.forEach(device => {
                        if (device.id == type) result = device.name
                    })
                }
                return result
            },
            showPhase(phase) {
                let result = this.phaseOptions.filter(item => item.Code == phase)[0];
                return result.Name;
            },

            setHoverFeeder() {
                // this.getFeederAssets('up', e.ParentID, e.ParentType, e.fiderId, e.fiderType, 'hover')
                // this.emitter.emit("feederHover", e);
            },
            removeHoverFeeder() {
                // this.emitter.emit("feederLeave", e);
            },

            closeDialog() {
                this.$emit('close');
            },

            /*select active asset (input/output) in dialog and highlight on the map */
            selectDetails(e, direction) {
                this.assetDetailsData = null;
                let url = 'getFeederNumberOfAssets';
                let params = {
                    feederID: e.FeederID,
                    feederType: e.FeederType,
                    assetID: direction === 'up' ? e.ParentID : e.parentID,
                    assetType: direction === 'up' ? e.ParentType : e.parentType,
                    direction: direction
                };
                this.axios.get(url, {params: params})
                    .then(response => {
                        if (response) {
                            this.assetDetailsData = response.data;
                            this.assetDetailsFeeder = e.FeederID;
                        }
                    });
                if (direction === 'up') this.emitter.emit("feederHover", [e.feeder, direction, this.device, this.childs]);
                if (direction === 'down') this.emitter.emit("feederHover", [e.feeder, direction, this.device, this.childs]);
            },

            /*reset active asset (input/output) from dialog and from map */
            checkAssetDetails(e, direction) {
                if (e && direction == 'up' && e.FeederID+'_'+e.ParentID == this.assetDetails) {
                    this.assetDetails = null;
                    this.emitter.emit("feederLeave", e);

                }
                if (e && direction == 'down' && e.FeederID+'_'+e.childID == this.assetDetails) {
                    this.assetDetails = null;
                    this.emitter.emit("feederLeave", e);
                }
            },
        }
    }
</script>