export default {
    actions: {
        async fetchUser(ctx) {
            if(localStorage.getItem('token')) {
                let user = {
                    areaid: localStorage.getItem('areaid'),
                    city: localStorage.getItem('city'),
                    country: localStorage.getItem('country'),
                };
                ctx.commit('setUser', user);
            }
            else ctx.commit('setUser', null);
            // let get_user_url = '/api/user/get_authenticated_user';
            // axios.get(get_user_url).then(response => {
            //     if (response.data) {
            //         let data = response.data.data;
            //         data.role = parseInt(data.role);
            //         console.log('Authenticated user:', data);
            //         ctx.commit('setUser', response.data.data);
            //
            //     } else {
            //         localStorage.removeItem('token');
            //         localStorage.removeItem('expiration');
            //         console.log('User is not authenticated');
            //     }
            // });
        }
    },

    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },

    state: {
        user: null,
    },

    getters: {
        getUser(state) {
            return state.user
        }
    },
}
