<template>
    <div>
        <div class="mb-12 row" v-if="show_asset">
            <i-input class="col-7" v-model="type" id="type" :label="$t('map.7')" disabled></i-input>
        </div>

        <!-- General -->
        <div class="font-600 mb-6">{{$t('map.15')}}</div>

        <div class="row mb-9">
            <i-input class="col-7 mb-4" v-model="name" required v-if="name_access" id="name"
                     :label="$t('map.9')"
                     :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="date" type="date" v-if="date_access" id="date"
                     :label="$t('map.10')"
                     :disabled="!edit"></i-input>

            <i-select class="col-5 mb-4" v-model="phase_connection" v-if="phase_connection_access" id="phase_connection"
                      :label="$t('map.18')" :disabled="!edit" :options="phaseOptions" title="Name"
                      option_key="Code"></i-select>

            <i-input class="col-7 mb-4" v-model="serial_number" v-if="serial_number_access"
                     id="serial_number" :label="$t('map.11')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="manufacturer" v-if="manufacturer_access" id="manufacturer"
                     :label="$t('map.12')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="brand" v-if="manufacturer_brand_access" id="manufacturer_brand"
                     :label="$t('map.12')" :disabled="!edit"></i-input>

            <i-input class="col-7 mb-4" v-model="address" v-if="address_access" id="address"
                     :label="$t('map.36')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="manufacturer_date" type="date"
                     v-if="manufacturer_date_access"
                     id="manufacturer_date" :label="$t('map.37')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="production_date" type="date"
                     v-if="production_date_access"
                     id="production_date" :label="$t('map.37')" :disabled="!edit"></i-input>


        </div>

        <!-- Technical -->
        <div v-if="capacity_access || power_access || primary_voltage_access || secondary_voltage_access || number_of_feeders_access || height_access || type_id_access || number_of_meters_access || GPRS_signal_access || location_type_id_access">
            <div class="font-600 mb-6" v-if="item.deviceType != 116">{{$t('map.16')}}</div>

            <div class="row">
                <i-input class="col-5 mb-4" v-model="capacity" v-if="capacity_access" id="type"
                         :label="$t('map.19')" :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" v-model="power" v-if="power_access" id="date" :label="$t('map.20')"
                         :disabled="!edit"></i-input>
            </div>
            <div class="row mb-9">
                <i-select class="col-5 mb-4" v-model="primary_voltage" v-if="primary_voltage_access" title="Name"
                          option_key="Code"
                          id="primary_voltage" :label="$t('map.26')" :disabled="!edit"
                          :options="transformerPrimaryVoltageOptions"></i-select>
                <i-select class="col-5 mb-4" v-model="secondary_voltage" v-if="secondary_voltage_access" title="Name"
                          option_key="Code"
                          id="secondary_voltage" :label="$t('map.27')" :disabled="!edit"
                          :options="transformerSecondaryVoltageOptions"></i-select>
                <i-input class="col-5 mb-4" v-model="number_of_feeders"
                         :required="data_for_create && (item.deviceType == 100 || item.deviceType == 102 || item.deviceType == 111)"
                         v-if="number_of_feeders_access"
                         id="number_of_feeders" :label="$t('map.28')"
                         :disabled="data_for_create ? false : true"></i-input>
                <i-input class="col-5 mb-4" v-model="height" v-if="height_access" id="height"
                         :label="$t('map.30')" :disabled="!edit"></i-input>
                <i-select class="col-5 mb-4" v-model="type_id" v-if="type_id_access" id="type_id"
                          :label="$t('map.31')" :disabled="!edit" :options="typeOptions" title="Name"
                          option_key="Code"></i-select>

                <i-input class="col-5 mb-4" v-model="number_of_meters" v-if="number_of_meters_access"
                         id="number_of_meters" :label="$t('map.32')" :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" v-model="GPRS_signal" disabled v-if="GPRS_signal_access"
                         id="GPRS_signal"
                         :label="$t('map.29')"></i-input>
                <i-select class="col-5 mb-4" v-model="location_type_id" v-if="location_type_id_access"
                          id="location_type_id"
                          :label="$t('map.35')" :disabled="!edit" :options="locationTypeOptions" title="Name"
                          option_key="Code"></i-select>

            </div>
        </div>


        <!-- Warranty -->
        <div v-if="item.deviceType == 111 || item.deviceType == 100 || item.deviceType == 102">
            <div class="font-600 mb-6">{{$t('map.34')}}</div>

            <div class="row mb-9">
                <i-input class="col-5 mb-4" v-model="maintenance_period" id="maintenance_period"
                         :label="$t('map.38')" :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" type="date" v-model="last_maintenance_check_date"
                         id="last_maintenance_check_date" :label="$t('map.39')" :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" v-model="warranty_time" id="warranty_time" :label="$t('map.40')"
                         :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" type="date" v-model="warranty_expires" id="warranty_expires"
                         :label="$t('map.41')" :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" v-model="asset_value" id="asset_value" :label="$t('map.42')"
                         :disabled="!edit"></i-input>
                <i-input class="col-5 mb-4" v-model="asset_maintenance_value" id="asset_maintenance_value"
                         :label="$t('map.43')" :disabled="!edit"></i-input>
            </div>
        </div>

        <!-- Devices -->
      
        <div class="mb-12" v-if="item.deviceType == 113 || item.deviceType == 115">
            <div class="font-600 mb-6">{{$t('map.33')}}</div>
            <div v-if="childs_details && childs_details.length">
                <div v-for="(child, index) in childs_details" :key="child.childID" class="collapse"
                     :class="{'active': activeDeviceChild == index}">
                    <div class="collapse-header" @click="selectDeviceChild(index)">
                        <img :src="'/svg/assets/'+child.childType+'_single.svg'"
                             alt="" class="child-icon"
                             width="18" height="18">
                        <span class="me-2" v-if="child.childType == 112">Service Point {{index + 1}} </span>
                        <span class="me-2" v-if="child.childType == 116">Gateway {{index + 1}} </span>

                        <span v-if="!child.ID"> (New)</span>

                        <div class="collapse-select">
                            <img src="/svg/arrow.svg" alt="">
                        </div>
                    </div>
                    <div class="collapse-body" v-if="activeDeviceChild == index">
                        <div class="row">
                            <i-input class="col-7 mb-4" v-model="child.Name" required
                                     :id="'name_child_' + child.childID"
                                     :label="$t('map.9')" :disabled="!edit"></i-input>

                            <i-select class="col-5 mb-4" v-model="child.PhaseConnectionID"
                                      id="'phase_child_' + child.childID"
                                      :label="$t('map.18')" :disabled="!edit" :options="phaseOptions" title="Name"
                                      option_key="Code"></i-select>

                            <i-input class="col-7 mb-4" v-model="child.SerialNumber"
                                     :id="'serial_number_child_' + child.childID"
                                     :label="$t('map.11')" :disabled="!edit"></i-input>

                            <i-input class="col-7 mb-4" v-model="child.Address"
                                     :id="'address_child_' + child.childID"
                                     :label="$t('map.36')" :disabled="!edit"></i-input>
                        </div>
                        <div class="row" v-if="child.childType == 112 && !data_for_create">
                            <i-input class="col-5 mb-4" v-model="child.GPRSSignal"
                                     :id="'gprs_child_' + child.childID"
                                     :label="$t('map.29')" disabled></i-input>

                        </div>

                        <div class="row" v-if="child.childType == 112">
                            <i-input class="col-5 mb-4" v-model="child.FirstName"
                                     :id="'first_name_child_' + child.childID"
                                     :label="$t('map.45')" :disabled="!edit"></i-input>
                            <i-input class="col-5 mb-4" v-model="child.LastName"
                                     :id="'last_name_child_' + child.childID"
                                     :label="$t('map.46')" :disabled="!edit"></i-input>
                            <i-input class="col-7 mb-4" v-model="child.Email"
                                     :id="'email_child_' + child.childID"
                                     :label="$t('map.50')" :disabled="!edit"></i-input>
                            <i-input class="col-5 mb-4" v-model="child.Mobile"
                                     :id="'mobile_child_' + child.childID"
                                     :label="$t('map.49')" :disabled="!edit"></i-input>
                        </div>
                        <div class="row">
                            <i-textarea class="col-7" v-model="child.Notes" :id="'notes_child_' + child.childID"
                                        :label="$t('map.48')" :disabled="!edit"></i-textarea>
                        </div>

                        <i-button img="/svg/remove.svg"
                                  @click="removeDetail(child, child.childType == 112 ? 'Service Point ' + (index + 1) : 'Gateway ' + (index + 1), index)"
                                  text color="remove"
                                  :title="$t('buttons.8')"></i-button>
                    </div>
                </div>
            </div>

            <i-dropdown :title="$t('buttons.10')" :options="devices_options"
                        v-if="edit"
                        img="/svg/add.svg"
                        @click_option="addNewDevice"
                        class="secondary inline large">
            </i-dropdown>
        </div>


        <!-- Other -->
        <div class="font-600 mb-6">{{$t('map.17')}}</div>

        <div class="row">
            <i-input class="col-7 mb-4" v-model="ownership" id="ownership" v-if="ownership_access"
                     :label="$t('map.44')" :disabled="!edit"></i-input>
        </div>
        <div class="row">
            <i-input class="col-5 mb-4" v-model="first_name" id="first_name" v-if="first_name_access"
                     :label="$t('map.45')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="last_name" id="last_name" v-if="last_name_access"
                     :label="$t('map.46')" :disabled="!edit"></i-input>

            <i-input class="col-7 mb-4" v-model="contact_person" id="contact_person"
                     v-if="contact_person_access" :label="$t('map.47')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="person_in_charge"
                     id="person_in_charge" v-if="person_in_charge_access"
                     :label="$t('map.47')" :disabled="!edit"></i-input>

            <i-input class="col-7 mb-4" v-model="email" id="email" v-if="email_access" :label="$t('map.50')"
                     :disabled="!edit"></i-input>

            <i-input class="col-5 mb-4" v-model="contact_person_phone_number"
                     id="contact_person_phone_number" v-if="contact_person_phone_number_access"
                     :label="$t('map.49')" :disabled="!edit"></i-input>
            <i-input class="col-5 mb-4" v-model="operator_phone_number"
                     id="operator_phone_number" v-if="operator_phone_number_access"
                     :label="$t('map.49')" :disabled="!edit"></i-input>

            <i-input class="col-5 mb-4" v-model="mobile"
                     id="mobile" v-if="mobile_access"
                     :label="$t('map.49')" :disabled="!edit"></i-input>
        </div>
        <div class="row mb-9">
            <i-textarea class="col-7 mb-4" v-model="notes" id="notes" :label="$t('map.48')"
                        :disabled="!edit"></i-textarea>
        </div>


        <div v-if="loading" class="loading-overlay">
            <i-loading></i-loading>
        </div>
    </div>
</template>

<script>
    import assets from '../../../assets/json/assets';
    import 'v-calendar/dist/style.css';

    export default {
        props: {
            item: Object,
            childs: Object,
            editable: {
                type: Boolean,
                default: false
            },
            show_asset: {
                type: Boolean,
                default: true
            },
            data_for_create: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: true,
                edit: false,

                name: null,
                date: null,
                serial_number: null,
                manufacturer: null,
                brand: null,
                address: null,
                manufacturer_date: null,
                production_date: null,
                phase_connection: null,
                capacity: null,
                power: null,
                primary_voltage: null,
                secondary_voltage: null,
                number_of_feeders: null,
                GPRS_signal: null,
                height: null,
                type_id: null,
                location_type_id: null,
                number_of_meters: null,
                maintenance_period: null,
                last_maintenance_check_date: null,
                warranty_time: null,
                warranty_expires: null,
                asset_value: null,
                asset_maintenance_value: null,
                ownership: null,
                first_name: null,
                last_name: null,
                contact_person: null,
                contact_person_phone_number: null,
                person_in_charge: null,
                operator_phone_number: null,
                mobile: null,
                notes: null,
                email: null,

                activeDeviceChild: null,
                computedHeight: 0,

                devices_options: [
                    {type: 112, title: this.$t('map.25')},
                    {type: 116, title: this.$t('map.24')}
                ],

                childs_details: [],
            }
        },

        computed: {
            invalid() {
                let result = false;
                if (this.data_for_create && (this.item.deviceType == 100 || this.item.deviceType == 102 || this.item.deviceType == 111)) {
                    if (!this.number_of_feeders) result = true;
                }
                if (this.item.deviceType == 113 || this.item.deviceType == 115) {
                    if (!this.name) result = true;
                    this.childs_details.forEach(child => {
                        if (!child.Name) result = true;
                    })
                } else {
                    if (!this.name) result = true;
                }
                return result;
            },
            type() {
                let result;
                assets.assets.forEach(asset => {
                    if (this.item && asset.id == this.item.deviceType) result = asset.title_en;
                });
                return result;
            },

            typeOptions() {
                return this.$store.getters.getSettings.poleType[0]
            },
            phaseOptions() {
                return this.$store.getters.getSettings.spPhaseConnection[0]
            },
            transformerPrimaryVoltageOptions() {
                if (this.item.deviceType == 100) return this.$store.getters.getSettings.substationPrimaryVoltage[0];
                else return this.$store.getters.getSettings.transformerPrimaryVoltage[0]
            },
            transformerSecondaryVoltageOptions() {
                if (this.item.deviceType == 100) return this.$store.getters.getSettings.substationSecondaryVoltage[0];
                else return this.$store.getters.getSettings.transformerSecondaryVoltage[0]
            },
            locationTypeOptions() {
                return this.$store.getters.getSettings.locationType[0]
            },
            name_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 102 || this.item.deviceType == 104 || this.item.deviceType == 111 || this.item.deviceType == 115 || this.item.deviceType == 113 || this.item.deviceType == 112 || this.item.deviceType == 116) result = true;
                return result;
            },
            date_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result;
            },
            serial_number_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 102 || this.item.deviceType == 104 || this.item.deviceType == 111 || this.item.deviceType == 112 || this.item.deviceType == 111 || this.item.deviceType == 113 || this.item.deviceType == 115 || this.item.deviceType == 116) result = true;
                return result;
            },
            manufacturer_brand_access() {
                let result = false;
                if (this.item.deviceType == 100) result = true;
                return result;
            },
            manufacturer_access() {
                let result = false;
                if (this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result;
            },
            address_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 111 || this.item.deviceType == 102 || this.item.deviceType == 112 || this.item.deviceType == 113 || this.item.deviceType == 115 || this.item.deviceType == 116) result = true;
                return result;
            },
            manufacturer_date_access() {
                let result = false;
                if (this.item.deviceType == 111 || this.item.deviceType == 102) result = true;
                return result;
            },
            production_date_access() {
                let result = false;
                if (this.item.deviceType == 100) result = true;
                return result;
            },
            phase_connection_access() {
                let result = false;
                if (this.item.deviceType == 112 || this.item.deviceType == 116) result = true;
                return result;
            },
            capacity_access() {
                let result = false;
                if (this.item.deviceType == 100) result = true;
                return result;
            },
            power_access() {
                let result = false;
                if (this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result;
            },
            primary_voltage_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 111 || this.item.deviceType == 102) result = true;
                return result
            },
            secondary_voltage_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 111 || this.item.deviceType == 102) result = true;
                return result
            },
            number_of_feeders_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 111 || this.item.deviceType == 102) result = true;
                return result
            },
            GPRS_signal_access() {
                let result = false;
                if ((this.item.deviceType == 100 || this.item.deviceType == 111 || this.item.deviceType == 102 || this.item.deviceType == 104 || this.item.deviceType == 112 || this.item.deviceType == 113 || this.item.deviceType == 115) && !this.data_for_create) result = true;
                return result
            },
            height_access() {
                let result = false;
                if (this.item.deviceType == 104) result = true;
                return result
            },
            type_id_access() {
                let result = false;
                if (this.item.deviceType == 104) result = true;
                return result
            },
            location_type_id_access() {
                let result = false;
                // if ((this.item.deviceType == 102 || this.item.deviceType == 111) && !this.data_for_create) result = true;
                return result
            },
            number_of_meters_access() {
                let result = false;
                if ((this.item.deviceType == 113 || this.item.deviceType == 115)) result = true;
                return result
            },
            ownership_access() {
                let result = false;
                if (this.item.deviceType == 100 || this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result
            },
            first_name_access() {
                let result = false;
                if (this.item.deviceType == 112) result = true;
                return result
            },
            last_name_access() {
                let result = false;
                if (this.item.deviceType == 112) result = true;
                return result
            },
            contact_person_access() {
                let result = false;
                if (this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result
            },
            contact_person_phone_number_access() {
                let result = false;
                if (this.item.deviceType == 102 || this.item.deviceType == 111) result = true;
                return result
            },
            person_in_charge_access() {
                let result = false;
                if (this.item.deviceType == 100) result = true;
                return result
            },
            operator_phone_number_access() {
                let result = false;
                if (this.item.deviceType == 100) result = true;
                return result
            },

            mobile_access() {
                let result = false;
                if (this.item.deviceType == 112) result = true;
                return result
            },
            email_access() {
                let result = false;
                if (this.item.deviceType == 112) result = true;
                return result
            }
        },

        watch: {
            editable() {
                this.edit = this.editable;
            },

            invalid() {
                this.$emit('set_invalid', this.invalid);
            }
        },

        mounted() {
            this.init();
            if (this.childs) this.initChilds(this.childs);
            this.edit = this.editable;
        },

        methods: {
            init() {
                this.name = null;
                this.date = null;
                this.serial_number = null;
                this.manufacturer = null;
                this.brand = null;
                this.address = null;
                this.manufacturer_date = null;
                this.production_date = null;
                this.phase_connection = null;
                this.capacity = null;
                this.power = null;
                this.primary_voltage = null;
                this.secondary_voltage = null;
                this.number_of_feeders = null;
                this.GPRS_signal = null;
                this.height = null;
                this.type_id = null;
                this.location_type_id = null;
                this.number_of_meters = null;
                this.maintenance_period = null;
                this.last_maintenance_check_date = null;
                this.warranty_time = null;
                this.warranty_expires = null;
                this.asset_value = null;
                this.asset_maintenance_value = null;
                this.ownership = null;
                this.first_name = null;
                this.last_name = null;
                this.contact_person = null;
                this.contact_person_phone_number = null;
                this.person_in_charge = null;
                this.operator_phone_number = null;
                this.mobile = null;
                this.notes = null;
                this.email = null;
                if (this.item) {
                    this.loading = true;
                    this.name = this.item.Name || this.item.name;
                    this.date = this.mainShowDate(this.item.InstallationDate) || this.mainShowDate(this.item.installationDate);
                    this.serial_number = this.item.SerialNumber || this.item.serialNumber;
                    this.manufacturer = this.item.Manufacturer || this.item.manufacturer;
                    this.brand = this.item.Brand || this.item.brand;
                    this.address = this.item.Address || this.item.address;
                    this.manufacturer_date = this.mainShowDate(this.item.ManufactureDate) || this.mainShowDate(this.item.manufactureDate);
                    this.production_date = this.mainShowDate(this.item.ProductionYear) || this.mainShowDate(this.item.productionYear);
                    this.phase_connection = this.item.PhaseConnectionID || this.item.phaseConnectionID;
                    this.capacity = this.item.Capacity || this.item.capacity;
                    this.power = this.item.Power || this.item.power;
                    this.primary_voltage = this.item.PrimaryVoltageID || this.item.primaryVoltageID;
                    this.secondary_voltage = this.item.SecondaryVoltageID || this.item.secondaryVoltageID;
                    this.number_of_feeders = this.item.NumOfFeeders || this.item.numOfFeeders;
                    this.GPRS_signal = this.item.GPRSSignal;
                    this.height = this.item.Height || this.item.height;
                    this.type_id = this.item.TypeID || this.item.typeID;
                    this.location_type_id = this.item.LocationTypeID || this.item.locationTypeID;
                    this.number_of_meters = this.item.NumberOfMeters || this.item.numberOfMeters;
                    this.maintenance_period = this.item.MaintenancePeriod || this.item.maintenancePeriod;
                    this.last_maintenance_check_date = this.mainShowDate(this.item.LastMaintenanceCheckDate) || this.mainShowDate(this.item.lastMaintenanceCheckDate);
                    this.warranty_time = this.item.WarrantyTime || this.item.warrantyTime;
                    this.warranty_expires = this.mainShowDate(this.item.WarrantyExpires) || this.mainShowDate(this.item.warrantyExpires);
                    this.asset_value = this.item.AssetValue || this.item.assetValue;
                    this.asset_maintenance_value = this.item.AssetMaintenanceValue || this.item.assetMaintenanceValue;
                    this.ownership = this.item.Ownership || this.item.ownership;
                    this.first_name = this.item.FirstName || this.item.firstName;
                    this.last_name = this.item.LastName || this.item.lastName;
                    this.contact_person = this.item.ContactPerson || this.item.contactPerson;
                    this.contact_person_phone_number = this.item.ContactPersonPhone || this.item.contactPersonPhone;
                    this.person_in_charge = this.item.PersonInCharge || this.item.personInCharge;
                    this.operator_phone_number = this.item.OperatorPhoneNumber || this.item.operatorPhoneNumber;
                    this.mobile = this.item.Mobile || this.item.mobile;
                    this.notes = this.item.Notes || this.item.notes;
                    this.email = this.item.Email || this.item.email;

                    this.loading = false;
                    this.activeDeviceChild = null;
                }
            },

            initChilds(childs) {
                this.activeDeviceChild = null;
                this.childs_details = [];
                childs.forEach(child => {
                    let child_data = {};

                    if (child.info && child.info.Address) child_data.Address = child.info.Address;
                    if (child.info && child.info.AssetMaintenanceValue) child_data.AssetMaintenanceValue = child.info.AssetMaintenanceValue;
                    if (child.info && child.info.AssetValue) child_data.AssetValue = child.info.AssetValue;
                    if (child.info && child.info.Description) child_data.Description = child.info.Description;
                    if (child.info && child.info.Email) child_data.Email = child.info.Email;
                    if (child.info && child.info.FirstName) child_data.FirstName = child.info.FirstName;
                    if (child.info && child.info.GPRSSignal) child_data.GPRSSignal = child.info.GPRSSignal;
                    if (child.info && child.info.ID) child_data.ID = child.info.ID;
                    if (child.info && child.info.InstallationDate) child_data.InstallationDate = child.info.InstallationDate;
                    if (child.info && child.info.LastMaintenanceCheckDate) child_data.LastMaintenanceCheckDate = child.info.LastMaintenanceCheckDate;
                    if (child.info && child.info.LastName) child_data.LastName = child.info.LastName;
                    if (child.info && child.info.Latitude) child_data.Latitude = child.info.Latitude;
                    if (child.info && child.info.Longitude) child_data.Longitude = child.info.Longitude;
                    if (child.info && child.info.MaintenancePeriod) child_data.MaintenancePeriod = child.info.MaintenancePeriod;
                    if (child.info && child.info.Mobile) child_data.Mobile = child.info.Mobile;
                    if (child.info && child.info.Name) child_data.Name = child.info.Name;
                    if (child.info && child.info.Notes) child_data.Notes = child.info.Notes;
                    if (child.info && child.info.PhaseConnectionID) child_data.PhaseConnectionID = child.info.PhaseConnectionID;
                    if (child.info && child.info.SerialNumber) child_data.SerialNumber = child.info.SerialNumber;
                    if (child.info && child.info.WarrantyExpires) child_data.WarrantyExpires = child.info.WarrantyExpires;
                    if (child.info && child.info.deviceID) child_data.childID = child.info.deviceID;
                    if (child.info && child.info.deviceType) child_data.childType = child.info.deviceType;
                    if (child.info && child.info.picturesArray) child_data.picturesArray = child.info.picturesArray;

                    this.childs_details = [...this.childs_details, child_data]
                })
            },

            getData() {
                let formData = {
                    name: this.name,
                    latitude: this.item.Latitude || this.item.latitude,
                    longitude: this.item.Longitude || this.item.longitude,
                };

                if (this.item.ID) formData.id = this.item.ID;

                if (this.serial_number) formData.serialNumber = this.serial_number;
                else formData.serialNumber = null;

                if (this.address) formData.address = this.address;
                else formData.address = null;

                if (this.date) formData.installationDate = this.mainSaveFormatData(this.date);
                else formData.installationDate = null;

                if (this.primary_voltage) formData.primaryVoltageID = Number(this.primary_voltage);
                else formData.primaryVoltageID = 0;

                if (this.secondary_voltage) formData.secondaryVoltageID = Number(this.secondary_voltage);
                else formData.secondaryVoltageID = 0;

                if (this.manufacturer) formData.manufacturer = this.manufacturer;
                else formData.manufacturer = null;

                if (this.brand) formData.brand = this.brand;
                else formData.brand = null;

                if (this.manufacturer_date) formData.manufactureDate = this.mainSaveFormatData(this.manufacturer_date);
                else formData.manufactureDate = null;

                if (this.production_date) formData.productionYear = this.mainSaveFormatData(this.production_date);
                else formData.productionYear = null;

                if (this.phase_connection) formData.phaseConnectionID = this.phase_connection;
                else formData.phaseConnectionID = null;

                if (this.capacity) formData.capacity = Number(this.capacity);
                else formData.capacity = 0;

                if (this.power) formData.power = Number(this.power);
                else formData.power = 0;

                if (this.GPRS_signal) formData.GPRSSignal = Number(this.GPRS_signal);
                else formData.GPRSSignal = 0;

                if (this.height) formData.height = Number(this.height);
                else formData.height = 0;

                if (this.type_id) formData.typeID = Number(this.type_id);
                else formData.typeID = 0;

                if (this.location_type_id) formData.locationTypeID = Number(this.location_type_id);
                else formData.locationTypeID = null;

                if (this.number_of_meters) formData.numberOfMeters = Number(this.number_of_meters);
                else formData.numberOfMeters = 0;

                if (this.maintenance_period) formData.maintenancePeriod = Number(this.maintenance_period);
                else formData.maintenancePeriod = 0;

                if (this.last_maintenance_check_date) formData.lastMaintenanceCheckDate = this.mainSaveFormatData(this.last_maintenance_check_date);
                else formData.lastMaintenanceCheckDate = null;

                if (this.warranty_time) formData.warrantyTime = Number(this.warranty_time);
                else formData.warrantyTime = 0;

                if (this.warranty_expires) formData.warrantyExpires = this.mainSaveFormatData(this.warranty_expires);
                else formData.warrantyExpires = null;

                if (this.asset_value) formData.assetValue = Number(this.asset_value);
                else formData.assetValue = 0;

                if (this.asset_maintenance_value) formData.assetMaintenanceValue = Number(this.asset_maintenance_value);
                else formData.assetMaintenanceValue = 0;

                if (this.ownership) formData.ownership = this.ownership;
                else formData.ownership = null;

                if (this.first_name) formData.firstName = this.first_name;
                else formData.firstName = null;

                if (this.last_name) formData.lastName = this.last_name;
                else formData.lastName = null;

                if (this.contact_person) formData.contactPerson = this.contact_person;
                else formData.contactPerson = null;

                if (this.contact_person_phone_number) formData.contactPersonTel = this.contact_person_phone_number;
                else formData.contactPersonTel = null;

                if (this.mobile) formData.mobile = this.mobile;
                else formData.mobile = null;

                if (this.email) formData.email = this.email;
                else formData.email = null;

                if (this.notes) formData.notes = this.notes;
                else formData.notes = null;

                if (this.number_of_feeders) formData.numOfFeeders = Number(this.number_of_feeders);
                else formData.numOfFeeders = null;

                if (this.contact_person_phone_number) formData.contactPersonPhone = this.contact_person_phone_number;
                else formData.contactPersonPhone = null;

                if (this.person_in_charge) formData.personInCharge = this.person_in_charge;
                else formData.personInCharge = null;

                if (this.operator_phone_number) formData.operatorPhoneNumber = this.operator_phone_number;
                else formData.operatorPhoneNumber = null;

                return formData;
            },

            getChildData(child) {
                let child_data = {
                    name: child.Name,
                    latitude: child.Latitude,
                    longitude: child.Longitude,
                };

                if (child.PhaseConnectionID) child_data.phaseConnectionID = child.PhaseConnectionID;
                else child_data.phaseConnectionID = null;

                if (child.SerialNumber) child_data.serialNumber = child.SerialNumber;
                else child_data.serialNumber = null;

                if (child.Address) child_data.address = child.Address;
                else child_data.address = null;

                if (child.GPRSSignal) child_data.GPRSSignal = child.GPRSSignal;
                else child_data.GPRSSignal = null;

                if (child.FirstName) child_data.firstName = child.FirstName;
                else child_data.firstName = null;

                if (child.LastName) child_data.lastName = child.LastName;
                else child_data.lastName = null;

                if (child.Email) child_data.email = child.Email;
                else child_data.email = null;

                if (child.Mobile) child_data.mobile = child.Mobile;
                else child_data.mobile = null;

                if (child.Notes) child_data.notes = child.Notes;
                else child_data.notes = null;

                if (child.childType) child_data.deviceType = child.childType;

                return child_data;
            },

            saveData() {
                this.loading = true;

                let formData = this.getData();

                /* save childs */
                if (this.item.deviceType == 115 || this.item.deviceType == 113) {
                    this.childs_details.forEach(child => {
                        let child_data = this.getChildData(child);

                        /*edit*/
                        if (child.childID) {
                            child_data.id = child.childID;
                            let child_url;
                            if (child.deviceType == 112 || child.childType == 112) child_url = 'updateServicePoint';
                            if (child.deviceType == 116 || child.childType == 116) child_url = 'updateGetway';

                            this.axios.put(child_url, child_data)
                        }

                        /*create*/
                        if (!child.childID) {
                            child_data.parentsArray = [];
                            child_data.latitude = this.item.Latitude;
                            child_data.longitude = this.item.Longitude;
                            let parentsArray = {
                                parentType: this.item.deviceType,
                                parentID: this.item.deviceID,
                                feederID: this.item.parentsArray[0].FeederID,
                                feederLocationTypeID: this.item.parentsArray[0].FeederLocationTypeID,
                                feederType: this.item.parentsArray[0].FeederType,
                                mainFeeder: this.item.parentsArray[0].MainFeeder
                            };
                            child_data.parentsArray.push(parentsArray);
                            let child_url;
                            console.log(child)
                            // if (child.deviceType == 112) child_url = 'addServicePoint';
                            // if (child.deviceType == 116) child_url = 'addGetway';

                            if (child.childType == 112) child_url = 'addServicePoint';
                            if (child.childType == 116) child_url = 'addGetway';

                            this.axios.post(child_url, child_data)
                        }
                    })
                }

                let url;
                if (this.item.deviceType == 100) url = 'updateSubstation';
                if (this.item.deviceType == 102) url = 'updateTransformer';
                if (this.item.deviceType == 104) url = 'updatePole';
                if (this.item.deviceType == 111) url = 'updateCabin';
                if (this.item.deviceType == 115) url = 'updatePillar';
                if (this.item.deviceType == 113) url = 'updateBox';
                if (this.item.deviceType == 112) url = 'updateServicePoint';

                formData.deviceType = this.item.deviceType;
                formData.deviceId = this.item.deviceID;

                this.axios.put(url, formData)
                    .then(response => {
                        if (response && response.data.status == 200) {
                            this.edit = false;
                            this.$emit('change_edit', this.edit);
                            this.mainNotification(this.$t('notification.1'));
                            this.loading = false;
                            this.activeDeviceChild = null;
                            let item = this.item;
                            item.deviceId = this.item.deviceID;
                            this.$emit('update', formData, true);
                        } else {
                            this.mainNotification(response.data.message);
                            this.loading = false;
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            this.mainNotification(error.response.data.message);
                            this.loading = false;
                        }
                    })
            },

            selectDeviceChild(item) {
                if (item == this.activeDeviceChild) {
                    this.activeDeviceChild = null;
                    return
                }
                this.activeDeviceChild = item;
            },

            removeDetail(item, title, index) {
                this.$swal.fire({
                    icon: false,
                    title: this.$t('map.51'),
                    html: `<div class="mb-4">${this.$t('map.52')}</div>
                    <div class="d-flex align-center">
                    <img src="/svg/assets/${item.childType}_single.svg" class="me-2" alt="">
                    ${title}
                    </div>`,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: this.$t('buttons.9'),
                    confirmButtonColor: '#BA1B1B',
                    reverseButtons: true,
                    closeButtonHtml: '<img src="/svg/close.svg">',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        let url;
                        if (item.childType == 112) url = 'removeServicePoint';
                        if (item.childType == 116) url = 'removeGetway';
                        if (item.childID) {
                            this.axios.post(url, {id: item.childID})
                                .then(response => {
                                    if (response && response.data.status == 200) {
                                        this.mainNotification(this.$t('notification.2'));
                                        this.childs_details.splice(index, 1);
                                        this.$emit('remove_device', item.childID);
                                    }
                                })
                                .catch((error) => {
                                    if (error) this.mainNotification(error.message);
                                })
                        } else this.childs_details.splice(index, 1)
                    }
                })
            },

            addNewDevice(item) {
                let child_data = {};
                child_data.Address = null;
                child_data.AssetMaintenanceValue = null;
                child_data.AssetValue = null;
                child_data.Description = null;
                child_data.Email = null;
                child_data.FirstName = null;
                child_data.GPRSSignal = null;
                child_data.ID = null;
                child_data.InstallationDate = null;
                child_data.LastMaintenanceCheckDate = null;
                child_data.LastName = null;
                child_data.Latitude = null;
                child_data.Longitude = null;
                child_data.MaintenancePeriod = null;
                child_data.Mobile = null;
                child_data.Name = null;
                child_data.Notes = null;
                child_data.PhaseConnectionID = null;
                child_data.SerialNumber = null;
                child_data.WarrantyExpires = null;
                child_data.childID = null;
                child_data.childType = item.type;
                child_data.picturesArray = null;
                this.childs_details.push(child_data);
                this.activeDeviceChild = this.childs_details.length - 1
            },

            getFilledData() {
                let formData = this.getData();
                formData.deviceType = this.item.deviceType;
                if ((this.item.deviceType == 115 || this.item.deviceType == 113) && this.childs_details && this.childs_details.length) {
                    formData.child = [];
                    this.childs_details.forEach(child => {
                        let child_data = this.getChildData(child);
                        formData.child.push(child_data)
                    })
                }
                this.$emit('set_filled_data', formData)
            },

            cancelData() {
                this.init();
                if (this.childs) this.initChilds(this.childs);
                this.edit = false;
                this.$emit('change_edit', this.edit)
            },
        }
    }
</script>