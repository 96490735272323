<template>
    <div v-if="advSearchDialog" class="dialog large">
        <div class="dialog-card">
            <div class="text-end">
                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-header">
                <span>{{$t('map.63')}}</span>

                <div>
                    <i-input v-model="filterData.AssetName" type="text" icon="search"
                             id="substationWarrantyExpires"
                             :placeholder="$t('nav.4')"></i-input>
                </div>

            </div>
            <div class="dialog-body">
                <div class="item">
                    <div class="item-title">{{$t('devices.1')}}</div>
                    <div class="item-body group-items">

                        <i-select v-model="filterData.SubstationNumOfFeeders"
                                  :placeholder="$t('map.28')" multiple :options="dropdown.SubstationNumOfFeeders" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.SubstationPrimaryVoltageID"
                                  :placeholder="$t('map.26')" multiple :options="dropdown.SubstationPrimaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.SubstationSecondaryVoltageID"
                                  :placeholder="$t('map.27')" multiple :options="dropdown.SubstationSecondaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.SubstationCapacity"
                                  :placeholder="$t('map.19')" multiple :options="dropdown.SubstationCapacity" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.SubstationManufacturer"
                                  :placeholder="$t('map.12')" multiple :options="dropdown.SubstationBrand" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.SubstationAssetValue"
                                  :placeholder="$t('map.105')" multiple :options="dropdown.SubstationAssetValue" title="ParametrText"
                                  option_key="ParameterValue"></i-select>



<!--                        <i-input v-model="filterData.SubstationBrand"-->
<!--                                 id="substationBrand"-->
<!--                                 :placeholder="$t('map.101')"></i-input>-->
<!--                        <i-input v-model="filterData.SubstationOwnership"-->
<!--                                 id="substationOwnership"-->
<!--                                 :placeholder="$t('map.103')"></i-input>-->
<!--                        <i-input v-model="filterData.SubstationWarrantyExpires" type="date"-->
<!--                                 id="substationWarrantyExpires"-->
<!--                                 :placeholder="$t('map.41')"></i-input>-->
<!--                        <i-input v-model="filterData.SubstationProductionYear" type="date"-->
<!--                                 id="substationProductionYear"-->
<!--                                 :placeholder="$t('map.102')"></i-input>-->
<!--                        <i-input v-model="filterData.SubstationMaintenancePeriod" type="date"-->
<!--                                 id="substationMaintenancePeriod"-->
<!--                                 :placeholder="$t('map.104')"></i-input>-->


                    </div>
                </div>

                <div class="item">
                    <div class="item-title">{{$t('devices.2')}}</div>
                    <div class="item-body group-items">
                        <i-select v-model="filterData.TransformerPrimaryVoltageID"
                                  :placeholder="$t('map.26')" multiple :options="dropdown.TransformerPrimaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.TransformerSecondaryVoltageID"
                                  :placeholder="$t('map.27')" multiple :options="dropdown.TransformerSecondaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.TransformerPower"
                                  :placeholder="$t('map.20')" multiple :options="dropdown.TransformerPower" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.TransformerManufacturer"
                                  :placeholder="$t('map.12')" multiple :options="dropdown.TransformerManufacturer" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-input v-model="filterData.TransformerWarrantyExpires" type="date"
                                 id="substationWarrantyExpires"
                                 :placeholder="$t('map.41')"></i-input>
                    </div>
                </div>

                <div class="item">
                    <div class="item-title">{{$t('devices.4')}}</div>
                    <div class="item-body group-items">
                        <i-select v-model="filterData.CabinPrimaryVoltageID"
                                  :placeholder="$t('map.26')" multiple :options="dropdown.CabinPrimaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.CabinSecondaryVoltageID"
                                  :placeholder="$t('map.27')" multiple :options="dropdown.CabinSecondaryVoltageID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.CabinPower"
                                  :placeholder="$t('map.20')" multiple :options="dropdown.CabinPower" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-select v-model="filterData.CabinManufacturer"
                                  :placeholder="$t('map.12')" multiple :options="dropdown.CabinManufacturer" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                        <i-input v-model="filterData.CabinWarrantyExpires" type="date"
                                 id="substationWarrantyExpires"
                                 :placeholder="$t('map.41')"></i-input>
                    </div>
                </div>

                <div class="item">
                    <div class="item-title">{{$t('devices.5')}}</div>
                    <div class="item-body group-items">
                        <i-select v-model="filterData.PillarNumberOfMeters"
                                  :placeholder="$t('map.55')" multiple :options="dropdown.PillarNumberOfMeters" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                    </div>
                </div>

                <div class="item">
                    <div class="item-title">{{$t('devices.6')}}</div>
                    <div class="item-body group-items">
                        <i-select v-model="filterData.BoxNumberOfMeters"
                                  :placeholder="$t('map.55')" multiple :options="dropdown.BoxNumberOfMeters" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                    </div>
                </div>

                <div class="item">
                    <div class="item-title">{{$t('devices.7')}}</div>
                    <div class="item-body group-items">
                        <i-select v-model="filterData.ServicePointConnectionType"
                                  :placeholder="$t('map.57')" multiple :options="connectionTypeOptions" title="Name"
                                  option_key="Code"></i-select>
                        <i-select v-model="filterData.ServicePointPhaseConnectionID"
                                  :placeholder="$t('map.56')" multiple :options="dropdown.ServicePointPhaseConnectionID" title="ParametrText"
                                  option_key="ParameterValue"></i-select>
                    </div>
                </div>
            </div>
            <div class="dialog-footer">
                <i-button extrasmall color="secondary" :title="$t('buttons.12')" class="me-2"
                          @click="resetSearch()"></i-button>
                <i-button extrasmall :title="$t('buttons.11')" :disabled="loading" :loading="loading" @click="showResult()"></i-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                advSearchDialog: false,
                loading: false,

                numbers_of_feeder: null,
                typeOptions: [
                    {Name: 'Название 1', Code: 1},
                    {Name: 'Название 2', Code: 2},
                    {Name: 3, Code: 3}
                ],

                filterData: {
                    AssetName: null,
                    /*Substation*/
                    SubstationNumOfFeeders: [],
                    SubstationPrimaryVoltageID: [],
                    SubstationSecondaryVoltageID: [],
                    SubstationCapacity: [],
                    SubstationManufacturer: [],
                    SubstationWarrantyExpires: null,
                    SubstationBrand: null,
                    SubstationProductionYear: null,
                    SubstationOwnership: null,
                    SubstationMaintenancePeriod: null,
                    SubstationAssetValue: [],
                    /*Transformer*/
                    TransformerPrimaryVoltageID: [],
                    TransformerSecondaryVoltageID: [],
                    TransformerPower: [],
                    TransformerManufacturer: [],
                    TransformerWarrantyExpires: null,
                    /*Cabin*/
                    CabinPrimaryVoltageID: [],
                    CabinSecondaryVoltageID: [],
                    CabinPower: [],
                    CabinManufacturer: [],
                    CabinWarrantyExpires: null,
                    /*Pillar*/
                    PillarNumberOfMeters: [],
                    /*Box*/
                    BoxNumberOfMeters: [],
                    /*SP*/
                    ServicePointPhaseConnectionID: [],
                    ServicePointConnectionType: [],
                },

                numberOfFeedersOptions: [
                    {Name: 4, Code: 4},
                    {Name: 5, Code: 5},
                    {Name: 6, Code: 6},
                    {Name: 10, Code: 10}
                ],
                primaryVoltageOptions: [
                    {Name: 12, Code: 12},
                    {Name: 33, Code: 33},
                ],
                secondaryVoltageOptions: [
                    {Name: 120, Code: 120},
                    {Name: 480, Code: 480},
                ],
                capacityOptions: [
                    {Name: 15, Code: 15},
                    {Name: 30, Code: 30},
                    {Name: 45, Code: 45},
                    {Name: 75, Code: 75},
                    {Name: 112.5, Code: 112.5},
                ],
                powerOptions: [
                    {Name: 33000, Code: 33000},
                    {Name: 400000, Code: 400000},
                ],
                // manufacturerOptions: [
                //     {Name: 'Powercom', Code: 'Powercom'},
                //     {Name: 'Longi', Code: 'Longi'},
                // ],
                numberOfMetersOptions: [
                    {Name: 2, Code: 2},
                    {Name: 4, Code: 4},
                    {Name: 5, Code: 5},
                ],
                connectionTypeOptions: [
                    {Name: 'PLC', Code: 'PLC'},
                    {Name: 'RF', Code: 'RF'},
                    {Name: 'GPRS', Code: 'GPRS'},
                ],
                assetValue: [],
                dropdownData: null,
            }
        },

        computed: {
            phaseTypeOptions() {
                return this.$store.getters.getSettings.spPhaseConnection[0]
            },
            dropdown() {
                let dropdown_data = {
                    req: false,
                    BoxNumberOfMeters: [],
                    CabinAssetMaintenanceValue: [],
                    CabinAssetValue: [],
                    CabinLocationTypeID: [],
                    CabinMaintenancePeriod: [],
                    CabinManufacturer: [],
                    CabinNumOfFeeders: [],
                    CabinOwnership: [],
                    CabinPower: [],
                    CabinPrimaryVoltageID: [],
                    CabinSecondaryVoltageID: [],
                    CabinWarrantyTime: [],
                    PillarNumberOfMeters: [],
                    PoleHeight: [],
                    PoleTypeID: [],
                    ServicePointPhaseConnectionID: [],
                    SubstationAssetMaintenanceValue: [],
                    SubstationAssetValue: [],
                    SubstationBrand: [],
                    SubstationCapacity: [],
                    SubstationMaintenancePeriod: [],
                    SubstationNumOfFeeders: [],
                    SubstationOwnership: [],
                    SubstationPrimaryVoltageID: [],
                    SubstationSecondaryVoltageID: [],
                    SubstationWarrantyTime: [],
                    TransformerAssetMaintenanceValue: [],
                    TransformerAssetValue: [],
                    TransformerLocationTypeID: [],
                    TransformerMaintenancePeriod: [],
                    TransformerManufacturer: [],
                    TransformerNumOfFeeders: [],
                    TransformerOwnership: [],
                    TransformerPower: [],
                    TransformerPrimaryVoltageID: [],
                    TransformerSecondaryVoltageID: [],
                    TransformerWarrantyTime: [],
                };
                let data = this.$store.getters.getDropdown;
                if(data) {
                    dropdown_data.req = true;
                    dropdown_data.BoxNumberOfMeters = data.filter(item => item.ParameterName == 'BoxNumberOfMeters');
                    dropdown_data.CabinAssetMaintenanceValue = data.filter(item => item.ParameterName == 'CabinAssetMaintenanceValue');
                    dropdown_data.CabinAssetValue = data.filter(item => item.ParameterName == 'CabinAssetValue');
                    dropdown_data.CabinLocationTypeID = data.filter(item => item.ParameterName == 'CabinLocationTypeID');
                    dropdown_data.CabinMaintenancePeriod = data.filter(item => item.ParameterName == 'CabinMaintenancePeriod');
                    dropdown_data.CabinManufacturer = data.filter(item => item.ParameterName == 'CabinManufacturer');
                    dropdown_data.CabinNumOfFeeders = data.filter(item => item.ParameterName == 'CabinNumOfFeeders');
                    dropdown_data.CabinOwnership = data.filter(item => item.ParameterName == 'CabinOwnership');
                    dropdown_data.CabinPower = data.filter(item => item.ParameterName == 'CabinPower');
                    dropdown_data.CabinPrimaryVoltageID = data.filter(item => item.ParameterName == 'CabinPrimaryVoltageID');
                    dropdown_data.CabinSecondaryVoltageID = data.filter(item => item.ParameterName == 'CabinSecondaryVoltageID');
                    dropdown_data.CabinWarrantyTime = data.filter(item => item.ParameterName == 'CabinWarrantyTime');
                    dropdown_data.PillarNumberOfMeters = data.filter(item => item.ParameterName == 'PillarNumberOfMeters');
                    dropdown_data.PoleHeight = data.filter(item => item.ParameterName == 'PoleHeight');
                    dropdown_data.PoleTypeID = data.filter(item => item.ParameterName == 'PoleTypeID');
                    dropdown_data.ServicePointPhaseConnectionID = data.filter(item => item.ParameterName == 'ServicePointPhaseConnectionID');
                    dropdown_data.SubstationAssetMaintenanceValue = data.filter(item => item.ParameterName == 'SubstationAssetMaintenanceValue');
                    dropdown_data.SubstationAssetValue = data.filter(item => item.ParameterName == 'SubstationAssetValue');
                    dropdown_data.SubstationBrand = data.filter(item => item.ParameterName == 'SubstationBrand');
                    dropdown_data.SubstationCapacity = data.filter(item => item.ParameterName == 'SubstationCapacity');
                    dropdown_data.SubstationMaintenancePeriod = data.filter(item => item.ParameterName == 'SubstationMaintenancePeriod');
                    dropdown_data.SubstationNumOfFeeders = data.filter(item => item.ParameterName == 'SubstationNumOfFeeders');
                    dropdown_data.SubstationOwnership = data.filter(item => item.ParameterName == 'SubstationOwnership');
                    dropdown_data.SubstationPrimaryVoltageID = data.filter(item => item.ParameterName == 'SubstationPrimaryVoltageID');
                    dropdown_data.SubstationSecondaryVoltageID = data.filter(item => item.ParameterName == 'SubstationSecondaryVoltageID');
                    dropdown_data.SubstationWarrantyTime = data.filter(item => item.ParameterName == 'SubstationWarrantyTime');
                    dropdown_data.TransformerAssetMaintenanceValue = data.filter(item => item.ParameterName == 'TransformerAssetMaintenanceValue');
                    dropdown_data.TransformerAssetValue = data.filter(item => item.ParameterName == 'TransformerAssetValue');
                    dropdown_data.TransformerLocationTypeID = data.filter(item => item.ParameterName == 'TransformerLocationTypeID');
                    dropdown_data.TransformerMaintenancePeriod = data.filter(item => item.ParameterName == 'TransformerMaintenancePeriod');
                    dropdown_data.TransformerManufacturer = data.filter(item => item.ParameterName == 'TransformerManufacturer');
                    dropdown_data.TransformerNumOfFeeders = data.filter(item => item.ParameterName == 'TransformerNumOfFeeders');
                    dropdown_data.TransformerOwnership = data.filter(item => item.ParameterName == 'TransformerOwnership');
                    dropdown_data.TransformerPower = data.filter(item => item.ParameterName == 'TransformerPower');
                    dropdown_data.TransformerPrimaryVoltageID = data.filter(item => item.ParameterName == 'TransformerPrimaryVoltageID');
                    dropdown_data.TransformerSecondaryVoltageID = data.filter(item => item.ParameterName == 'TransformerSecondaryVoltageID');
                    dropdown_data.TransformerWarrantyTime = data.filter(item => item.ParameterName == 'TransformerWarrantyTime');
                }
                return dropdown_data;
            }
        },

        watch: {
            advSearchDialog() {
                this.resetSearch();
            }
        },

        mounted() {
            this.resetSearch();
            this.emitter.on("showSearchDialog", e => {
                if(e) {
                    if(!this.dropdown.req) {
                        this.$store.dispatch('fetchDropdown', this.axios);
                    }
                    this.showDialog();
                }
            });
        },
        methods: {
            init() {
                this.filterData = {
                    AssetName: null,

                    /*Substation*/
                    SubstationNumOfFeeders: [],
                    SubstationPrimaryVoltageID: [],
                    SubstationSecondaryVoltageID: [],
                    SubstationCapacity: [],
                    SubstationManufacturer: [],
                    SubstationWarrantyExpires: null,
                    SubstationBrand: null,
                    SubstationProductionYear: null,
                    SubstationOwnership: null,
                    SubstationMaintenancePeriod: null,
                    SubstationAssetValue: [],

                    /*Transformer*/
                    TransformerPrimaryVoltageID: [],
                    TransformerSecondaryVoltageID: [],
                    TransformerPower: [],
                    TransformerManufacturer: [],
                    TransformerWarrantyExpires: null,

                    /*Cabin*/
                    CabinPrimaryVoltageID: [],
                    CabinSecondaryVoltageID: [],
                    CabinPower: [],
                    CabinManufacturer: [],
                    CabinWarrantyExpires: null,
                    /*Pillar*/
                    PillarNumberOfMeters: [],
                    /*Box*/
                    BoxNumberOfMeters: [],
                    /*SP*/
                    ServicePointPhaseConnectionID: [],
                    ServicePointConnectionType: [], //-убрать


                    // "PoleHeight": "3.6,4",
                    // "PoleTypeID": "2",

                    // Height
                    // TypeID
                }
            },
            showResult() {
                this.loading = true;
                let url = 'getAllDevicesByParameters';
                let params = {};
                if(this.filterData.AssetName) params.AssetName = this.filterData.AssetName;
                if(this.filterData.SubstationNumOfFeeders.length) params.SubstationNumOfFeeders = Object.values(this.filterData.SubstationNumOfFeeders);
                if(this.filterData.SubstationPrimaryVoltageID.length) params.SubstationPrimaryVoltageID = Object.values(this.filterData.SubstationPrimaryVoltageID);
                if(this.filterData.SubstationSecondaryVoltageID.length) params.SubstationSecondaryVoltageID = Object.values(this.filterData.SubstationSecondaryVoltageID);
                if(this.filterData.SubstationCapacity.length) params.SubstationCapacity = Object.values(this.filterData.SubstationCapacity);
                if(this.filterData.SubstationManufacturer.length) params.SubstationManufacturer = Object.values(this.filterData.SubstationManufacturer);
                if(this.filterData.SubstationWarrantyExpires) params.SubstationWarrantyExpires = this.filterData.SubstationWarrantyExpires;
                if(this.filterData.SubstationBrand) params.SubstationBrand = this.filterData.SubstationBrand;
                if(this.filterData.SubstationProductionYear) params.SubstationProductionYear = this.filterData.SubstationProductionYear;
                if(this.filterData.SubstationOwnership) params.SubstationOwnership = this.filterData.SubstationOwnership;
                if(this.filterData.SubstationMaintenancePeriod) params.SubstationMaintenancePeriod = this.filterData.SubstationMaintenancePeriod;
                if(this.filterData.SubstationAssetValue) params.SubstationAssetValue =  Object.values(this.filterData.SubstationAssetValue);

                if(this.filterData.TransformerPrimaryVoltageID.length) params.TransformerPrimaryVoltageID = Object.values(this.filterData.TransformerPrimaryVoltageID);
                if(this.filterData.TransformerSecondaryVoltageID.length) params.TransformerSecondaryVoltageID = Object.values(this.filterData.TransformerSecondaryVoltageID);
                if(this.filterData.TransformerPower.length) params.TransformerPower = Object.values(this.filterData.TransformerPower);
                if(this.filterData.TransformerManufacturer.length) params.TransformerManufacturer = Object.values(this.filterData.TransformerManufacturer);
                if(this.filterData.TransformerWarrantyExpires) params.TransformerWarrantyExpires = this.filterData.TransformerWarrantyExpires;
                if(this.filterData.CabinPrimaryVoltageID.length) params.CabinPrimaryVoltageID = Object.values(this.filterData.CabinPrimaryVoltageID);
                if(this.filterData.CabinSecondaryVoltageID.length) params.CabinSecondaryVoltageID = Object.values(this.filterData.CabinSecondaryVoltageID);
                if(this.filterData.CabinPower.length) params.CabinPower = Object.values(this.filterData.CabinPower);
                if(this.filterData.CabinManufacturer.length) params.CabinManufacturer = Object.values(this.filterData.CabinManufacturer);
                if(this.filterData.CabinWarrantyExpires) params.CabinWarrantyExpires = this.filterData.CabinWarrantyExpires;
                if(this.filterData.PillarNumberOfMeters.length) params.PillarNumberOfMeters = Object.values(this.filterData.PillarNumberOfMeters);
                if(this.filterData.BoxNumberOfMeters.length) params.BoxNumberOfMeters = Object.values(this.filterData.BoxNumberOfMeters);
                if(this.filterData.ServicePointPhaseConnectionID.length) params.ServicePointPhaseConnectionID = Object.values(this.filterData.ServicePointPhaseConnectionID);
                if(this.filterData.ServicePointConnectionType.length) params.ServicePointConnectionType = Object.values(this.filterData.ServicePointConnectionType);

                let isEmpty = Object.keys(params).length == 0;

                if(!isEmpty) {
                    this.axios.post(url, params)
                        .then(response => {
                            if(response.data)  {
                                this.emitter.emit("searchResultsData", response.data);
                                // this.emitter.emit("closeAssets", true);
                                this.closeDialog();
                                this.loading = false;
                            }
                        })
                }
                else {
                    this.mainNotification(this.$t('errors.5'));
                    this.loading = false;
                }

            },

            resetSearch() {
                this.init();
                // this.closeDialog();
            },

            showDialog() {
                this.advSearchDialog = true
            },
            closeDialog() {
                this.advSearchDialog = false
            }
        }
    }
</script>

<style scoped lang="sass">
    .dialog
        .dialog-card
            padding: 16px 32px

        .dialog-header
            padding-bottom: 8px
            border-bottom: 2px solid var(--border-light)
            display: flex
            align-items: center
            justify-content: space-between

        .dialog-body
            max-height: calc(688px - 130px)
            text-align: left
            padding-bottom: 40px

        .item
            display: grid
            grid-template-columns: 96px calc(100% - 120px)
            justify-content: space-between
            border-bottom: 1px solid #F1F0F4
            padding: 24px 0
            margin-right: 20px

            &:last-child
                border-bottom: none

            .item-title
                font-weight: 600
                color: var(--text-color-gray-dark)
                height: 38px
                text-align: right
                display: flex
                align-items: center
                justify-content: flex-end

            .select-element
                margin-top: 8px

</style>