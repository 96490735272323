// window.axios = require('axios');
export default {
    actions: {
        fetchSettings(ctx, axios) {
            let url = '/getSettings';
            let options = {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            };
            axios.get(url, options).then(response => {
                if (response.data) {
                    ctx.commit('setSettings', response.data);
                }
            });
        }
    },

    mutations: {
        setSettings(state, data) {
            state.settings = data;
        }
    },

    state: {
        settings: null,
    },

    getters: {
        getSettings(state) {
            return state.settings
        }
    },
}
