<template>
    <div class="dialog-side left large" id="dialog-details">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div>
                    <div class="card-drag" id="drag-details">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                </div>

                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>

            <div class="dialog-body">
                <div class="asset-title mb-12">
                    <div class="dialog-title d-flex">
                        <span v-if="!edit" class="me-6">{{$t('map.5')}}</span>
                        <span v-if="edit" class="me-6">{{$t('map.6')}}</span>
                        <i-button v-if="!edit" @click="edit = true" img="/svg/edit.svg" color="secondary" small
                                  :title="$t('buttons.5')"></i-button>
                    </div>
                </div>

                <div class="dialog-body-scroll" v-if="item">
                    <details-data ref="detailsData"
                                  @change_edit="changeEdit"
                                  @remove_device="removeDevice"
                                  @update="updateData"
                                  @set_invalid="setInvalid"
                                  :item="item" :childs="childs" :editable="edit"></details-data>
                </div>

            </div>

            <div class="dialog-footer" v-if="edit">
                <div>
                    <p v-if="invalid" class="error-color">Fill in required fields</p>
                </div>
                <div>
                    <i-button @click="cancelData()" class="me-2" color="secondary" small
                              :title="$t('buttons.7')"></i-button>
                    <i-button :disabled="invalid" @click="saveData()" small :title="$t('buttons.6')"></i-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";
    import DetailsData from "./DetailsData";

    gsap.registerPlugin(Draggable);


    export default {
        props: {
            item: Object,
            childs: Object,
        },

        components: {
            DetailsData
        },

        data() {
            return {
                edit: false,
                invalid: true,
            }
        },

        mounted() {
            Draggable.create("#dialog-details", {
                trigger:"#drag-details",
                bounds:".main-page",
                edgeResistance:0.65,
                inertia:true,
                autoScroll:1,
            });
        },

        methods: {
            closeDialog() {
                this.$emit('close');
            },
            cancelData() {
                this.$refs.detailsData.cancelData();
            },
            saveData() {
                this.$refs.detailsData.saveData();
            },
            updateChilds(childs) {
                this.$refs.detailsData.initChilds(childs);
            },
            changeEdit(e) {
                this.edit = e;
            },
            removeDevice(id) {
                this.$emit('remove_device', id);
            },
            updateData(item) {
                this.$emit('update', item, true)
            },
            setInvalid(valid) {
                this.invalid = valid
            },

        }
    }
</script>