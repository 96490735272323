<template>
    <div id="auth-page">
        <div class="container">
            <div class="card--auth">

                <div class="d-flex align-center justify-between mb-12">
                    <img src="/img/logo.svg" alt="">
<!--                    <div class="text-gray-dark font-500">English (USA)</div>-->
                </div>

                <sign-in v-if="$route.name === 'login'"></sign-in>
                <recover-password v-if="$route.name === 'recover'"></recover-password>
            </div>


            <picture id="background-image">
                <sourse srcset="/img/auth/background.webp" type="image/webp"></sourse>
                <sourse srcset="/img/auth/background.png" type="image/png"></sourse>
                <img src="/img/auth/background.png" alt="">
            </picture>
        </div>

    </div>
</template>

<script>
    import SignIn from './SignIn.vue';
    import RecoverPassword from './RecoverPassword.vue';

    export default {
        components: {
            SignIn, RecoverPassword
        },
        data() {
          return {}
        }
    }
</script>

<style scoped lang="sass">
    #auth-page
        background: #DBE1FF

        .container
            display: flex
            align-items: center
            padding: 80px 20px
            min-height: 100vh

        #background-image
            position: absolute
            right: 100px
            top: 50%
            transform: translateY(-50%)

</style>