<template>
    <div class="overflow-auto" v-if="user">
        <div class="dialog" v-if="loading">
            <div class="dialog-card">
                <div class="dialog-header" v-if="!editDevice.value">
                    Rendering data
                </div>
                <div class="dialog-body">
                    <p>Loading there</p>
                    <i-loading></i-loading>
                </div>
            </div>

        </div>
        <div class="heading">
            <div class="container">
                <div class="d-flex align-center justify-between">
                    <div>
                        <p class="middle font-600 primary-tonal mb-1">AIS</p>
                        <router-link to="/" class="d-flex align-center">
                            <svg class="flex-shrink-0 me-3" width="28" height="28" viewBox="0 0 28 28" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="28" height="28" rx="14" fill="#DBE1FF"/>
                                <path d="M15.9996 17.2292L12.7663 13.9958L15.9996 10.7625C16.3246 10.4375 16.3246 9.9125 15.9996 9.5875C15.6746 9.2625 15.1496 9.2625 14.8246 9.5875L10.9996 13.4125C10.6746 13.7375 10.6746 14.2625 10.9996 14.5875L14.8246 18.4125C15.1496 18.7375 15.6746 18.7375 15.9996 18.4125C16.3163 18.0875 16.3246 17.5542 15.9996 17.2292Z"
                                      fill="#001452"/>
                            </svg>
                            <p class="fs-36">Activities</p>
                        </router-link>
                    </div>
                    <i-button
                            @click="exportData"
                            img="/svg/file_upload.svg"
                            color="secondary light"
                            title="Export"
                            medium
                    ></i-button>
                </div>
            </div>
        </div>
        <div class="main-page activities-page">
            <div class="container">
                <div class="d-flex mb-4">
                    <i-select
                            class="me-2"
                            v-model="filter.type"
                            :options="typeOptions"
                            title="name"
                            placeholder="Asset Type"
                            option_key="code"
                            @change="addFilter()"
                    >
                    </i-select>
                    <i-select
                            class="me-2"
                            v-model="filter.responsible"
                            :options="responsibleOptions"
                            title="name"
                            placeholder="Responsible"
                            option_key="userId"
                            @change="addFilter()"
                    >
                    </i-select>
                    <i-select
                            class="me-2"
                            v-model="filter.date"
                            :options="daysOptions"
                            title="name"
                            option_key="code"
                            @change="dateSelect($event)"
                            :rangeDateSelect="rangeDateSelect"
                            deselect
                    >
                    </i-select>
                    <i-select
                            v-model="filter.status"
                            :options="statusOptions"
                            title="name"
                            placeholder="Status"
                            option_key="id"
                            @change="addFilter()"
                    >
                    </i-select>

                </div>
                <div class="activities-table">
                    <div class="activities-table__heading">
                        <p class="fs-16 font-600">Results: <span>{{this.countDevices}}</span></p>
                        <div class="d-flex align-items-center">
                            <p class="fs-16">Show:</p>
                            <i-select
                                    v-model="filter.limit"
                                    :options="showElTableData"
                                    title="name"
                                    option_key="code"></i-select>
                        </div>
                    </div>
                    <el-table
                            :data="tableDevicesData"
                            style="width: 100%"
                            class="activities-table__table"
                            empty-text="No data"
                            @sort-change="sortChange($event)"
                    >
                        <el-table-column prop="name" label="Name" sortable min-width="350">
                            <template #default="scope">
                                <p class="fs-14">{{ scope.row.name }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deviceName" label="Type" sortable min-width="200">
                            <template #default="scope">
                                <p class="fs-14">{{ this.devicesTypes[scope.row.deviceType] }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="Responsible"
                                sortable
                                label="Responsible"
                                min-width="200">
                            <template #default="scope">
                                <p class="fs-14">{{ scope.row.Responsible }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="ActionDate"
                                label="Date"
                                sortable
                                min-width="200">
                            <template #default="scope">
                                <p class="fs-14">{{ dateTable(scope.row.ActionDate) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="Status"
                                min-width="200">
                            <template #default="scope">
                                <p class="fs-14">{{ scope.row.ActionName }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                min-width="100">
                            <template #default="scope">
                                <div
                                        v-if="scope.row.ActionName !== 'Deleted' && scope.row.deviceType != 101 && scope.row.deviceType != 114"
                                        class="edit-button"
                                        @click="openDevices(scope.row, scope)">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.795 21.4549V25.2049H10.545L21.605 14.1449L17.855 10.3949L6.795 21.4549ZM25.205 10.5449L21.455 6.79492L18.925 9.33492L22.675 13.0849L25.205 10.5449Z"
                                              fill="#45464E"/>
                                    </svg>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
                <el-pagination

                        background
                        layout="prev, pager, next"
                        :total="countDevices"
                        :page-size="filter.limit"
                        @current-change="changeCurrentPage"
                        :current-page="filter.page + 1"
                />

            </div>

        </div>
        <div class="date_picker_overlay date_picker__range" v-if="date_picker">
            <div class="date_picker_card">
                <i-button @click="clearDate" icon img="/svg/close.svg"></i-button>
                <p class="fs-12 mb-2">Select range</p>
                <div class="d-flex align-center" v-if="this.rangeDate.start">
                    <p class="fs-24">{{ this.rangeStart }} - {{ this.rangeEnd }}</p>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M32.71 17.6305C33.1 18.0205 33.1 18.6505 32.71 19.0405L30.88 20.8705L27.13 17.1205L28.96 15.2905C29.35 14.9005 29.98 14.9005 30.37 15.2905L32.71 17.6305ZM15 33.0005V29.2505L26.06 18.1905L29.81 21.9405L18.75 33.0005H15Z"
                              fill="#45464E"/>
                    </svg>
                </div>
                <DatePicker
                        v-model="rangeDate"
                        is-range
                        :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"
                        :columns="2"
                        :max-date='new Date()'
                />
                <div class="button-container">
                    <i-button small @click="getData()" :title="$t('buttons.1')"></i-button>
                </div>
            </div>
        </div>

        <!-- EDIT DEVICE -->
        <div v-if="editDevice.value && editDevice.item" class="backdrop details_asset">
            <div class="details_asset_card">
                <div class="details_asset_header text-capitalize fs-28 mb-1">{{editDevice.item.Name}}</div>
                <div class="text-gray-light text-capitalize fs-14 mb-10">{{showType(editDevice.item.deviceType)}}</div>
                <div class="details_asset_body">
                    <details-data :item="editDevice.item"
                                  :childs="editDevice.child"
                                  :show_asset="false"
                                  :editable="true"
                                  ref="detailsData"
                                  @update="updateData"
                                  @set_invalid="setInvalid"></details-data>
                </div>
                <div class="details_asset_footer">
                    <div>
                        <p v-if="editDevice.invalid" class="error-color">Fill in required fields</p>
                    </div>
                    <div>
                        <i-button @click="hideEdit()" class="me-2" color="secondary" small
                                  :title="$t('buttons.7')"></i-button>
                        <i-button :disabled="editDevice.invalid" @click="saveEdit()" small
                                  :title="$t('buttons.6')"></i-button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import DetailsData from "./../map/details/DetailsData";
    import {DatePicker} from 'v-calendar';
    import moment from "moment";

    export default {
        components: {DatePicker, DetailsData},
        data() {
            return {
                loading: true,
                date_picker: false,
                showElTableData: [
                    {name: 10, code: 10},
                    {name: 15, code: 15},
                    {name: 20, code: 20},
                    {name: 30, code: 30}
                ],
                responsibleOptions: [],
                statusOptions: [
                    {name: 'Added', id: 1},
                    {name: 'Updated', id: 2},
                    {name: 'Deleted', id: 3}
                ],
                devicesData: [],
                originalDevicesData: [],
                filter: {
                    date: 6,
                    responsible: null,
                    status: null,
                    type: null,
                    limit: 15,
                    page: 0
                },
                tableDate: {
                    start: '',
                    end: moment(new Date()).format('YYYY-MM-DD 23:59:59Z')
                },
                rangeDate: {
                    start: '',
                    end: ''
                },
                daysOptions: [
                    {name: '24 Hours', code: 1},
                    {name: 'Last 7 Days', code: 6},
                    {name: 'Last 30 Days', code: 29},
                    {name: 'Custom Range', code: 0}
                ],
                rangeDateSelect: null,

                editDevice: {
                    value: false,
                    item: null,
                    child: [],
                    invalid: false,
                    index: null
                }
            }
        },

        computed: {
            rangeStart() {
                return moment(this.rangeDate.start).format('MMM D')
            },
            rangeEnd() {
                return moment(this.rangeDate.end).format('MMM D')
            },
            devices() {
                return this.$store.getters.getSettings.devicesList
            },
            devicesTypes() {
                var obj = {};
                this.$store.getters.getSettings.devicesList.forEach(el => {
                    obj[el.id] = el.name;
                })
                return obj
            },
            typeOptions() {
                let array = [{name: '', code: ''}];
                if (this.$store.getters.getSettings) {
                    array = this.$store.getters.getSettings.devicesList.map(el => {
                        return {
                            name: el.name,
                            code: el.id
                        }
                    })
                }
                return array
            },
            tableDevicesData() {

                if (this.filter.page === 0) {
                    return this.devicesData.slice(this.filter.page, this.filter.limit);
                } else {
                    return this.devicesData.slice((this.filter.page * this.filter.limit), ((this.filter.page + 1) * this.filter.limit));
                }
            },
            countDevices() {
                return this.devicesData.length
            },
            user() {
                return this.$store.getters.getUser;
            }
        },

        mounted() {
            this.init();
        },

        methods: {

            exportData() {
                const params = {};
                params.DateFrom = this.tableDate.start;
                params.DateTo = this.tableDate.end;
                if (this.filter.type) {
                    params.AssetType = this.filter.type;
                }
                if (this.filter.responsible) {
                    params.UserID = this.filter.responsible;
                }
                if (this.filter.status) {
                    params.ActionID = this.filter.status;
                }
                this.axios.post(`exportReportDetail`, params, {responseType: 'blob'})
                    .then(response => {
                        if (response) {
                            let url = URL.createObjectURL(new Blob([response.data], {
                                type: 'application/excel'
                            }));
                            let link = document.createElement('a');
                            link.style.position = 'absolute';
                            link.style.left = '-9999px';
                            link.href = url;
                            link.setAttribute('download', 'UserReportDetail.xls');
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch(error => {
                        if (error) {
                            console.log(error);
                        }
                    })
            },

            getData() {
                let rangeDate = {
                    start: moment(this.rangeDate.start).format('DD/MM/YYYY'),
                    end: moment(this.rangeDate.end).format('DD/MM/YYYY')
                }
                this.rangeDateSelect = rangeDate
                this.tableDate.start = moment(this.rangeDate.start).format('YYYY-MM-DD 00:00:00Z');
                this.tableDate.end = moment(this.rangeDate.end).format('YYYY-MM-DD 23:59:59Z');
                this.getReport();
            },

            dateSelect(e) {
                if (this.filter.date === 0) {
                    this.date_picker = true;
                } else {
                    let newDate = new Date();
                    newDate.setDate(newDate.getDate() - this.filter.date);
                    if (this.filter.date !== 1) {
                        this.tableDate.start = moment(newDate).format('YYYY-MM-DD 00:00:00Z');
                        this.tableDate.end = moment(new Date()).format('YYYY-MM-DD 23:59:59Z');
                    } else {
                        this.tableDate.start = moment(newDate).format('YYYY-MM-DD HH:mm:ssZ');
                        this.tableDate.end = moment(new Date()).format('YYYY-MM-DD HH:mm:ssZ');
                    }
                    this.getReport();
                }
                if (e.code !== 0) {
                    this.rangeDateSelect = null;
                    this.rangeDate = {
                        start: '',
                        end: ''
                    }
                }
            },
            clearDate() {
                this.date_picker = false;
                this.rangeDate.start = '';
                this.rangeDate.end = '';
            },

            dateTable(date) {
                return moment(date).format('DD.MM.YYYY');
            },
            addFilter() {
                this.devicesData = [...this.originalDevicesData];
                if (this.filter.type) {
                    this.devicesData = this.devicesData.filter(el => el.deviceType == this.filter.type);
                }
                if (this.filter.responsible) {
                    this.devicesData = this.devicesData.filter(el => el.UserID == this.filter.responsible);
                }
                if (this.filter.status) {
                    this.devicesData = this.devicesData.filter(el => el.ActionID == this.filter.status);
                }
            },
            sortChange(e) {
                if (e.order) {
                    this.devicesData.sort((a, b) => a[e.prop].localeCompare(b[e.prop], undefined, {
                        numeric: true,
                        sensitivity: "base"
                    }))
                    if (e.order == 'descending') {
                        this.devicesData.reverse();
                    }
                } else {
                    this.devicesData = [...this.originalDevicesData];
                    this.addFilter();
                }
            },

            init() {
                let newDate = new Date();
                newDate.setDate(newDate.getDate() - this.filter.date);
                this.tableDate.start = moment(newDate).format('YYYY-MM-DD 00:00:00Z');
                let params = {
                    areaID: localStorage.getItem('areaid')
                };
                this.axios.get(`getUsers`, {params: params})
                    .then(response => {
                        if (response) {
                            this.responsibleOptions = response.data.map(el => {
                                return {
                                    name: el.userName,
                                    userId: el.userID
                                }
                            });
                        }
                    })
                    .catch(error => {
                        if (error) {
                            console.log(error);
                        }
                    })
                this.getReport();

            },

            getReport() {
                this.date_picker = false;
                this.loading = true;
                let params = {
                    DateFrom: this.tableDate.start,
                    DateTo: this.tableDate.end
                };
                this.axios.get(`getReportDetail`, {params: params})
                    .then(response => {
                        if (response) {
                            this.devicesData = [...response.data];
                            this.originalDevicesData = [...response.data];
                            this.addFilter();
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        if (error) {
                            console.log(error);
                        }
                    })
            },
            changeCurrentPage(page) {
                this.filter.page = page - 1;
            },
            openDevices(scope, index) {
                this.loading = true;
                this.editDevice.item = null;
                this.editDevice.value = true;
                this.editDevice.index = index.$index;
                let url = `getInfoDevice/${scope.deviceType}/${scope.id}`;
                this.axios.get(url)
                    .then(response => {
                        if (response) {
                            if(scope.deviceType == 113 || scope.deviceType == 115) {
                                let params = {
                                    assetID: scope.id,
                                    assetType: scope.deviceType
                                };
                                /*get device child*/
                                this.axios.get('getChilds', {params: params})
                                    .then(response_child => {
                                        if (response_child) {
                                            this.editDevice.child = response_child.data;

                                            /*get device child info*/
                                            this.editDevice.child.forEach((child, i) => {
                                                let url_child_info = `getInfoDevice/${child.childType}/${child.childID}`;
                                                this.axios.get(url_child_info)
                                                    .then(response_asset => {
                                                        child.info = response_asset.data;

                                                        if (i == this.editDevice.child.length - 1) {
                                                            this.editDevice.item = response.data;
                                                            this.loading = false;
                                                        }
                                                    });

                                            })
                                        }
                                    });
                            }
                            else {
                                this.editDevice.item = response.data;
                                this.loading = false;
                            }

                        }
                    })
                    .catch((error) => {
                        if (error) {
                            this.loading = false;
                            this.mainNotification(error.response.data.message);
                        }
                    })
            },

            hideEdit() {
                this.editDevice.item = null;
                this.editDevice.value = false;
            },

            saveEdit() {
                this.$refs.detailsData.saveData();
            },
            updateData(item) {
                this.tableDevicesData[this.editDevice.index].name = item.name;
                this.hideEdit();
            },
            setInvalid(e) {
                this.editDevice.invalid = e;
            },
            showType(type) {
                let result;

                if (this.devices) {
                    this.devices.forEach(device => {
                        if (device.id == type) result = device.name
                    })
                }
                return result;
            }
        }


    }
</script>

<style scoped lang="sass">

    .align-items-center
        align-items: center

    .details_asset
        .details_asset_card
            width: 628px
            border-radius: 12px
            background: var(--background-light)
            max-height: 80vh
            padding: 32px 24px
            position: relative
            overflow: hidden

        .details_asset_body
            padding: 8px 12px
            margin: 0 -12px
            max-height: calc(80vh - 116px - 68px)
            overflow-y: scroll

        .details_asset_footer
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            z-index: 2
            height: 68px
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 24px
            box-shadow: var(--shadow-600)
            background: var(--background-light)

</style>
