// import axios from "axios";
window.axios = require('axios');
let token = localStorage.getItem('token');
let axiosInstance = window.axios.create({
  // baseURL: '/api/',
  baseURL: process.env.NODE_ENV === 'development' ? '/api/' : 'https://ais.q24x.mdmsdemo.com/api/',
  headers: {
    'Access-Control-Allow-Credentials': true,
    //'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Requested-With, content-type',
    'Authorization': 'Bearer ' + token,
    'credentials': 'same-origin',
    'cf-access-token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJmNzgzMjUwOTQyNDZjMzMxZmYzNzFlMmFkMzQ0MjM2OGFiOGY2YTI0N2VkZmE1MWFmMTJiNjRlYmQ4YTk2NGQifQ.eyJhdWQiOlsiMTU3MWE4YzlhNDRjOTllMWQ3NmRkZmIxMTEyZGE0OTQ5ZGMxYzEwNzg1OGMxYWMwMzczNzAxNWU2YTAxNGE5NiJdLCJlbWFpbCI6ImZlZG9yLnBvemRlZXZAcG93ZXJjb20uY28uaWwiLCJleHAiOjE3MTg0OTg3MjksImlhdCI6MTcxNTg3MDcyOSwibmJmIjoxNzE1ODcwNzI5LCJpc3MiOiJodHRwczovL3Bvd2VyY29tLmNsb3VkZmxhcmVhY2Nlc3MuY29tIiwidHlwZSI6ImFwcCIsImlkZW50aXR5X25vbmNlIjoiMGhPZm9GdUc1bUd4akx2YyIsInN1YiI6IjA4YzVjODBlLWU0ODUtNTdhYi1hYjAyLTNjNDg4ZTAxZjM5NCIsImNvdW50cnkiOiJVQSJ9.PifACvgd07CbsRV_Xw34gEOfhZi7ezElO-v8-SmdLvLB_j9qRYABmJbcm1JYJA9VN-AsQZAmJTawkMoVtVnvdkAl99vI08xOVyYqakeiyzF45AUe0mhbX8ujKsj3pFXCxMzOdL_8KIw9xgNoIaitypSRV6dpUOxSnizJJlVdCD_I1MY7GDd0sY5gXIFjG-JqXO3Xy_dIBGJOvyygrjPM_hiUADNt363u_RLZF1CeFFVRrdQfb_xCLDEbczmWUy8tADwAkr6-Cscnqj5jG5JP7CjnvwKi4PrUzSr0hvx3mltjm3v1mWsND1uW_bZQ1Kryx26vxM73GohFbQaMxonpqg',
  },
});


// Предварительная обработка ответов
axiosInstance.interceptors.response.use(response => response, function (error) {
  if (error.response.status == 401 && localStorage.getItem('token')) {
    localStorage.removeItem('token');
    localStorage.removeItem('areaid');
    localStorage.removeItem('city');
    localStorage.removeItem('country');
    window.location.pathname = "/login";
    return error;
  }
  else {
    return Promise.reject(error);
  }
});


export default axiosInstance;
