<template>
    <div id="info-summary">
        <div id="summary-button" @click="active = !active">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.25 13.5H9.75V12H8.25V13.5ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM9 4.5C7.3425 4.5 6 5.8425 6 7.5H7.5C7.5 6.675 8.175 6 9 6C9.825 6 10.5 6.675 10.5 7.5C10.5 9 8.25 8.8125 8.25 11.25H9.75C9.75 9.5625 12 9.375 12 7.5C12 5.8425 10.6575 4.5 9 4.5Z"
                      fill="white"/>
            </svg>
        </div>
        <div id="summary-card" v-if="active">
            <div class="summary-title">
                <div class="font-600 middle">Info Summary</div>
                <div>
                    <i-button @click="active = false" icon img="/svg/close.svg"></i-button>
                </div>
            </div>

            <div class="summary-nav">
                <p @click="type = 1" :class="{'active': type == 1}">All ({{clusters_data.length + markers_data.length}})</p>
                <p @click="type = 2" :class="{'active': type == 2}">Clusters ({{clusters_data.length}})</p>
                <p @click="type = 3" :class="{'active': type == 3}">Markers ({{markers_data.length}})</p>
            </div>

            <div class="summary-body">
                <div v-if="type == 1 || type == 2">
                    <div class="text-card" v-for="card in clusters_data" :key="card.id">
                        <div class="image-container">
                            <img :src="'/img/help/help_' + card.id + '.jpg'" alt="">
                        </div>
                        <div>
                            <p class="font-500">{{card.title}}</p>
                            <p class="small text-gray-dark">{{card.info}}</p>
                        </div>
                    </div>
                </div>
               <div v-if="type == 1 || type == 3">
                   <div class="text-card" v-for="card in markers_data" :key="card.id">
                       <div class="image-container">
                           <img :src="'/img/help/help_' + card.id + '.jpg'" alt="">
                       </div>
                       <div>
                           <p class="font-500">{{card.title}}</p>
                           <p class="small text-gray-dark">{{card.info}}</p>
                       </div>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: false,
                type: 1,
                clusters_data: [
                    {
                        id: 1,
                        title: 'Regular Cluster',
                        info: 'Indicates a group of elements that can transform into separate assets when zooming in'
                    },
                    {
                        id: 2,
                        title: 'Group of Assets',
                        info: 'Indicates a group of elements that belong to one item and cannot be separated into individual objects'
                    },
                    {
                        id: 3,
                        title: 'Group of Assets (Selected)',
                        info: 'Indicates the current selected group of assets. It is possible to select only one group at once'
                    }
                ],
                markers_data: [
                    {
                        id: 4,
                        title: 'Asset (Filtered)',
                        info: 'Indicates an asset with applied filters'
                    },
                    {
                        id: 5,
                        title: 'Asset (Selected)',
                        info: 'Indicates a selected asset with is number of input (green) and output (blue) feeders'
                    },
                    {
                        id: 6,
                        title: 'Asset (Editable)',
                        info: 'Highlighting for a newly created or edited asset'
                    },
                    {
                        id: 7,
                        title: 'Asset (Connected)',
                        info: 'Icon for the selected asset when connecting elements for a new or editable asset'
                    },
                    {
                        id: 8,
                        title: 'Feeder (Input)',
                        info: 'Indicates input feeders of a selected asset, shown as a green line'
                    },
                    {
                        id: 9,
                        title: 'Feeder (Output)',
                        info: 'Indicates output feeders of a selected asset, shown as a blue line'
                    },
                    {
                        id: 10,
                        title: 'Feeder (Hovered)',
                        info: 'Highlighting for the hovered feeder'
                    },
                    {
                        id: 11,
                        title: 'Feeder (Selected)',
                        info: 'Highlighting for the selected feeder'
                    },
                    {
                        id: 12,
                        title: 'Feeder (New)',
                        info: 'Indicatea the new feeder when selecting the cable-laying method'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="sass">
    #info-summary
        position: fixed
        z-index: 2
        right: 16px
        bottom: 16px

        #summary-button
            width: 40px
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            border-radius: 12px
            background: var(--color-primary)
            box-shadow: var(--shadow-300)
            transition: 0.4s ease-in-out

            &:hover
                cursor: pointer
                background: var(--color-primary-hover)


        #summary-card
            position: absolute
            right: 0
            bottom: 44px
            width: 321px
            height: 426px
            background: var(--background-light)
            border-radius: 2px
            box-shadow: var(--shadow-600)

            .summary-title
                display: flex
                align-items: center
                justify-content: space-between
                padding: 20px


            .summary-nav
                display: grid
                grid-template-columns: repeat(3, 33%)
                text-align: center
                width: calc(100% - 24px)
                margin: 0 auto 7px auto
                border-bottom: 2px solid var(--border-middle)

                p
                    font-size: 12px
                    padding: 10px 0
                    transition: 0.3s ease-in-out
                    color: var(--text-color-gray-middle)
                    font-weight: 500
                    position: relative
                    &::before
                        content: ''
                        width: 0
                        height: 4px
                        border-radius: 8px 8px 0 0
                        position: absolute
                        bottom: -1px
                        left: 0
                        background: var(--color-primary)
                        transition: 0.2s ease-in-out
                    &:hover
                        cursor: pointer
                        color: var(--text-color-dark)
                p.active
                    color: var(--text-color-dark)
                    &::before
                        width: 100%

            .summary-body
                padding: 0 12px
                overflow-y: scroll
                height: calc(100% - 132px)
                .text-card
                    display: grid
                    grid-template-columns: 80px auto
                    align-items: center
                    padding: 12px 0
                    border-bottom: 1px solid var(--border-middle)
                    &:last-child
                        border-bottom: 0
                        padding-bottom: 0
                    .image-container
                        text-align: center


</style>