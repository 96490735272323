<template>
    <div>
        <h1 class="mb-6">{{$t('auth.14')}}</h1>
        <p class="mb-8 text-gray-dark">{{$t('auth.15')}}</p>

        <div class="mb-6">
            <i-input v-model="password_new"
                     id="password"
                     :solo="false"
                     :label="$t('auth.16')"
                     :error="v$.password_new.$error"
                     :errorMsg="newPassError"
                     :type="'password'"
                     :icon="'password'"
                     :placeholder="$t('auth.18')">
            </i-input>
        </div>

        <div class="mb-12">
            <i-input v-model="password_confirm"
                     id="password"
                     :solo="false"
                     :label="$t('auth.17')"
                     :error="v$.password_confirm.$error"
                     :errorMsg="confirmPassError"
                     :type="'password'"
                     :icon="'password'"
                     :placeholder="$t('auth.18')">
            </i-input>
            <p v-if="serverError" class="error-msg">{{serverError}}</p>
        </div>


        <i-button @click="setPass()" block :title="$t('auth.20')" :loading="loading"></i-button>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {sameAs, required} from '@vuelidate/validators';
    import {password} from "./../../services/Validation";

    export default {
        props: {
            userId: {
                default: null,
            },
            token: {
                default: null,
            }
        },
        data() {
            return {
                loading: false,

                password_new: null,
                password_confirm: null,

                serverError: null,
                passwordType: true,

                v$: useVuelidate(),
            }
        },

        computed: {
            confirmPassError() {
                let result;
                if (this.serverError) result = this.serverError;
                else {
                    if (this.password_confirm) result = this.$t('errors.2');
                    if (!this.password_confirm) result = this.$t('errors.1');
                }
                return result;
            }
        },

        methods: {
            setPass() {
                this.serverError = null;
                this.v$.password_new.$touch();
                this.v$.password_confirm.$touch();
                if (this.v$.password_new.$invalid || this.v$.password_confirm.$invalid) {
                    return;
                } else {
                    this.loading = true;
                    let data = {
                        password: this.password_new,
                        userID: this.userId
                    };
                    let options = {
                        headers: {Authorization: "Bearer " + this.token},
                    };
                    this.axios.post('updatePassword', data, options)
                        .then(response => {
                            if ((response && response.data.status && response.data.status == 401) || (response && response.data.status && response.data.status == 400)) {
                                this.serverError = response.data.message;
                                this.loading = false;
                            }
                            else {
                                localStorage.setItem('token', this.token);
                                window.location.pathname = "/";
                            }
                        })
                        .catch(error => {
                            if (error) {
                                this.serverError = error.response.data.message;
                                this.loading = false;
                            }
                        })
                }
            }
        },

        validations() {
            return {
                password_new: password,
                password_confirm: {
                    sameAsPassword: sameAs(this.password_new),
                    required: required
                }

            }
        }
    }
</script>