<template>
    <div v-if="item" class="child">
        <div v-if="feeders" class="treeview-padding">
            <div  v-for="feeder in feeders" :key="feeder.feederID">
                <div class="treeview-item" v-if="feeder.assets && feeder.assets.length" :class="{'show': feeder.show_feeder_assets, 'last': feeder.last}" @click="feeder.show_feeder_assets = !feeder.show_feeder_assets">
                    <img src="/svg/assets/116_single.svg" alt="">
                    <div class="item-name"> {{feeder.feederName}}</div>
                </div>

                <div v-if="feeder.assets && feeder.show_feeder_assets" class="child">
                    <treeview-asset v-for="asset in feeder.assets" :key="asset.AssetID" :item="asset" :down="true" :active_asset="active_asset"></treeview-asset>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import treeviewAsset from '../treeview/TreeviewAsset'
    export default {
        props: {
            item: Object,
            load: {
                type: Boolean,
                default: false
            },
            active_asset: {
                default: 1
            }
        },

        components: {
            treeviewAsset
        },

        data() {
            return {
                feeders: null
            }
        },

        mounted() {
            this.getConnection(this.item, this.load);
        },

        methods: {
            getConnection(e, load, asset) {
                if(load) {
                    let url = `getFeederConnection/${e.deviceType || e.AssetType}/${e.deviceID || e.AssetID}`;
                    return this.axios.get(url)
                        .then(response_feeder => {
                            this.feeders = response_feeder.data;
                            this.feeders.forEach(feeder => {
                                feeder.show_feeder_assets = false;
                                feeder.feeder_assets = null;
                                feeder.feeder_loading = false;
                                feeder.last = false;

                                let url_assets = `getFeederAssets`;
                                let params = {
                                    assetID: e.deviceID || e.AssetID,
                                    assetType: e.deviceType || e.AssetType,
                                    feederID: feeder.feederID,
                                    feederType: feeder.feederType,
                                    direction: 'down'
                                };
                                this.axios.get(url_assets, {params: params})
                                    .then(response_assets => {
                                        let assets = response_assets.data;
                                        if(assets && assets.length) feeder.assets =  this.findChilds(feeder, assets);
                                        if(!assets || (assets && !assets.length)) feeder.last = true;
                                        // this.findNeighbors();
                                    })
                            });
                            this.loading = false;
                            if(asset) {
                                return response_feeder.data
                            }
                        });
                }

            },

            findChilds(feeder, assets) {
                let result = [];

                const dataObj = assets.reduce((acc,item)=>{
                    acc[item.AssetID]={
                        AssetID:item.AssetID,
                        AssetType:item.AssetType,
                        name:item.name,
                        show_feeder_assets: false,
                        loading: false,
                        last: false,
                        feederID: feeder.feederID,
                        feederType: feeder.feederType,
                        assets:[]
                    };
                    return acc
                },{});
                result = assets.reduce((acc,item)=>{
                    if(item.ParentID == this.item.deviceID || item.ParentID == this.item.AssetID) acc.push(dataObj[item.AssetID]);
                    else if(dataObj[item.ParentID] && dataObj[item.AssetID]) dataObj[item.ParentID].assets.push(dataObj[item.AssetID]);
                    return acc;
                },[]);

                return result;
            }
        }
    }
</script>