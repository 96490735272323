// window.axios = require('axios');
export default {
    actions: {
        async fetchDropdown(ctx, axios) {
            let url = '/getDropDownValues';
            axios.get(url).then(response => {
                if (response.data) {
                    ctx.commit('setDropdown', response.data);
                }
            });
        }
    },

    mutations: {
        setDropdown(state, data) {
            state.dropdown = data;
        }
    },

    state: {
        dropdown: undefined,
    },

    getters: {
        getDropdown(state) {
            return state.dropdown
        }
    },
}
