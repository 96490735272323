<template>
    <div class="dialog-side left" id="dialog-group-asset">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div class="card-drag" id="drag-group-asset">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                              fill="#909194"/>
                    </svg>
                </div>
                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div class="dialog-title mb-3"> {{$t('map.83')}}</div>
                <div class="asset-title" v-if="!loading && filtered_assets && filtered_assets.length">
                    <div v-for="item in group_assets" :key="item.key">
                        <div class="filter-button" @click="setKey(item.key)" :class="{'active': active_group == item.key}" v-if="item.count">
                            {{item.title}} {{item.count}}
                        </div>
                    </div>
                </div>

                <div class="asset-body">
                    <div v-if="loading" class="text-center">
                        <i-loading></i-loading>
                    </div>

                    <div v-if="!loading && filtered_assets && filtered_assets.length">
                        <div v-for="item in filtered_assets" class="item" :class="{'active': active_item == item.deviceID}" @click="setActiveItem(item)" :key="item.deviceID">
                            <div class="item-name">
                                <img :src="'/svg/assets/' + item.deviceType + '_single.svg'" alt="">
                                {{item.deviceName}}
                            </div>
                            <span class="fs-14 text-gray-dark item-type">{{item.deviceTypeName}}</span>
                        </div>
                    </div>

                    <div v-if="!loading && (!filtered_assets || filtered_assets && !filtered_assets.length)">
                        <p class="fs-14 text-gray-dark">{{$t('errors.4')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            items: Object,
        },

        data() {
            return {
                loading: true,
                group_assets: [
                    {
                        title: 'All',
                        key: 0,
                        count: 0
                    },
                    {
                        title: 'Substation',
                        key: 100,
                        count: 0
                    },
                    {
                        title: 'Transformer',
                        key: 102,
                        count: 0
                    },
                    {
                        title: 'Pole',
                        key: 104,
                        count: 0
                    },
                    {
                        title: 'Cabin',
                        key: 111,
                        count: 0
                    },
                    {
                        title: 'Pillar',
                        key: 115,
                        count: 0
                    },
                    {
                        title: 'Box',
                        key: 113,
                        count: 0
                    },
                    {
                        title: 'Service Point',
                        key: 112,
                        count: 0
                    },
                    {
                        title: 'Gateway',
                        key: 116,
                        count: 0
                    }
                ],
                active_group: 0,
                active_item: null,
                assets: null,
            }
        },

        computed: {
            devicesList() {
                return this.$store.getters.getSettings.devicesList;
            },
            filtered_assets() {
                let data = this.assets;
                let result = [];

                if(this.active_group && this.active_group != 0) {
                    data.forEach(item => {
                        if(item.deviceType == this.active_group) result.push(item)
                    });
                }
                if(!this.active_group || this.active_group == 0) result = data;
                return result
            }
        },

        watch: {
            items() {
                this.init()
            }
        },

        mounted() {
            Draggable.create("#dialog-group-asset", {
                trigger:"#drag-group-asset",
                bounds:".main-page",
                edgeResistance:0.65,
                inertia:true,
                autoScroll:1,
            });

            this.emitter.on("closeGroupAssets", e => {
                if(e) this.closeDialog();
            });

            this.init();
        },

        methods: {
            init() {
                this.loading = true;
                this.group_assets[0].count = this.items.length;
                this.assets = this.items;
                this.assets.forEach(item => {
                   this.group_assets.filter(function(asset) {
                        if(asset.key == item.deviceType) asset.count +=1;
                    });
                });
                this.loading = false;
            },
            showType(type) {
                let result;
                if (this.devicesList) {
                    this.devicesList.forEach(device => {
                        if (device.id == type) result = device.name
                    })
                }
                return result
            },

            setActiveItem(item) {
                item.deviceId = item.deviceID;
                this.setActiveIndex(item.deviceID);
                this.$emit('set_active', item, null, 'cluster');
            },

            setKey(key) {
                if(this.active_group != key) this.active_group = key;
            },
            setActiveIndex(id) {
                this.active_item = id;
            },
            resetActive() {
                this.active_item = null;
            },
            closeDialog() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="sass">
    .asset-title
        display: flex
        flex-wrap: nowrap
        overflow-x: scroll
        margin-bottom: 8px
        min-height: 44px
        padding-right: 0

        .filter-button
            height: 32px
            padding: 0 12px
            display: flex
            align-items: center
            font-size: 12px
            border-radius: 20px
            color: var(--color-primary)
            border: 1px solid var(--color-primary)
            transition: 0.3s ease-in-out
            white-space: nowrap
            margin-right: 8px
            &:hover
                cursor: pointer
                background: var(--color-primary-hover-light)

        .filter-button.active
            color: var(--text-color-white)
            background: var(--color-primary)
            border: 1px solid var(--color-primary)
    .item
        padding: 12px
        display: flex
        align-items: center
        justify-content: space-between
        border-radius: 8px
        transition: 0.4s ease-in-out
        &:hover
            cursor: pointer
            background: var(--color-primary-hover-light)
        .item-name
            padding-right: 8px
            font-weight: 500
            display: flex
            img
                width: 18px
                height: 18px
                margin-right: 8px
        .item-type
            white-space: nowrap

    .item.active
        background: rgba(42, 84, 205, 0.16)
</style>

<style scoped lang="sass">
    .asset-body
        overflow-x: scroll
        overflow-y: scroll

</style>