<template>
    <div>
<!--        <nav-app v-if="$route.name !== 'login' && $route.name !== 'recover'"></nav-app>-->
        <nav-app v-if="user"></nav-app>
        <router-view></router-view>
    </div>

</template>

<script>
    import '/src/assets/sass/app.sass';
    import NavApp from './components/partial/NavigationApp';

    export default {
        name: 'App',

        components: {
            NavApp
        },

        data() {
            return {}
        },

        computed: {
            user() {
                return this.$store.getters.getUser;
            }
        },

        mounted() {
            this.$store.dispatch('fetchUser');
            if(localStorage.getItem('token')) {
                this.$store.dispatch('fetchSettings', this.axios);
                // this.$store.dispatch('fetchDropdown', this.axios);
            }
        }

    }
</script>