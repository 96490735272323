<template>
    <div v-if="feeders_up">

        <!-- PARENT -->
        <div v-if="feeders_up.parent" class="treeview-item"
             :class="{'show': feeders_up.parent.show_feeder_assets}"
             @click="feeders_up.parent.show_feeder_assets = !feeders_up.parent.show_feeder_assets">
            <img :src="'/svg/assets/' + feeders_up.parent.deviceType + (active_asset == feeders_up.parent.deviceType ? '_single_active.svg' : '_single.svg')" alt="">
            <div class="item-name"> {{feeders_up.parent.Name}}</div>
        </div>

        <div class="treeview-padding" v-if="feeders_up.parent.show_feeder_assets">
            <!-- FEEDER -->
            <div class="treeview-item" :class="{'show': feeders_up.show_feeder_assets}"
                 @click="feeders_up.show_feeder_assets = !feeders_up.show_feeder_assets">
                <img src="/svg/assets/116_single.svg" alt="">
<!--                <div class="text-capitalize">Feeder - {{feeders_up.FeederID}}</div>-->
                <div class="item-name" v-if="feeder">{{feeder.feederName}}</div>
            </div>
            <div class="child">
                <!-- ASSETS -->
                <div v-if="feeders_up.assets && feeders_up.assets.length && feeders_up.show_feeder_assets"  class="treeview-padding">
                    <treeview-asset v-for="asset in feeders_up.assets" :key="asset.AssetID" :up="true" :active_asset="active_asset"
                                    :item="asset" :active_item="active_item"></treeview-asset>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import treeviewAsset from '../treeview/TreeviewAsset'

    export default {
        props: {
            item: Object,
            active_item: Object,
            active_asset: {
                default: 1
            }
        },

        components: {
            treeviewAsset
        },

        data() {
            return {
                feeders: [],
                parent: null,
                feeders_up: null,
                feeder: null
            }
        },

        mounted() {
            this.feeders_up = this.item;
            this.getFeederData(this.feeders_up);
        },

        methods: {
            getFeederData(e) {
                let url = `getFeederConnection/${e.assets[0].AssetType}/${e.assets[0].AssetID}`;
                return this.axios.get(url)
                    .then(response_feeder => {
                        let feeders = response_feeder.data;
                        feeders.forEach(feeder => {
                            if(e.FeederID == feeder.feederID) {
                                feeder.show_feeder_assets = false;
                                feeder.feeder_assets = null;
                                feeder.feeder_loading = false;
                                feeder.last = false;
                                this.feeder = feeder;
                            }
                        });
                    });
            }
        }
    }
</script>