<template>
    <div class="error-page">
        <div class="error-card">
            <div class="error-card__body">
                <div class="icon">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z" fill="#2A54CD"/>
                    </svg>
                </div>
                <h1 class="font-400 primary">Page not found</h1>
                <p>The URL you requested wasn’t found on the server. Please check the address or try going back to the previous page</p>
                <i-button to="/" title="Go to homepage"></i-button>
            </div>

        </div>
    </div>
</template>

<style lang="sass" scoped>
    .error-page
        height: calc(100vh - 72px)
        display: flex
        align-items: center
        justify-content: center
    .error-card
        width: 45vw
        height: 60vh
        background: var(--background-light)
        display: flex
        align-items: center
        justify-content: center
        margin: auto
        border-radius: 4px
        box-shadow: var(--shadow-300)
        .error-card__body
            width: 47%

        h1
            font-size: 45px
            margin-bottom: 24px

        p
            margin-bottom: 32px
        .button.primary
            width: 100%

        .icon
            text-align: center
            margin-bottom: 30px
</style>