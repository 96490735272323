import { createApp } from 'vue';

import i18n from './lang';
import mitt from 'mitt';
import MainService from './services/MainService';


/* Swal */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const swalOptions = {
    confirmButtonColor: '#2A54CD',
    cancelButtonColor: '#2A54CD',
    padding: '24px',
    color: '#1A1C1E'
};

/* Axios */
import VueAxios from 'vue-axios';
import axiosInstance from "./api/axios.js";

//tooltip
import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';

/*Components*/
import iButton from './components/partial/elements/ButtonEl';
import iInput from './components/partial/elements/InputEl';
import iSelect from './components/partial/elements/SelectEl';
import iDropdown from './components/partial/elements/DropdownEl';
import iLoading from './components/partial/elements/LoadingEl';
import iTextarea from './components/partial/elements/TextareaEl';
import TreeviewItemDown from './components/treeview/TreeviewItemDown';
import TreeviewItemUp from './components/treeview/TreeviewItemUp';
import iSegmented from './components/partial/elements/SegmentedEl.vue';
import iChip from './components/partial/elements/ChipEl.vue';
import { ElTable, ElTableColumn, ElPagination } from 'element-plus';



import router from './routes';

import { createStore } from 'vuex';
import user from "./store/modules/user";
import settings from "./store/modules/settings";
import dropdown from "./store/modules/dropdowns";
import modals from "./store/modules/modals";
window.store = createStore({
    modules: { user, settings, dropdown, modals }
});
const emitter = mitt();

import App from './App.vue';
const app = i18n(createApp(App).use(router).use(window.store));

// document.cookie = 'CF_AppSession=n41408b4a8ff7421f';
// document.cookie = 'CF_Authorization=eyJhbGciOiJSUzI1NiIsImtpZCI6IjJmNzgzMjUwOTQyNDZjMzMxZmYzNzFlMmFkMzQ0MjM2OGFiOGY2YTI0N2VkZmE1MWFmMTJiNjRlYmQ4YTk2NGQifQ.eyJhdWQiOlsiMTU3MWE4YzlhNDRjOTllMWQ3NmRkZmIxMTEyZGE0OTQ5ZGMxYzEwNzg1OGMxYWMwMzczNzAxNWU2YTAxNGE5NiJdLCJlbWFpbCI6ImZlZG9yLnBvemRlZXZAcG93ZXJjb20uY28uaWwiLCJleHAiOjE3MTg0OTg3MjksImlhdCI6MTcxNTg3MDcyOSwibmJmIjoxNzE1ODcwNzI5LCJpc3MiOiJodHRwczovL3Bvd2VyY29tLmNsb3VkZmxhcmVhY2Nlc3MuY29tIiwidHlwZSI6ImFwcCIsImlkZW50aXR5X25vbmNlIjoiMGhPZm9GdUc1bUd4akx2YyIsInN1YiI6IjA4YzVjODBlLWU0ODUtNTdhYi1hYjAyLTNjNDg4ZTAxZjM5NCIsImNvdW50cnkiOiJVQSJ9.PifACvgd07CbsRV_Xw34gEOfhZi7ezElO-v8-SmdLvLB_j9qRYABmJbcm1JYJA9VN-AsQZAmJTawkMoVtVnvdkAl99vI08xOVyYqakeiyzF45AUe0mhbX8ujKsj3pFXCxMzOdL_8KIw9xgNoIaitypSRV6dpUOxSnizJJlVdCD_I1MY7GDd0sY5gXIFjG-JqXO3Xy_dIBGJOvyygrjPM_hiUADNt363u_RLZF1CeFFVRrdQfb_xCLDEbczmWUy8tADwAkr6-Cscnqj5jG5JP7CjnvwKi4PrUzSr0hvx3mltjm3v1mWsND1uW_bZQ1Kryx26vxM73GohFbQaMxonpqg';

app.mixin(MainService);
app.use(VueAxios, axiosInstance);
app.directive('tooltip', vTooltip)
app.use(VueSweetalert2, swalOptions);
app.config.globalProperties.emitter = emitter;
app.component('iButton', iButton);
app.component('iInput', iInput);
app.component('iSelect', iSelect);
app.component('iDropdown', iDropdown);
app.component('iLoading', iLoading);
app.component('iSegmented', iSegmented);
app.component('iChip', iChip);
app.component('iTextarea', iTextarea);
app.component('ElTable', ElTable);
app.component('ElTableColumn', ElTableColumn);
app.component('ElPagination', ElPagination);
app.component('TreeviewItemDown', TreeviewItemDown);
app.component('TreeviewItemUp', TreeviewItemUp);
app.mount('#app');
