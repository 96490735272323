/* eslint-disable no-unused-vars */
let hoverValue = undefined;
const hoverSegment = {
    id: "hoverSegment",
    beforeDatasetsDraw(chart) {
        const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
        ctx.save();
        ctx.fillStyle = "rgba(42, 84, 205, 0.2)";
        const segment = width / (x.max + 1);
        if (hoverValue !== undefined) {
            ctx.fillRect(x.getPixelForValue(hoverValue) - segment / 2, top, segment, height);
        }
    },
    afterEvent(chart, args, plugins) {
        const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
        if (args.inChartArea) {
            hoverValue = x.getValueForPixel(args.event.x);
        } else {
            hoverValue = undefined;
        }
        args.changed = true;
    }
}

export { hoverSegment }