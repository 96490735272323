export default {
    actions: {
    },

    mutations: {
        setResultsModal(state, value) {
            state.results.show = value
        },
        setDetailsModal(state, value) {
            state.details = value
        },
        setItemModal(state, value) {
            state.item = value
        },
        setTreeviewModal(state, value) {
            state.treeview.show = value
        },
        setClusterModal(state, value) {
            state.cluster = value
        },
        /*position*/
        setTreeviewPosition(state, value) {
            state.treeview.position = value
        },
        setResultsPosition(state, value) {
            state.results.position = value
        },
        /*zoom*/
        setTreeviewZoom(state, value) {
            state.treeview.collapse = value
        },
        setResultsZoom(state, value) {
            state.results.collapse = value
        }
    },

    state: {
        results: {
            show: false,
            collapse: false,
            position: 0
        },
        details: {
            show: false,
            collapse: false,
        },
        item: {
            show: false,
            collapse: false,
        },
        treeview: {
            show: false,
            collapse: false,
            position: 0
        },
        cluster: {
            show: false,
            collapse: false,
        }
    },

    getters: {
        getResultsModal(state) {
            return state.results
        },
        getDetailsModal(state) {
            return state.details
        },
        getItemModal(state) {
            return state.item
        },
        getTreeviewModal(state) {
            return state.treeview
        },
        getClusterModal(state) {
            return state.cluster
        }
    },
}
