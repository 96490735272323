export default {
    methods: {
        mainNotification(message) {
            this.$swal.fire({
                position: 'top-end',
                icon: false,
                backdrop: false,
                text: message,
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: 'animate__animated animate__fadeInRight'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutRight'
                },
            })
        },

        mainShowDate(data) {
            let result = null;
            if(data) {
                let date = new Date(data);
                let day = date.getDate();
                if (day < 10) day = '0' + day;
                let month = date.getMonth() + 1;
                if (month < 10) month = '0' + month;
                let year = date.getFullYear();
                result = `${day}/${month}/${year}`;
            }
            return result;
        },

        mainSaveFormatData(data) {
            let format_date = null;
            if(data) {
                let date = data.split('/');
                let day = date[0];
                let month = date[1];
                let year = date[2];
                let today = new Date();
                let hours = (today.getHours()<10?'0':'') + today.getHours();
                let minutes = (today.getMinutes()<10?'0':'') + today.getMinutes();
                let seconds = (today.getSeconds()<10?'0':'') + today.getSeconds();
                format_date = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.265Z`;
            }
            return format_date;
        }
    }
}
