<template>
  <div
    class="dropdown"
    ref="dropdown"
    :class="{ active: show, styled: $slots.default }"
  >
    <slot @click="show = !show">
      <div class="dropdown-header" @click="show = !show">
        <img v-if="img" :src="img" alt="" class="me-2" />
        {{ title }}
      </div>
    </slot>
    <div class="dropdown-menu" v-if="show">
      <ul>
        <li v-for="option in options" :key="option.title">
          <a
            :href="option.href ? option.href : '#'"
            rel="noopener"
            @click="action(option)"
            :target="option.href ? '_blank' : '_self'"
            :class="{ 'd-flex': option.img }"
          >
            <img v-if="option.img" :src="option.img" class="me-1" />
            {{ option.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onDeactivated } from 'vue';
export default {
  setup() {
    onDeactivated(() => {
      document.removeEventListener('click', this.close);
    });
  },
  props: {
    title: String,
    options: Array,
    img: String,
  },
  created() {
    document.addEventListener('click', this.closeDropdown);
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeDropdown(e) {
      let el = this.$refs.dropdown;
      let target = e.target;
      if (el && el !== target && !el.contains(target)) {
        this.show = false;
      }
    },

    action(option) {
      if (!option.href) {
        this.$emit('click_option', option);
      }
      this.show = !this.show;
    },
  },
  // destroyed () {
  //     document.removeEventListener('click', this.close)
  // },
};
</script>
