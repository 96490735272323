<template>
    <div>
        <!-- *** STEPPER  *** -->
        <div v-if="step && step > 1" class="stepper">
            <div class="stepper-back" @click="hideCreation()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 8.25H5.8725L10.065 4.0575L9 3L3 9L9 15L10.0575 13.9425L5.8725 9.75H15V8.25Z"
                          fill="#2A54CD"/>
                </svg>
                <span>{{$t('map.69')}}</span>
            </div>
            <div class="stepper-navigation">
                <div class="stepper-navigation-item" :class="{'active': step == 2, 'confirmed': step > 2}">
                    <div class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                        <span>1</span>
                    </div>
                    <p>{{$t('map.70')}}</p>
                </div>
                <div class="stepper-navigation-item" :class="{'active': step == 3, 'confirmed': step > 3}">
                    <span class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                       <span>2</span>
                    </span>
                    <p>{{$t('map.71')}}</p>
                </div>
                <div class="stepper-navigation-item" :class="{'active': step == 4 || step == 5 || step == 6}">
                    <span class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                       <span>3</span>
                    </span>
                    <p>{{$t('map.72')}}</p>
                </div>
            </div>
            <div></div>
        </div>

        <!-- ***  STEP 1  *** -->
        <div v-if="step == 1" class="new_asset backdrop">
            <div class="new_asset_card">
                <div class="close" @click="hideCreation()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                              fill="#909194"/>
                    </svg>
                </div>
                <div class="new_asset_card_header fs-32 mb-4 font-500">{{$t('map.65')}}</div>
                <p class="fs-16 mb-6">{{$t('map.66')}}</p>
                <div class="assets-container" v-if="assets">
                    <div class="asset" v-for="asset in assets" :key="asset.id" @click="setAsset(asset.id)">
                        <div class="image-container" v-html="asset.icon"></div>
                        <p>{{asset.title_en}}</p>
                    </div>
                    <!--                    <div class="asset" @click="setAsset(116)">-->
                    <!--                        <div class="image-container">-->
                    <!--                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3H21V21H3V3ZM5 5V19H19V5H5ZM8 8H16V16H8V8ZM10 10V14H14V10H10Z" fill="#2A54CD"/>-->
                    <!--                            </svg>-->
                    <!--                        </div>-->
                    <!--                        <p>Others</p>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>

        <!-- ***  STEP 2  *** -->
        <div v-if="step == 2" class="position_card">
            <div class="fs-28 mb-4">{{$t('map.67')}}</div>
            <p class="mb-5">{{$t('map.68')}}</p>
            <div class="button-container">
                <i-button small :title="$t('buttons.1')" @click="confirmPosition()"></i-button>
            </div>
        </div>

        <!-- ***  STEP 3  *** -->
        <div v-if="step == 3" class="backdrop details_asset">
            <div class="details_asset_card">
                <div class="details_asset_header fs-28 mb-10">{{showType(new_asset.deviceType)}}</div>
                <div class="details_asset_body">
                    <details-data :item="new_asset"
                                  :childs="childs"
                                  :show_asset="false"
                                  :editable="true"
                                  :data_for_create="true"
                                  ref="detailsData"
                                  @set_filled_data="setFilledData"
                                  @set_invalid="setInvalid"></details-data>
                </div>
                <div class="details_asset_footer">
                    <div>
                        <p v-if="invalid" class="error-color">Fill in required fields</p>
                    </div>
                    <div>
                        <i-button @click="hideCreation()" class="me-2" color="secondary" small
                                  :title="$t('buttons.7')"></i-button>
                        <i-button :disabled="invalid" @click="saveData()" small :title="$t('buttons.6')"></i-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- ***  STEP 4 - 5 - 6  *** -->
        <div v-if="step == 4 || step == 5 || step == 6" class="link_card">
            <div class="loading" v-if="loading">
                <i-loading></i-loading>
            </div>
            <p class="fs-28 mb-8">{{$t('map.73')}}</p>
            <ul>
                <li :class="{'active': step == 4, 'confirmed': step > 4}">
                    <div class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                        <p>1</p>
                    </div>
                    <p class="font-500"> {{$t('map.74')}}</p>
                </li>
                <li :class="{'active': step == 5, 'confirmed': step > 5}">
                    <div class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                        <p>2</p>
                    </div>
                    <p class="font-500"> {{$t('map.75')}}</p>
                </li>
                <li :class="{'active': step == 6}">
                    <div class="num">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.391 5.95425L1.04575 3.60825L0.25 4.404L3.39175 7.54575L10.1418 0.79575L9.346 0L3.391 5.95425Z"
                                  fill="#2A54CD"/>
                        </svg>
                        <p>3</p>
                    </div>
                    <p class="font-500"> {{$t('map.76')}}</p>
                </li>
            </ul>
            <div class="button-container">
                <i-button small color="secondary" :title="$t('buttons.15')" @click="stepBack()"
                          img="/svg/arrow-left.svg"></i-button>
                <i-button small :title="$t('buttons.16')" @click="linkToNextStep()"></i-button>
            </div>
        </div>

        <!-- ***  STEP 5  *** -->
        <div v-if="step == 5" class="feeders_card">
            <div class="fs-22 mb-6 font-400"> {{$t('map.22')}}</div>
            <div class="parents_container">
                <div class="parent_box" :class="{'active': active_parent == parent.deviceId}" v-for="parent in parents"
                     :key="parent.deviceId">
                    <div class="parent_box_header" @click="setActiveParent(parent.deviceId, parent.feeders)">
                        <img :src="'/svg/assets/'+parent.deviceType+'_single.svg'" alt="">
                        <span class="fs-16">{{showType(parent.deviceType)}} {{parent.deviceId}}</span>

                        <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 17L14.5 12L9.5 7V17Z" fill="#45464E"/>
                        </svg>

                    </div>

                    <div class="parent_box_body" v-if="active_parent == parent.deviceId">
                        <div class="parent_box_body_card" v-for="feeder in parent.feeders" :key="feeder.feederID">

                            <div class="input-checkbox input-element" :class="{'input-selected': feeder.selected}">
                                <input type="checkbox" :id="feeder.feederID" v-model="feeder.selected"
                                       :value="feeder.feederID" @change="selectFeeder(feeder)">
                                <label :for="feeder.feederID">
                                    {{showType(feeder.feederType)}} {{feeder.feederID}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ***  STEP 6  *** -->
        <div v-if="step == 6" class="cabling_method_card">
            <div class="cabling_method_card_header">
                <img :src="'/svg/assets/'+new_asset.deviceType+'_single.svg'" alt="">
                <div>
                    <p class="fs-22">{{new_asset.name}}</p>
                    <span class="fs-12 text-gray-light">{{showType(new_asset.deviceType)}}</span>
                </div>
            </div>

            <div class="cabling_method_card_body">
                <p class="mb-8">{{$t('map.79')}}</p>

                <div class="parents" v-for="parent in parents" :key="parent.deviceId">
                    <div class="parent-header">
                        <img :src="'/svg/assets/'+parent.deviceType+'_single.svg'" alt="">
                        <span class="fs-16 font-600">{{showType(parent.deviceType)}} {{parent.deviceId}}</span>
                    </div>

                    <div class="parent-body">
                        <div class="feeders-data"
                             :class="{'active': active_feeder == parent.deviceId + '_' + feeder.feederID}"
                             v-for="feeder in parent.selected_feeders" :key="feeder.feederID">
                            <div class="feeder-item-header" @click="selectSelectedFeeder(parent, feeder)">
                                {{showType(feeder.feederType)}} {{feeder.feederID}}

                                <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.5 17L14.5 12L9.5 7V17Z" fill="#45464E"/>
                                </svg>
                            </div>
                            <div class="feeder-item-body"
                                 v-if="active_feeder == parent.deviceId + '_' + feeder.feederID">
                                <div class="feeders-data__item">
                                    <input type="radio" :id="parent.deviceId + '_' + feeder.feederID + '_1'"
                                           v-model="feeder.feederLocationTypeID"
                                           value="1">
                                    <label :for="parent.deviceId + '_' + feeder.feederID + '_1'">{{$t('map.80')}}</label>
                                </div>
                                <div class="feeders-data__item">
                                    <input type="radio" :id="parent.deviceId + '_' + feeder.feederID + '_2'"
                                           v-model="feeder.feederLocationTypeID"
                                           value="2">
                                    <label :for="parent.deviceId + '_' + feeder.feederID + '_2'">{{$t('map.81')}}</label>
                                </div>

                                <div class="line"></div>

                                <div class="input-switch" v-if="new_asset.deviceType != 104"
                                     :class="{'input-selected': feeder.mainFeeder}">
                                    <input type="checkbox"
                                           :id="parent.deviceId + '_' + feeder.feederID + '_main_feeder'"
                                           @change="selectMainFeeder(feeder, parent)"
                                           value="true">
                                    <label :for="parent.deviceId + '_' + feeder.feederID + '_main_feeder'">{{$t('map.82')}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import assets from './../../assets/json/assets';
    import DetailsData from "./details/DetailsData";

    export default {
        props: {
            add_new_marker: {
                type: Boolean,
                default: false
            },
            marker_parents: {
                type: Array,
            },
            marker: {
                type: Object
            }
        },

        components: {
            DetailsData
        },

        data() {
            return {
                loading: false,
                step: 0,
                childs: [],
                new_asset: {
                    deviceType: null,
                },
                parents: [],
                active_parent: 0,
                active_feeder: 0,
                invalid: true
            }
        },

        computed: {
            assets() {
                return assets.assets;
            },
        },

        methods: {
            setStep(step) {
                this.active_parent = 0;
                this.active_feeder = 0;
                this.step = step;
                this.$emit('set_step', step);
                this.$emit('hide_feeder', true);
                if (step == 4) this.$emit('hide_selected_feeder', true);
            },

            setAsset(id) {
                this.setStep(2);
                this.new_asset.deviceType = id;
                this.$emit('set_new_asset', id)
            },

            confirmPosition() {
                this.setStep(3);
                this.$emit('confirm_asset_position', true)
            },

            linkToNextStep() {
                if (this.step == 6) {
                    this.loading = true;
                    let valid;
                    if(this.new_asset.deviceType == 104) valid = true;
                    else {
                        /*valid main feeder*/
                        this.parents.forEach(parents => {
                            parents.selected_feeders.forEach(feeders => {
                                if(feeders.mainFeeder) valid = true;
                            })
                        });
                    }

                    if(valid) this.saveNewItem();
                    if(!valid) {
                        this.loading = false;
                        this.mainNotification(this.$t('errors.9'));
                    }
                }

                /*for current 4 step*/
                if (this.step == 4 && this.marker_parents && this.marker_parents.length) {
                    this.parents = [];
                    this.marker_parents.forEach((marker, i) => {
                        this.loading = true;
                        marker.feeders = [];
                        marker.selected_feeders = [];

                        if(marker.deviceType == 100 || marker.deviceType == 102 || marker.deviceType == 111) {
                            /*output feeders*/
                            let url = `getFeederConnection/${marker.deviceType}/${marker.deviceId}`;
                            this.axios.get(url)
                                .then(response => {
                                    let result = response.data;
                                    result.forEach(res => {
                                        res.direction = 'down';
                                        res.selected = false;
                                        res.mainFeeder = false;
                                        res.feederLocationTypeID = 1;
                                        res.lat = marker.lat;
                                        res.lng = marker.lng;
                                        let check = marker.feeders.find(value => value.feederID == res.feederID);
                                        if (!check) marker.feeders.push(res);
                                    });
                                    this.parents.push(marker);
                                    if ((i + 1) == this.marker_parents.length) {
                                        this.setStep(5);
                                        this.loading = false;
                                    }
                                });
                        }
                        else {
                            /*input feeders*/
                            let url_input = `getInfoDevice/${marker.deviceType}/${marker.deviceId}`;
                            this.axios.get(url_input)
                                .then(response => {
                                    let result = response.data;
                                    result.parentsArray.forEach(res => {
                                        if(res.FeederID) {
                                            res.direction = 'up';
                                            res.feederID = res.FeederID;
                                            res.feederType = res.FeederType;
                                            res.parentID = res.ParentID;
                                            res.parentType = res.ParentType;
                                            res.lat = marker.lat;
                                            res.lng = marker.lng;
                                            res.selected = false;
                                            res.mainFeeder = false;
                                            res.feederLocationTypeID = res.FeederLocationTypeID;
                                            let check = marker.feeders.find(value => value.feederID == res.FeederID);
                                            if (!check) marker.feeders.push(res);
                                            this.loading = false;
                                        }
                                        else this.loading = false;
                                    });

                                    this.parents.push(marker);
                                    if ((i + 1) == this.marker_parents.length) this.setStep(5)
                                });
                        }
                    });
                }
                if (this.step == 4 && this.marker_parents && !this.marker_parents.length) {
                    this.mainNotification(this.$t('errors.6'));
                }

                if (this.step == 5) {
                    let valid = true;
                    this.loading = true;

                    this.parents.forEach((parent, i) => {
                        console.log(parent)
                        parent.selected_feeders = [];
                        let check = parent.feeders.find(value => value.selected === true);
                        if (!check) {
                            valid = false;
                            if (this.parents.length == 1) this.mainNotification(this.$t('errors.7'));
                            if (this.parents.length > 1) this.mainNotification(this.$t('errors.8'));
                            this.loading = false;
                            return;
                        }
                        if (check) {
                            parent.feeders.forEach(feeder => {
                                if (feeder.selected) parent.selected_feeders.push(feeder)
                            });
                            if ((i + 1) == this.parents.length && valid) {
                                this.setStep(6);
                                this.loading = false;
                                this.$emit('hide_feeder', true);
                                this.$emit('set_selected_feeders', this.parents)
                            }
                        }
                    });
                }
            },

            selectFeeder(feeder) {
                if (feeder.selected) this.$emit('set_feeder', feeder);
                if (!feeder.selected) this.$emit('remove_feeder', feeder);
            },

            setActiveParent(id, feeders) {
                if(this.active_parent != id) {
                    this.active_parent = id;
                    this.$emit('hide_feeder', true);
                    this.$nextTick(() => {
                        feeders.forEach(feeder => {
                            this.$emit('show_feeder', feeder);
                        })
                    });
                    return;
                }
                if(this.active_parent == id)  this.active_parent = null;
            },

            selectSelectedFeeder(parent, feeder) {
                if(this.active_feeder != parent.deviceId + '_' + feeder.feederID) {
                    this.$emit('show_selected_feeder', feeder);
                    this.active_feeder = parent.deviceId + '_' + feeder.feederID;
                    return;
                }
                if(this.active_feeder == parent.deviceId + '_' + feeder.feederID) this.active_feeder = null;
            },

            stepBack() {
                let step = this.step - 1;
                this.setStep(step);
            },
            hideCreation() {
                this.$swal.fire({
                    icon: false,
                    title: this.$t('map.77'),
                    html: this.$t('map.78'),
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: this.$t('buttons.17'),
                    confirmButtonColor: '#BA1B1B',
                    reverseButtons: true,
                    closeButtonHtml: '<img src="/svg/close.svg">',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.step = 0;
                        this.new_asset = {
                            deviceType: null
                        };
                        this.childs = [];
                        this.parents = [];
                        this.active_parent = 0;
                        this.active_feeder = 0;
                        this.invalid = true;
                        this.$emit('hide_creation', true)
                    }
                })
            },

            saveData() {
                this.$refs.detailsData.getFilledData();
            },

            saveNewItem() {
                let url;
                if (this.new_asset.deviceType == 100) url = 'addSubstation';
                if (this.new_asset.deviceType == 102) url = 'addTransformer';
                if (this.new_asset.deviceType == 104) url = 'addPole';
                if (this.new_asset.deviceType == 111) url = 'addCabin';
                if (this.new_asset.deviceType == 115) url = 'addPillar';
                if (this.new_asset.deviceType == 113) url = 'addBox';
                if (this.new_asset.deviceType == 112) url = 'addServicePoint';

                let data = this.new_asset;
                data.latitude = this.marker.position.lat();
                data.longitude = this.marker.position.lng();

                if (this.parents && this.parents.length) {
                    data.parentsArray = [];
                    this.parents.forEach(parent => {
                        parent.feeders.forEach(feeder => {
                            if(feeder.selected) {
                                let parent_data = {};
                                parent_data.parentType = parent.deviceType;
                                parent_data.parentID = parent.deviceId;
                                parent_data.feederID = feeder.feederID;
                                parent_data.feederLocationTypeID = feeder.feederLocationTypeID;
                                parent_data.feederType = feeder.feederType;
                                parent_data.mainFeeder = feeder.mainFeeder;
                                data.parentsArray.push(parent_data);
                            }
                        });
                    })
                }
                this.axios.post(url, data)
                    .then(response => {
                        if (response && response.data.status == 200) {
                            if (this.new_asset.child) {
                                this.new_asset.child.forEach(child => {
                                    let url_child;
                                    if (child.deviceType == 112) url_child = 'addServicePoint';
                                    if (child.deviceType == 116) url_child = 'addGetway';

                                    child.latitude = this.marker.position.lat();
                                    child.longitude = this.marker.position.lng();

                                    let parent = null;
                                    data.parentsArray.forEach(item => {
                                        if(item.mainFeeder) parent = item;
                                    });

                                    child.parentsArray = [
                                        {
                                            parentType: this.new_asset.deviceType,
                                            parentID: response.data.id,
                                            feederID: parent.feederID,
                                            feederLocationTypeID: parent.feederLocationTypeID,
                                            feederType: parent.feederType,
                                            mainFeeder: parent.mainFeeder
                                        }
                                    ]
                                    this.axios.post(url_child, child)
                                })
                            }
                            this.step = 0;
                            this.loading = false;
                            this.new_asset = {
                                deviceType: null

                            };
                            this.$emit('hide_creation', true);
                            this.mainNotification(this.$t('notification.3'));
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            this.mainNotification(error.response.data.message);
                            this.loading = false;
                        }
                    })
            },

            selectMainFeeder(feeder, parent) {
                this.parents.forEach(parents => {
                    parents.selected_feeders.forEach(feeders => {
                        if(parents.deviceId != parent.deviceId && feeders.feederID != feeder.feederID) feeders.mainFeeder = false;
                        if(parents.deviceId == parent.deviceId && feeders.feederID == feeder.feederID) feeders.mainFeeder = !feeders.mainFeeder;
                    })
                })
            },

            setInvalid(valid) {
                this.invalid = valid
            },

            setFilledData(data) {
                this.new_asset = data;
                if (this.new_asset.deviceType != 100) {
                    this.setStep(4)
                }
                if (this.new_asset.deviceType == 100) {
                    this.saveNewItem();
                }
            },

            showType(type) {
                let result;
                if (this.assets) {
                    this.assets.forEach(device => {
                        if (device.id == type) result = device.title_en
                    })
                }
                if (!result) {
                    if (type == 101) result = 'Feeder HV';
                    if (type == 114) result = 'Feeder LV';
                }
                return result;
            },
        }
    }
</script>

<style scoped lang="sass">
    .stepper
        z-index: 29
        position: fixed
        left: 0
        top: 0
        right: 0
        background: var(--background-light)
        height: 88px
        padding: 0 16px
        display: grid
        grid-template-columns: 160px auto 160px
        justify-content: space-between
        align-items: center
        box-shadow: var(--shadow-100)

        .stepper-back
            font-weight: 500
            color: var(--color-primary)
            display: flex
            align-items: center

            svg
                transition: 0.3s ease-in-out
                margin-right: 8px

            &:hover
                cursor: pointer

                svg
                    transform: translateX(-10px)


        .stepper-navigation
            display: grid
            grid-template-columns: repeat(3, 200px)
            border-radius: 8px
            overflow: hidden

            .stepper-navigation-item
                height: 40px
                display: flex
                align-items: center
                justify-content: center
                background: var(--color-primary-hover-light)
                position: relative

                &:last-child
                    &::before, &::after
                        content: none

                &::before, &::after
                    content: ''
                    position: absolute
                    top: 0
                    border: 20px solid transparent

                &::before
                    left: 100%
                    z-index: 3
                    border-left: 13px solid var(--color-primary-hover-light)

                &::after
                    left: calc(100% + 1px)
                    z-index: 2
                    border-left: 13px solid var(--color-primary-dark)

                .num
                    width: 24px
                    height: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    font-size: 1rem
                    margin-right: 7px
                    border-radius: 50%
                    background: var(--color-primary-dark)
                    color: var(--color-primary-hover-light)

                    svg
                        display: none

                p
                    color: var(--color-primary-dark)
                    font-weight: 500

            .stepper-navigation-item.active
                .num
                    background: var(--color-primary)

            .stepper-navigation-item.confirmed
                background: var(--color-primary)

                &::before
                    border-left: 13px solid var(--color-primary)

                &::after
                    border-left: 13px solid var(--background-white)

                .num
                    background: var(--background-white)

                    svg
                        display: block

                    span
                        display: none

                p
                    color: var(--text-color-white)


    .new_asset
        .new_asset_card
            background: var(--background-light)
            border-radius: 12px
            padding: 24px 24px 32px 24px
            position: relative

            .close
                position: absolute
                z-index: 2
                right: 16px
                top: 16px

                svg
                    path
                        transition: 0.3s ease-in-out

                &:hover
                    cursor: pointer

                    svg
                        path
                            fill: var(--text-color-dark)

            .assets-container
                display: grid
                grid-column-gap: 24px
                grid-row-gap: 24px
                grid-template-columns: repeat(4, 1fr)

                .asset
                    padding: 0 24px
                    display: flex
                    flex-direction: column
                    align-items: center
                    font-weight: 500

                    &:hover
                        cursor: pointer

                        p
                            color: var(--color-primary)

                        .image-container
                            background: var(--color-primary-hover-light)

                    &:focus, &:active
                        .image-container
                            background: var(--color-primary)

                    p
                        transition: 0.3s ease-in-out

                    .image-container
                        display: flex
                        align-items: center
                        justify-content: center
                        border: 2px solid var(--color-primary)
                        margin-bottom: 4px
                        border-radius: 50%
                        width: 48px
                        height: 48px
                        transition: 0.3s ease-in-out

    .position_card
        min-width: 286px
        max-width: 300px
        position: fixed
        z-index: 10
        left: 16px
        top: 50%
        transform: translateY(-50%)
        padding: 24px 24px 16px 24px
        background: var(--background-light)
        border-radius: 12px
        box-shadow: var(--shadow-300)

        .button-container
            text-align: right

    .details_asset
        .details_asset_card
            width: 628px
            border-radius: 12px
            background: var(--background-light)
            max-height: 80vh
            padding: 32px 24px
            position: relative
            overflow: hidden

        .details_asset_body
            padding: 8px 12px
            margin: 0 -12px
            max-height: calc(80vh - 116px - 68px)
            overflow-y: scroll

        .details_asset_footer
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            z-index: 2
            height: 68px
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 24px
            box-shadow: var(--shadow-600)
            background: var(--background-light)

    .link_card
        min-width: 313px
        max-width: 340px
        position: fixed
        z-index: 10
        left: 16px
        top: 50%
        transform: translateY(-50%)
        padding: 24px 24px 16px 24px
        background: var(--background-light)
        border-radius: 12px
        box-shadow: var(--shadow-300)

        .loading
            display: flex
            align-items: center
            justify-content: center
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: rgba(255, 255, 255, 0.2)

        ul
            li
                padding: 6px 4px
                border-radius: 8px
                display: flex
                align-items: center
                list-style: none
                transition: 0.3s ease-in-out
                margin-bottom: 16px

                .num
                    width: 24px
                    height: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    margin-right: 8px
                    border-radius: 50%
                    background: rgba(0, 20, 82, 0.38)
                    color: var(--text-color-white)
                    transition: 0.3s ease-in-out

                    p
                        color: var(--text-color-white)

                    svg
                        display: none

                        path
                            fill: var(--color-primary-hover-light)

                p
                    color: rgba(0, 20, 82, 0.38)
                    transition: 0.3s ease-in-out

            li.active
                background: var(--color-primary-hover-light)

                .num
                    background: var(--color-primary-dark)

                    p
                        color: var(--color-primary-hover-light)

                p
                    color: var(--color-primary-dark)

            li.confirmed
                .num
                    background: var(--color-primary-dark)

                    svg
                        display: block

                    p
                        display: none

                p
                    color: var(--color-primary-dark)


        .button-container
            display: flex
            justify-content: flex-end

            button
                &:first-child
                    margin-right: 8px

    .feeders_card
        position: fixed
        right: 0
        bottom: 0
        z-index: 10
        background: var(--background-light)
        width: 345px
        padding: 32px 12px 32px 24px
        height: calc(100vh - 92px)
        box-shadow: var(--shadow-300)

        .parents_container
            height: calc(100% - 40px)
            overflow-y: scroll
            padding-right: 12px
        .parent_box
            .parent_box_header
                font-weight: 600
                display: flex
                align-items: center
                border-radius: 8px
                padding: 8px 40px 8px 8px
                transition: 0.3s ease-in-out
                position: relative

                &:hover
                    cursor: pointer

                img
                    margin-right: 10px

                .icon
                    position: absolute
                    right: 8px
                    top: 8px
                    z-index: 2
                    transition: 0.4s ease-in-out

            .parent_box_body
                padding-bottom: 15px

                .parent_box_body_card
                    font-weight: 500

        .parent_box.active
            .parent_box_header
                background: #C7FFB9

                .icon
                    transform: rotate(90deg)

    .cabling_method_card
        position: fixed
        right: 0
        bottom: 0
        z-index: 10
        background: var(--background-light)
        width: 345px
        padding: 40px 24px
        height: calc(100vh - 92px)
        box-shadow: var(--shadow-300)

        .cabling_method_card_header
            display: flex
            align-items: flex-start
            margin-bottom: 36px

            img
                margin-right: 8px

            p
                margin-bottom: 4px

        .parents
            margin-bottom: 40px

            .parent-header
                margin-bottom: 16px
                display: flex
                align-items: center

                img
                    margin-right: 8px

            .parent-body
                .feeders-data
                    .feeder-item-header
                        padding: 8px 40px 8px 8px
                        font-weight: 500
                        transition: 0.3s ease-in-out
                        position: relative
                        border-radius: 8px

                        .icon
                            position: absolute
                            right: 8px
                            top: 8px
                            z-index: 2
                            transition: 0.4s ease-in-out

                        &:hover
                            cursor: pointer

                    .feeder-item-body
                        .line
                            margin-bottom: 16px
                            height: 1px
                            background: rgba(118, 118, 127, 0.12)

                .feeders-data.active
                    .feeder-item-header
                        background: #C7FFB9

                    .icon
                        transform: rotate(90deg)

</style>