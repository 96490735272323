<template>
    <div class="dialog-side left" id="dialog-results" ref="dialogResults" :style="resultsStyle" v-if="results || noResults">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div class="dialog-header-actions">
                    <div class="card-drag" id="drag-results">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                    <svg @click="collapseItem()" v-if="!getResultsModal.collapse" class="card-collapse" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#909194"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>collapse-fullscreen-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <g> <path d="M8,26a2,2,0,0,0-2,2.3A2.1,2.1,0,0,0,8.1,30h7.1L4.7,40.5a2,2,0,0,0-.2,2.8A1.8,1.8,0,0,0,6,44a2,2,0,0,0,1.4-.6L18,32.8v7.1A2.1,2.1,0,0,0,19.7,42,2,2,0,0,0,22,40V28a2,2,0,0,0-2-2Z"></path> <path d="M43.7,4.8a2,2,0,0,0-3.1-.2L30,15.2V8.1A2.1,2.1,0,0,0,28.3,6,2,2,0,0,0,26,8V20a2,2,0,0,0,2,2H39.9A2.1,2.1,0,0,0,42,20.3,2,2,0,0,0,40,18H32.8L43.4,7.5A2.3,2.3,0,0,0,43.7,4.8Z"></path> </g> </g> </g> </g></svg>
                    <svg @click="collapseItem()" v-if="getResultsModal.collapse" class="card-collapse" viewBox="0 0 24 24" fill="#909194" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" stroke="#909194" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>

                </div>

                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div class="dialog-title mb-3"> {{$t('map.60')}}</div>

                <div v-if="results" class="results-container">
                    <div v-for="result in results" :key="result.deviceID" class="results-container">
                        <div class="result-card" :class="{'active': device && device.ID == result.deviceID}"  @click="getDevice(result)">
                            <div class="image-container"><img :src="'/svg/assets/' + result.deviceType + '_single.svg'"
                                                              alt=""></div>
                            <span> {{result.deviceName}}</span>
                        </div>
                    </div>
                </div>

                <div v-if="noResults" class="no-results-container">
                    <div class="icon mb-4">
                        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 30.5H26V34.5H22V30.5ZM22 14.5H26V26.5H22V14.5ZM23.98 4.5C12.94 4.5 4 13.46 4 24.5C4 35.54 12.94 44.5 23.98 44.5C35.04 44.5 44 35.54 44 24.5C44 13.46 35.04 4.5 23.98 4.5ZM24 40.5C15.16 40.5 8 33.34 8 24.5C8 15.66 15.16 8.5 24 8.5C32.84 8.5 40 15.66 40 24.5C40 33.34 32.84 40.5 24 40.5Z" fill="#2A54CD"/>
                        </svg>
                    </div>

                    <div class="font-600 primary mb-4 text-center fs-16">{{$t('errors.4')}}</div>
                    <p class="text-center fs-16">{{$t('map.61')}}</p>
                    <p class="text-center fs-16">{{$t('map.62')}}</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        data() {
            return {
                results: null,
                device: null,
                noResults: false,
            }
        },

        computed: {
            getTreeviewModal() {
                return this.$store.getters.getTreeviewModal;
            },
            getResultsModal() {
                return this.$store.getters.getResultsModal;
            },
            resultsStyle() {
                let height = 'calc(100vh - 136px)';
                let result;
                if (this.getResultsModal.collapse) height = 'calc((100vh - 144px) / 2)';
                result = {'height': height};
                return result;
            },
        },

        mounted() {
            this.emitter.on("searchResultsData", e => {
                if(e && e.length) {
                    this.results = e;
                    this.noResults = false;
                    this.$store.commit('setResultsModal', true);
                }
                else {
                    this.results = null;
                    this.noResults = true;
                    this.$store.commit('setResultsModal', false);
                }

                this.$nextTick(() => {
                    Draggable.create("#dialog-results", {
                        trigger:"#drag-results",
                        bounds:".main-page",
                        edgeResistance:0.65,
                        inertia:true,
                        autoScroll:1,
                        onDragEnd: function(){
                            window.store.commit('setResultsPosition', this.x);
                        }
                    });
                })

            });
            this.emitter.on("closeSearchResultDialog", e => {
                if(e) {
                    this.results = null;
                    this.noResults = false;
                }
            })
        },

        methods: {
            getDevice(e) {
                this.$emit('closeAssets',  true);
                let url = `getInfoDevice/${e.deviceType}/${e.deviceID}`;
                this.axios.get(url)
                    .then(response => {
                        this.device = response.data;
                        this.$emit('showAssets',  this.device);
                    })

            },
            closeDialog() {
                this.results = null;
                this.device = null;
                this.noResults = false;
                this.$store.commit('setResultsModal', false);
            },
            collapseItem() {
                this.$store.commit('setResultsZoom', !this.getResultsModal.collapse);
            }
        }
    }
</script>

<style scoped lang="sass">
    .dialog-body
        padding-right: 18px

    .no-results-container
        border: 1px solid var(--border-middle)
        border-radius: 12px
        padding: 40px 24px
        display: flex
        flex-direction: column
        align-items: center
    .result-card
        display: inline-flex
        align-items: center
        padding: 8px 16px
        margin-bottom: 4px
        transition: 0.4s ease-in-out
        border-radius: 8px
        &:hover
            cursor: pointer
            background: var(--color-secondary-hover)
        .image-container
            margin-right: 4px
            height: 16px
            img
                width: 16px
                height: 16px
    .result-card.active
        background: #C7FFB9

    .results-container
        max-height: calc(100% - 48px)
        overflow-y: scroll

</style>