<template>
  <div class="segmented">
    <span
      v-for="radio in items"
      :key="radio[option_key]"
      class="segmented-button"
      :class="{ 'segmented-button__active': modelValue === radio[option_key] }"
      @click="setRadioValue(radio[option_key])"
    >
      {{ radio[title] }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'value',
    },
    option_key: {
      type: String,
      default: 'value',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  methods: {
    setRadioValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss">
.segmented {
  display: flex;
  border-radius: 8px;
  overflow: hidden;

  .segmented-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 50%;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-primary-disabled);
    background-color: var(--color-primary-hover-light);
    padding: 8px 45px;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__active {
      color: #fefbff;
      background-color: var(--color-primary);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
