import { required, minLength} from '@vuelidate/validators';

let reqRule = {
    required
};

let password = {
    required,
    minLength: minLength(8)
};

export {
    reqRule, password
}
