<template>
  <div class="overflow-auto" v-if="user">
    <div class="dialog" v-if="loading">
      <div class="dialog-card">
        <div class="dialog-header">Rendering data</div>
        <div class="dialog-body">
          <p>Loading there</p>

          <i-loading></i-loading>
        </div>
      </div>
    </div>
    <div class="heading">
      <div class="container">
        <div class="d-flex align-center justify-between">
          <div>
            <p class="middle font-600 primary-tonal mb-1">AIS</p>
            <router-link to="/" class="d-flex align-center">
              <svg
                class="flex-shrink-0 me-3"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="14" fill="#DBE1FF" />
                <path
                  d="M15.9996 17.2292L12.7663 13.9958L15.9996 10.7625C16.3246 10.4375 16.3246 9.9125 15.9996 9.5875C15.6746 9.2625 15.1496 9.2625 14.8246 9.5875L10.9996 13.4125C10.6746 13.7375 10.6746 14.2625 10.9996 14.5875L14.8246 18.4125C15.1496 18.7375 15.6746 18.7375 15.9996 18.4125C16.3163 18.0875 16.3246 17.5542 15.9996 17.2292Z"
                  fill="#001452"
                />
              </svg>
              <p class="fs-36">Installation Reports</p>
            </router-link>
          </div>
          <i-dropdown
            title="Title"
            :options="[
              { title: 'Excel (.xls)', img: '/svg/table.svg', value: 'xls' },
              { title: 'PDF', img: '/svg/pdf.svg', value: 'pdf' },
            ]"
            @click_option="exportData($event)"
          >
            <template #default="dropdownProps">
              <i-button
                v-bind="dropdownProps"
                img="/svg/file_upload.svg"
                color="secondary light"
                title="Export"
                medium
              />
            </template>
          </i-dropdown>
        </div>
      </div>
    </div>
    <div class="main-page reports-page">
      <div class="container">
        <div class="d-flex mb-6">
          <i-select
            v-model="filterData.installationTeam"
            :options="installationTeamOptions"
            class="me-2"
            title="Name"
            option_key="Code"
            :visible="1"
            @change="getDevicesTypeBar"
            multiple
            placeholder="Installer"
          />
          <i-select
            v-model="filterData.daysOptionsBar"
            :options="daysOptions"
            class="me-2"
            title="Name"
            option_key="Code"
            deselect
            @change="selectChange($event, (chartType = 2))"
            :rangeDateSelect="rangeDateSelectBar"
            placeholder="Date Range"
          />
          <i-select
            v-model="filterData.devicesOptionsBar"
            :options="devicesOptions"
            class="me-2"
            title="Name"
            option_key="Code"
            :visible="1"
            multiple
            @change="getDevicesTypeBar"
            placeholder="Devices"
          />
        </div>
        <div class="chart-container mb-6">
          <div class="row mb-4">
            <div class="col-6">
              <p class="fs-28 mb-2">{{ computedDeviceHeader }}</p>
              <p class="fs-16 font-600 primary-tonal" v-if="summBarItems">
                <span>{{ summBarItems }}</span> items
              </p>
              <p class="fs-16 font-600 opacity-56" v-else>No data</p>
            </div>
            <div class="col-6">
              <div class="d-flex justify-end flex-wrap">
                <i-select
                  v-model="filterData.model"
                  :options="computedModels"
                  class="me-2"
                  title="Name"
                  option_key="Code"
                  :visible="1"
                  @change="getDevicesTypeBar"
                  multiple
                  placeholder="Model"
                />
                <i-select
                  :disabled="!filterData.devicesOptionsBar.includes(2)"
                  v-model="filterData.paymentType"
                  :options="paymentTypeOptions"
                  class="me-2"
                  title="Name"
                  option_key="Code"
                  :visible="1"
                  @change="getDevicesTypeBar"
                  multiple
                  placeholder="Payment Type"
                />
                <i-select
                  :disabled="!filterData.devicesOptionsBar.includes(2)"
                  v-model="filterData.communication"
                  :options="communicationOptions"
                  class="me-2"
                  title="Name"
                  option_key="Code"
                  :visible="1"
                  @change="getDevicesTypeBar"
                  multiple
                  placeholder="Communication"
                />
                <!-- <i-chip
                  v-if="filterData.daysOptionsBar"
                  class="ml-2 mb-2"
                  :item="daysChip"
                  @chip:close="clearDays"
                />
                <i-chip
                  v-if="rangeDateSelectBar"
                  class="ml-2 mb-2"
                  :item="daysChip"
                  @chip:close="clearDays"
                />
                <i-chip
                  v-for="chip of getChips('installationTeam')"
                  :key="chip"
                  class="ml-2 mb-2"
                  :item="chip"
                  title="value"
                  @chip:close="filterChips('installationTeam', $event)"
                />
                <i-chip
                  v-for="chip of getChips('paymentType')"
                  :key="chip"
                  class="ml-2 mb-2"
                  :item="chip"
                  title="value"
                  @chip:close="filterChips('paymentType', $event)"
                />
                <i-chip
                  v-for="chip of getChips('model')"
                  :key="chip"
                  class="ml-2 mb-2"
                  :item="chip"
                  title="value"
                  @chip:close="filterChips('model', $event)"
                />
                <i-chip
                  v-for="chip of getChips('communication')"
                  :key="chip"
                  class="ml-2 mb-2"
                  :item="chip"
                  title="value"
                  @chip:close="filterChips('communication', $event)"
                /> -->
              </div>
            </div>
          </div>
          <div class="box-bar-chart mb-10">
            <div ref="barChart">
              <Bar
                v-if="summBarItems"
                class="bar-chart"
                :chart-options="chartBarOptions"
                :chart-data="chartBarData"
              />
            </div>
          </div>
          <div
            class="barChart__legends d-flex justify-center align-center"
            v-if="chartBarData.datasets.length && summBarItems"
          >
            <div
              class="me-2 ml-2"
              v-for="item of chartBarData.datasets"
              :key="item"
            >
              <span :style="{ backgroundColor: item.backgroundColor }"></span>
              {{ item.label }}
            </div>
          </div>
        </div>
        <div>
          <el-table
            :data="tableData"
            style="width: 100%"
            class="activities-table__table"
            empty-text="No data"
            @sort-change="sortChange($event)"
          >
            <el-table-column min-width="120" label="Date" sortable>
              <template #default="scope">
                <p class="fs-14">{{ scope.row.date }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Installer" sortable>
              <template #default="scope">
                <p class="fs-14">
                  {{ scope.row.name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Amount" sortable>
              <template #default="scope">
                <p class="fs-14">{{ scope.row.amount }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Phase" sortable>
              <template #default="scope">
                <p class="fs-14">
                  {{ scope.row.phase[0] ? scope.row.phase[0] : '-' }}
                  <span
                    class="more-items ml-2"
                    v-tooltip="
                      scope.row.phase
                        .slice(-(scope.row.phase.length - 1))
                        .join(';\n')
                    "
                    v-if="scope.row.phase.length > 1"
                  >
                    +{{ scope.row.phase.length - 1 }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Model">
              <template #default="scope">
                <p class="fs-14">
                  {{ scope.row.model[0] }}
                  <span
                    class="more-items ml-2"
                    v-tooltip="{
                      content: scope.row.model
                        .slice(-(scope.row.model.length - 1))
                        .join(';\n'),
                      html: true,
                    }"
                    v-if="scope.row.model.length > 1"
                  >
                    +{{ scope.row.model.length - 1 }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Payment Type">
              <template #default="scope">
                <p class="fs-14">
                  {{ scope.row.payment[0] ? scope.row.payment[0] : '-' }}
                  <span
                    class="more-items ml-2"
                    v-tooltip="
                      scope.row.payment
                        .slice(-(scope.row.payment.length - 1))
                        .join(';\n')
                    "
                    v-if="scope.row.payment.length > 1"
                  >
                    +{{ scope.row.payment.length - 1 }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Area">
              <template #default="scope">
                <p class="fs-14">
                  {{ scope.row.area[0] ? scope.row.area[0] : '-' }}
                  <span
                    class="more-items ml-2"
                    v-tooltip="
                      scope.row.area
                        .slice(-(scope.row.area.length - 1))
                        .join(';\n')
                    "
                    v-if="scope.row.area.length > 1"
                  >
                    +{{ scope.row.area.length - 1 }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Communication">
              <template #default="scope">
                <p class="fs-14">
                  {{
                    scope.row.communication[0]
                      ? scope.row.communication[0]
                      : '-'
                  }}
                  <span
                    class="more-items ml-2"
                    v-tooltip="
                      scope.row.communication
                        .slice(-(scope.row.communication.length - 1))
                        .join(';\n')
                    "
                    v-if="scope.row.communication.length > 1"
                  >
                    +{{ scope.row.communication.length - 1 }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column min-width="80">
              <template #default="scope">
                <i-button
                  text
                  @click="showDevice(scope)"
                  title="Show map"
                  img="/svg/location.svg"
                  reverse
                ></i-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="d-flex justify-between">
          <div class="activities-table__heading">
            <p class="fs-16 font-600">
              Results: <span>{{ tableData.length }}</span>
            </p>
            <div class="d-flex align-center">
              <p class="fs-16">Show:</p>
              <i-select
                v-model="filterData.limit"
                :options="showElTableData"
                title="name"
                option_key="code"
                class="ml-1"
              ></i-select>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="numberOfTeams"
            :page-size="filterData.limit"
            @current-change="changeCurrentPage"
            :current-page="filterData.page + 1"
          />
        </div>
      </div>
    </div>

    <div class="date_picker_overlay date_picker__range" v-if="date_picker">
      <div class="date_picker_card">
        <i-button @click="clearDate" icon img="/svg/close.svg"></i-button>
        <p class="fs-12 mb-2">Select range</p>
        <div class="d-flex align-center" v-if="this.rangeDate.start">
          <p class="fs-24">{{ this.rangeStart }} - {{ this.rangeEnd }}</p>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.71 17.6305C33.1 18.0205 33.1 18.6505 32.71 19.0405L30.88 20.8705L27.13 17.1205L28.96 15.2905C29.35 14.9005 29.98 14.9005 30.37 15.2905L32.71 17.6305ZM15 33.0005V29.2505L26.06 18.1905L29.81 21.9405L18.75 33.0005H15Z"
              fill="#45464E"
            />
          </svg>
        </div>
        <DatePicker
          v-model="rangeDate"
          is-range
          :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"
          :columns="2"
          :max-date="new Date()"
        />
        <div class="button-container">
          <i-button
            small
            @click="getData()"
            :title="$t('buttons.1')"
          ></i-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { hoverSegment } from '@/plugins/chartSegmentBg';
import { stringToColor } from '@/utils/stringToColor';

ChartJS.register(
  Title,
  Tooltip,
  BarElement,
  LinearScale,
  Legend,
  ArcElement,
  CategoryScale,
  hoverSegment
);

export default {
  components: { DatePicker, Bar },
  data() {
    return {
      loading: true,
      chartType: 0,
      responseBarData: [],
      responseFilteredData: [],
      chartBarDataFormat: [],
      summBarItems: 0,
      chartBarData: {
        labels: [],
        datasets: [],
      },
      tableData: [],
      showElTableData: [
        { name: 10, code: 10 },
        { name: 15, code: 15 },
        { name: 20, code: 20 },
        { name: 30, code: 30 },
      ],
      chartBarOptions: null,
      charLabelData: {},
      filterData: {
        daysOptionsBar: 6,
        devicesOptionsBar: [2, 3],
        responsibleBar: null,
        installationTeam: [],
        paymentType: [],
        communication: [],
        model: [],
        limit: 15,
        page: 0,
      },
      daysOptions: [
        { Name: '24 Hours', Code: 1 },
        { Name: 'Last 7 Days', Code: 6 },
        { Name: 'Last 30 Days', Code: 29 },
        { Name: 'Custom Range', Code: 0 },
      ],
      devicesOptions: [
        { Name: 'Meter', Code: 2 },
        { Name: 'DCU', Code: 3 },
      ],
      installationTeamOptions: [],
      modelOptions: [],
      paymentTypeOptions: [
        {
          Name: 'Postpaid',
          Code: 0,
        },
        {
          Name: 'Prepaid',
          Code: 1,
        },
      ],
      communicationOptions: [],
      responsibleOptions: [],
      date_picker: false,
      chartsDate: {
        start: '',
        end: moment(new Date()).format('YYYY-MM-DD 23:59:59Z'),
      },
      rangeDate: {
        start: '',
        end: '',
      },
      exportDate: {
        start: '',
        end: moment(new Date()).format('YYYY-MM-DD 23:59:59Z'),
      },
      rangeDateSelectBar: null,
    };
  },

  computed: {
    rangeDateSelect() {
      if (this.rangeDate.start && this.rangeDate.end) {
        return {
          start: moment(this.rangeDate.start).format('DD/MM/YYYY'),
          end: moment(this.rangeDate.end).format('DD/MM/YYYY'),
        };
      } else return null;
    },
    computedModels() {
      let index = this.modelOptions.findIndex((item) => item.Name === 'DCU');
      if (this.filterData.devicesOptionsBar.length > 1) {
        return this.modelOptions;
      }
      if (this.filterData.devicesOptionsBar.includes(3)) {
        return this.modelOptions.slice(index, this.modelOptions.length);
      }

      return this.modelOptions.slice(0, index);
    },
    rangeStart() {
      return moment(this.rangeDate.start).format('MMM D');
    },
    rangeEnd() {
      return moment(this.rangeDate.end).format('MMM D');
    },
    user() {
      return this.$store.getters.getUser;
    },
    daysChip() {
      if (
        this.filterData.daysOptionsBar === 0 &&
        this.rangeDateSelectBar.start &&
        this.rangeDateSelectBar.end
      ) {
        return {
          value:
            this.rangeDateSelectBar.start + ' - ' + this.rangeDateSelectBar.end,
        };
      }
      if (this.filterData.daysOptionsBar) {
        const value = this.daysOptions.find(
          (item) => item.Code === this.filterData.daysOptionsBar
        );
        return { value: value.Name };
      }
      return null;
    },
    computedDeviceHeader() {
      if (this.filterData.devicesOptionsBar.length > 1) {
        return 'Installation devices';
      }

      if (this.filterData.devicesOptionsBar.includes(3)) {
        return "Installation DCU's";
      }

      return 'Installation meters';
    },
    numberOfTeams() {
      return this.filterData.installationTeam.length
        ? this.filterData.installationTeam.length
        : this.installationTeamOptions.length;
    },
  },

  watch: {
    'filterData.devicesOptionsBar': {
      handler() {
        this.clearFilterData();
      },
      deep: true,
    },
  },

  mounted() {
    this.loading = false;
    this.init();
    this.getDevicesDate();
  },

  methods: {
    changeCurrentPage(page) {
      this.filterData.page = page - 1;
    },

    getCharParams() {
      const labelsData = this.charLabelData;
      this.chartBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        onHover: (e, els) => {
          if (els.length) {
            e.chart.tooltip.options.mode = 'x';
          } else {
            e.chart.tooltip.options.mode = 'index';
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            interaction: {
              intersect: false,
              mode: 'index',
            },
            yAlign: 'bottom',
            backgroundColor: '#2F3033',
            titleColor: '#F1F0F4',
            titleFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '500',
            },
            bodyColor: '#F1F0F4',
            bodyFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '400',
            },
            padding: 8,
            displayColors: false,
            callbacks: {
              label: function (context) {
                if (this.options.mode === 'index') {
                  return `${context.dataset.label}: ${
                    labelsData[context.dataset.label][context.dataIndex].dcu
                  } DCU, ${
                    labelsData[context.dataset.label][context.dataIndex].meter
                  } meters`;
                }
                return '+' + context.formattedValue + ` devices`;
              },
              title: function (title) {
                if (this.options.mode === 'index') {
                  let sum = title.reduce(
                    (sum, current) => sum + current.raw,
                    0
                  );

                  return `Total - (${sum} devices)`;
                }
                return title[0].dataset.label;
              },
            },
          },
          hoverSegment,
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10,
            },
          },
        },
      };
    },

    generateTableData() {
      const dateRange = `${moment(this.chartsDate.start).format(
        'DD.MM.YYYY'
      )} - ${moment(this.chartsDate.end).format('DD.MM.YYYY')}`;

      const teams = this.groupByTeams();
      let tableData = [];
      for (let team in teams) {
        let amount = 0,
          phase = [],
          model = [],
          communication = [],
          payment = [],
          area = [];

        teams[team].data.forEach((item) => {
          amount += item.quantityInstallation;
          item.phaseName &&
            !phase.includes(item.phaseName) &&
            phase.push(item.phaseName);
          item.modelName &&
            !model.includes(item.modelName) &&
            model.push(item.modelName);
          item.communicationName &&
            !communication.includes(item.communicationName) &&
            communication.push(item.communicationName);
          item.areaName &&
            !area.includes(item.areaName) &&
            area.push(item.areaName);
          if (item.isPrepay !== undefined || item.isPrepay !== null) {
            let paymentType = item.isPrepay ? 'Prepaid' : 'Postpaid';
            !payment.includes(paymentType) && payment.push(paymentType);
          }
        });

        tableData.push({
          date: dateRange,
          name: teams[team].label,
          amount,
          phase,
          model,
          payment,
          area,
          communication,
        });
      }
      this.tableData = tableData;
    },

    filteredImportData() {
      this.responseFilteredData = [...this.responseBarData];

      this.filterData.devicesOptionsBar.length &&
        (this.responseFilteredData = this.responseFilteredData.filter((item) =>
          this.filterData.devicesOptionsBar.includes(item.deviceType)
        ));

      this.filterData.communication.length &&
        (this.responseFilteredData = this.responseFilteredData.filter(
          (item) => {
            if (item.deviceType === 2) {
              return this.filterData.communication.includes(
                item.communicationTypeID
              );
            } else {
              return true;
            }
          }
        ));

      this.filterData.model.length &&
        (this.responseFilteredData = this.responseFilteredData.filter((item) =>
          this.filterData.model.includes(item.modelID)
        ));

      this.filterData.installationTeam.length &&
        (this.responseFilteredData = this.responseFilteredData.filter((item) =>
          this.filterData.installationTeam.includes(item.userID)
        ));

      this.filterData.paymentType.length &&
        (this.responseFilteredData = this.responseFilteredData.filter(
          (item) => {
            if (item.deviceType === 2) {
              return this.filterData.paymentType.includes(
                item.isPrepay ? 1 : 0
              );
            } else {
              return true;
            }
          }
        ));

      this.chartsDate.start &&
        (this.responseFilteredData = this.responseFilteredData.filter(
          (item) => item.dateInstallation >= this.chartsDate.start
        ));
    },

    groupByTeams() {
      const charDatasets = {};
      this.responseFilteredData.forEach((obj) => {
        if (!charDatasets[`team_${obj.userID}`]) {
          charDatasets[`team_${obj.userID}`] = {
            label: obj.userName,
            backgroundColor: stringToColor(
              obj.userName + obj.userID * Math.E ** 0.2
            ),
            data: [],
          };
        }
        charDatasets[`team_${obj.userID}`].data.push(obj);
      });
      return charDatasets;
    },

    exportData(type) {
      const params = {};
      params.DateFrom = this.exportDate.start;
      params.DateTo = this.exportDate.end;
      params.ActionID = 1;

      this.$swal
        .fire({
          title: 'Export',
          text: `Export to ${type.title}?`,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log(type.value);
          }
        });
      // this.axios
      //   .post(`exportReport`, params, { responseType: 'blob' })
      //   .then((response) => {
      //     if (response) {
      //       let url = URL.createObjectURL(
      //         new Blob([response.data], {
      //           type: 'application/excel',
      //         })
      //       );
      //       let link = document.createElement('a');
      //       link.style.position = 'absolute';
      //       link.style.left = '-9999px';
      //       link.href = url;
      //       link.setAttribute('download', 'UserReport.xls');
      //       document.body.appendChild(link);
      //       link.click();
      //     }
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       console.log(error);
      //     }
      //   });
    },

    getData() {
      let rangeDate = {
        start: moment(this.rangeDate.start).format('DD/MM/YYYY'),
        end: moment(this.rangeDate.end).format('DD/MM/YYYY'),
      };
      this.chartsDate.start = moment(this.rangeDate.start).format(
        'YYYY-MM-DD 00:00:00Z'
      );
      this.chartsDate.end = moment(this.rangeDate.end).format(
        'YYYY-MM-DD 23:59:59Z'
      );
      this.rangeDateSelectBar = rangeDate;
      this.getDevicesDate();
    },

    selectChange(e) {
      if (this.filterData.daysOptionsBar === 0) {
        this.date_picker = true;
      } else {
        this.rangeDateSelectBar = null;
        let newDate = new Date();
        newDate.setDate(newDate.getDate() - this.filterData.daysOptionsBar);
        if (this.filterData.daysOptionsBar !== 1) {
          this.chartsDate.start = moment(newDate).format(
            'YYYY-MM-DD 00:00:00Z'
          );
          this.chartsDate.end = moment(new Date()).format(
            'YYYY-MM-DD 23:59:59Z'
          );
        } else {
          this.chartsDate.start = moment(newDate).format(
            'YYYY-MM-DD HH:mm:ssZ'
          );
          this.chartsDate.end = moment(new Date()).format(
            'YYYY-MM-DD HH:mm:ssZ'
          );
        }
        this.getDevicesDate();
      }

      if (e.Code !== 0) {
        this.rangeDate = {
          start: '',
          end: '',
        };
      }
    },

    clearDate() {
      this.date_picker = false;
      this.rangeDate.start = '';
      this.rangeDate.end = '';
    },

    getBarData() {
      this.chartBarDataFormat.labels = [];
      if (this.filterData.daysOptionsBar === 1) {
        let newDate = moment(new Date()).format('YYYY-MM-DD HH');
        let getDate = moment(new Date())
          .subtract(23, 'h')
          .format('YYYY-MM-DD HH');
        this.arrayBarDate(newDate, getDate);
      } else if (this.filterData.daysOptionsBar === 0) {
        let newDate = moment(this.rangeDate.end).format('YYYY-MM-DD');
        let getDate = moment(this.rangeDate.start)
          .subtract(this.filterData.daysOptionsBar, 'd')
          .format('YYYY-MM-DD');
        this.arrayBarDate(newDate, getDate);
      } else {
        let newDate = moment(new Date()).format('YYYY-MM-DD');
        let getDate = moment(new Date())
          .subtract(this.filterData.daysOptionsBar, 'd')
          .format('YYYY-MM-DD');
        this.arrayBarDate(newDate, getDate);
      }
      this.getDevicesTypeBar();
    },

    arrayBarDate(newDate, getDate) {
      if (newDate >= getDate) {
        this.chartBarDataFormat.labels.unshift(newDate);
        if (this.filterData.daysOptionsBar === 1) {
          newDate = moment(newDate).subtract(1, 'h').format('YYYY-MM-DD HH');
          this.arrayBarDate(newDate, getDate);
        } else {
          newDate = moment(newDate).subtract(1, 'd').format('YYYY-MM-DD');
          this.arrayBarDate(newDate, getDate);
        }
      }
    },

    showDevice(id) {
      console.log(id);
    },

    //начальная функция
    init() {
      let newDate = new Date();
      newDate.setDate(newDate.getDate() - 6);
      this.chartsDate.start = moment(newDate).format('YYYY-MM-DD 00:00:00Z');
      this.exportDate.start = this.chartsDate.start;
    },

    getDevicesDate() {
      this.date_picker = false;
      this.loading = true;
      const params = {
        dateFrom: this.chartsDate.start,
        dateTo: this.chartsDate.end,
      };

      this.axios
        .get(`installation/electricity/report`, { params: params })
        .then((response) => {
          if (response) {
            this.responseBarData = [...response.data];
            this.getFilters();
            this.getBarData();
            this.filteredImportData();
            this.generateTableData();
            this.getCharParams();
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },

    getFilters() {
      let teams = [],
        modelMeter = [],
        modelDCU = [],
        communication = [];
      this.responseBarData.forEach((item) => {
        let findTeam = teams.find((team) => team.Code === item.userID);
        if (!findTeam) {
          teams.push({
            Name: item.userName,
            Code: item.userID,
          });
          this.filterData.installationTeam.push(item.userID);
        }

        if (item.deviceType === 2) {
          let findModel = modelMeter.find(
            (model) => model.Code === item.modelID
          );
          if (!findModel) {
            modelMeter.push({
              Name: item.modelName,
              Code: item.modelID,
            });
          }
        } else {
          let findModel = modelDCU.find((model) => model.Code === item.modelID);
          if (!findModel) {
            modelDCU.push({
              Name: item.modelName,
              Code: item.modelID,
            });
          }
        }

        let findCommunication = communication.find(
          (comm) => comm.Code === item.communicationTypeID
        );
        if (!findCommunication) {
          communication.push({
            Name: item.communicationName,
            Code: item.communicationTypeID,
          });
        }

        this.installationTeamOptions = [...teams];
        this.modelOptions = [
          { Name: 'Meters' },
          ...modelMeter,
          { Name: 'DCU' },
          ...modelDCU,
        ];
        this.communicationOptions = [...communication];
      });
    },

    getDevicesTypeBar() {
      this.filteredImportData();
      this.generateTableData();
      let charDatasets = [];
      const teams = this.groupByTeams();
      this.summBarItems = 0;

      for (let team in teams) {
        if (!this.charLabelData[teams[team].label]) {
          this.charLabelData[teams[team].label] = [];
        }

        teams[team].data = this.chartBarDataFormat.labels.map((date) => {
          return teams[team].data.filter((obj) => {
            if (this.filterData.daysOptionsBar === 1) {
              var actionDateTime =
                moment(obj.dateInstallation).format('YYYY-MM-DD') +
                ' ' +
                obj.dateInstallation;
            } else {
              actionDateTime = moment(obj.dateInstallation).format(
                'YYYY-MM-DD'
              );
            }
            if (
              this.filterData.devicesOptionsBar.includes(obj.deviceType) &&
              actionDateTime == date
            ) {
              return obj;
            }
          });
        });

        this.charLabelData[teams[team].label] = teams[team].data.map((item) => {
          return item.reduce(
            (prev, current) => {
              if (current.deviceType === 2) {
                prev.meter += current.quantityInstallation;
              } else {
                prev.dcu += current.quantityInstallation;
              }
              return prev;
            },
            { meter: 0, dcu: 0 }
          );
        });
        teams[team].data = teams[team].data.map((item) =>
          item
            .map((el) => el.quantityInstallation)
            .reduce((prev, current) => prev + current, 0)
        );

        this.summBarItems = teams[team].data.reduce(
          (prev, current) => prev + current,
          this.summBarItems
        );
        charDatasets.push(teams[team]);
      }

      this.chartBarData.datasets = charDatasets;
      if (this.filterData.daysOptionsBar === 1) {
        this.chartBarData.labels = this.chartBarDataFormat.labels.map((date) =>
          moment(date).format('ddd HH')
        );
      } else {
        this.chartBarData.labels = this.chartBarDataFormat.labels.map((date) =>
          moment(date).format('MMM DD')
        );
      }

      if (this.chartBarDataFormat.labels.length > 31) {
        this.$refs.barChart.style.width =
          3.3 * this.chartBarDataFormat.labels.length + '%';
      } else {
        this.$refs.barChart.style.width = '100%';
      }
    },

    clearFilterData() {
      this.filterData.communication = [];
      this.filterData.paymentType = [];
      this.filterData.model = [];
    },

    clearDays() {
      this.filterData.daysOptionsBar = null;
      this.rangeDateSelectBar = null;
      this.clearDate();
    },

    getChips(key) {
      const teams = this.filterData[key].map((item) => {
        const find = this[key + 'Options'].filter((el) => el.Code === item);
        return {
          value: find[0].Name,
          id: find[0].Code,
        };
      });
      return teams;
    },

    // sortChange(e) {
    //             if (e.order) {
    //                 this.devicesData.sort((a, b) => a[e.prop].localeCompare(b[e.prop], undefined, {
    //                     numeric: true,
    //                     sensitivity: "base"
    //                 }))
    //                 if (e.order == 'descending') {
    //                     this.devicesData.reverse();
    //                 }
    //             } else {
    //                 this.devicesData = [...this.originalDevicesData];
    //             }
    //         },

    filterChips(key, item) {
      this.filterData[key] = this.filterData[key].filter(
        (el) => item.id !== el
      );
      if (key !== 'installationTeam') {
        this.getDevicesDate();
      } else {
        this.getDevicesTypeBar();
      }
    },
  },
};
</script>
<style lang="scss">
.v-popper--theme-tooltip {
  .v-popper__inner {
    padding: 6px 8px;
    border-radius: 8px;
    font-size: 12px;
    background-color: var(--background-tooltip);
    div {
      white-space: pre-line;
    }
  }
}
.more-items {
  cursor: default;
  padding: 4px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-primary-hover-light);
}
</style>
