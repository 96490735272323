<template>
  <div class="overflow-auto" v-if="user">
    <div class="dialog" v-if="loading">
      <div class="dialog-card">
        <div class="dialog-header">Rendering data</div>
        <div class="dialog-body">
          <p>Loading there</p>

          <i-loading></i-loading>
        </div>
      </div>
    </div>
    <div class="heading">
      <div class="container">
        <div class="d-flex align-center justify-between">
          <div>
            <p class="middle font-600 primary-tonal mb-1">AIS</p>
            <router-link to="/" class="d-flex align-center">
              <svg
                class="flex-shrink-0 me-3"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="14" fill="#DBE1FF" />
                <path
                  d="M15.9996 17.2292L12.7663 13.9958L15.9996 10.7625C16.3246 10.4375 16.3246 9.9125 15.9996 9.5875C15.6746 9.2625 15.1496 9.2625 14.8246 9.5875L10.9996 13.4125C10.6746 13.7375 10.6746 14.2625 10.9996 14.5875L14.8246 18.4125C15.1496 18.7375 15.6746 18.7375 15.9996 18.4125C16.3163 18.0875 16.3246 17.5542 15.9996 17.2292Z"
                  fill="#001452"
                />
              </svg>
              <p class="fs-36">Reports</p>
            </router-link>
          </div>
          <i-button
            @click="exportData()"
            img="/svg/file_upload.svg"
            color="secondary light"
            title="Export"
            medium
          ></i-button>
        </div>
      </div>
    </div>
    <div class="main-page reports-page">
      <div class="container">
        <div class="row">
          <div class="col-4">
            <div class="chart-container">
              <p class="fs-28">Total Assets</p>
              <!--                            <p class="material-thee-color middle mb-4">-->
              <!--                                <span class="fw-600">15%</span> higher than last week-->
              <!--                            </p>-->
              <div class="d-flex mb-4 mt-12">
                <i-select
                  v-model="filterData.daysOptionsPie"
                  :options="daysOptions"
                  title="Name"
                  option_key="Code"
                  deselect
                  @change="selectChange($event, (chartType = 1))"
                  :rangeDateSelect="rangeDateSelectPie"
                  class="me-2"
                ></i-select>
                <i-select
                  v-model="filterData.responsiblePie"
                  :options="responsibleOptions"
                  title="name"
                  @change="drawingChartPie()"
                  option_key="userId"
                  placeholder="Responsible"
                ></i-select>
              </div>
              <div
                v-if="
                  chartPieData.datasets[0].data.length &&
                  chartPieData.datasets[0].data.reduce((a, b) => a + b) > 0
                "
              >
                <Pie
                  class="pie-chart mb-4"
                  :chart-options="chartPieOptions"
                  :chart-data="chartPieData"
                />
                <div class="array-devices">
                  <div
                    v-for="(item, i) in legendsPie"
                    :key="i"
                    class="array-devices__single"
                  >
                    <div
                      :style="'background-color:' + item.color"
                      class="color"
                    ></div>
                    <p class="fs-12">
                      {{ item.name }}
                      <span class="font-500">{{ item.amount }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="box-no-data">
                <svg
                  class="mb-4"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 30.5H26V34.5H22V30.5ZM22 14.5H26V26.5H22V14.5ZM23.98 4.5C12.94 4.5 4 13.46 4 24.5C4 35.54 12.94 44.5 23.98 44.5C35.04 44.5 44 35.54 44 24.5C44 13.46 35.04 4.5 23.98 4.5ZM24 40.5C15.16 40.5 8 33.34 8 24.5C8 15.66 15.16 8.5 24 8.5C32.84 8.5 40 15.66 40 24.5C40 33.34 32.84 40.5 24 40.5Z"
                    fill="#2A54CD"
                  />
                </svg>
                <p class="primary-tonal font-600 fs-16 mb-4">
                  No matching data found
                </p>
                <p class="fs-14">
                  Try adjusting your filters to find relevant results
                </p>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="chart-container">
              <div class="mb-4">
                <p class="fs-28 mb-2">Assets Added by Type</p>
                <p class="fs-16 font-600 opacity-56" v-if="summBarItems">
                  <span>{{ summBarItems }}</span> items
                </p>
                <p class="fs-16 font-600 opacity-56" v-else>No data</p>
              </div>

              <div class="d-flex mb-12">
                <i-select
                  v-model="filterData.daysOptionsBar"
                  :options="daysOptions"
                  class="me-2"
                  title="Name"
                  option_key="Code"
                  deselect
                  @change="selectChange($event, (chartType = 2))"
                  :rangeDateSelect="rangeDateSelectBar"
                >
                </i-select>
                <i-select
                  v-model="filterData.devicesOptionsBar"
                  :options="devicesOptions"
                  title="Name"
                  option_key="Code"
                  deselect
                  class="me-2"
                >
                </i-select>
                <i-select
                  v-model="filterData.responsibleBar"
                  :options="responsibleOptions"
                  title="name"
                  @change="getDevicesTypeBar()"
                  option_key="userId"
                  placeholder="Responsible"
                ></i-select>
              </div>
              <div class="box-bar-chart">
                <div ref="barChart">
                  <Bar
                    class="bar-chart"
                    :chart-options="chartBarOptions"
                    :chart-data="chartBarData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="date_picker_overlay date_picker__range" v-if="date_picker">
      <div class="date_picker_card">
        <i-button @click="clearDate" icon img="/svg/close.svg"></i-button>
        <p class="fs-12 mb-2">Select range</p>
        <div class="d-flex align-center" v-if="this.rangeDate.start">
          <p class="fs-24">{{ this.rangeStart }} - {{ this.rangeEnd }}</p>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.71 17.6305C33.1 18.0205 33.1 18.6505 32.71 19.0405L30.88 20.8705L27.13 17.1205L28.96 15.2905C29.35 14.9005 29.98 14.9005 30.37 15.2905L32.71 17.6305ZM15 33.0005V29.2505L26.06 18.1905L29.81 21.9405L18.75 33.0005H15Z"
              fill="#45464E"
            />
          </svg>
        </div>
        <DatePicker
          v-model="rangeDate"
          is-range
          :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"
          :columns="2"
          :max-date="new Date()"
        />
        <div class="button-container">
          <i-button
            small
            @click="getData()"
            :title="$t('buttons.1')"
          ></i-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import { Bar, Pie } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  BarElement,
  LinearScale,
  Legend,
  ArcElement,
  CategoryScale
);

export default {
  components: { DatePicker, Bar, Pie },
  data() {
    return {
      loading: true,
      chartType: 0,
      responsePieData: [],
      chartPieData: {
        labels: [
          'Substation',
          'Transformer',
          'Pole',
          'Cabin',
          'Pillar',
          'Box',
          'Service Point',
        ],
        datasets: [
          {
            backgroundColor: [
              '#736CC3',
              '#2284DF',
              '#009AEC',
              '#00AEE9',
              '#00BED6',
              '#00CCB6',
              '#00D78F',
            ],
            data: [],
            hoverOffset: 15,
          },
        ],
      },
      chartPieOptions: {
        layout: {
          padding: 20,
        },
        responsive: true,
        maintainAspectRatio: true,
        borderWidth: 0,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#2F3033',
            titleColor: '#F1F0F4',
            titleFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '500',
            },
            bodyColor: '#F1F0F4',
            bodyFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '400',
            },
            padding: 8,
            displayColors: false,
            callbacks: {
              label: function (context) {
                return '+' + context.parsed + ' items';
              },
              title: function (title) {
                return title[0].label;
              },
            },
          },
          hoverSegment: false,
        },
      },

      responseBarData: [],
      summBarItems: '',
      chartBarDataFormat: [],

      chartBarData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#2A54CD',
            data: [],
          },
        ],
      },

      chartBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#2F3033',
            titleColor: '#F1F0F4',
            titleFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '500',
            },
            bodyColor: '#F1F0F4',
            bodyFont: {
              family: "'IBM Plex Sans', sans-serif",
              weight: '400',
            },
            padding: 8,
            displayColors: false,
            callbacks: {
              label: function (context) {
                return '+' + context.formattedValue + ' items';
              },
              title: function (title) {
                return title[0].dataset.label;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
      devicesList: [100, 102, 104, 111, 115, 113, 112],

      filterData: {
        daysOptionsPie: 6,
        daysOptionsBar: 6,
        devicesOptionsBar: 100,
        responsiblePie: null,
        responsibleBar: null,
      },
      daysOptions: [
        { Name: '24 Hours', Code: 1 },
        { Name: 'Last 7 Days', Code: 6 },
        { Name: 'Last 30 Days', Code: 29 },
        { Name: 'Custom Range', Code: 0 },
      ],
      devicesOptions: [
        { Name: 'Substation', Code: 100 },
        { Name: 'Transformer', Code: 102 },
        { Name: 'Pole', Code: 104 },
        { Name: 'Cabin', Code: 111 },
        { Name: 'Pillar', Code: 115 },
        { Name: 'Box', Code: 113 },
        { Name: 'Service Point', Code: 112 },
      ],
      responsibleOptions: [],
      date_picker: false,
      chartsDate: {
        start: '',
        end: moment(new Date()).format('YYYY-MM-DD 23:59:59Z'),
      },
      rangeDate: {
        start: '',
        end: '',
      },
      exportDate: {
        start: '',
        end: moment(new Date()).format('YYYY-MM-DD 23:59:59Z'),
      },
      rangeDateSelectPie: null,
      rangeDateSelectBar: null,
    };
  },

  computed: {
    rangeDateSelect() {
      if (this.rangeDate.start && this.rangeDate.end) {
        return {
          start: moment(this.rangeDate.start).format('DD/MM/YYYY'),
          end: moment(this.rangeDate.end).format('DD/MM/YYYY'),
        };
      } else return null;
    },
    rangeStart() {
      return moment(this.rangeDate.start).format('MMM D');
    },
    rangeEnd() {
      return moment(this.rangeDate.end).format('MMM D');
    },
    legendsPie() {
      return this.chartPieData.labels.map((el, i) => {
        return {
          name: el,
          amount: this.chartPieData.datasets[0].data[i],
          color: this.chartPieData.datasets[0].backgroundColor[i],
        };
      });
    },
    user() {
      return this.$store.getters.getUser;
    },
  },

  watch: {
    'filterData.devicesOptionsBar'() {
      this.chartBarData.datasets[0].label = this.devicesOptions.find(
        (el) => el.Code === this.filterData.devicesOptionsBar
      ).Name;
      this.getDevicesTypeBar();
    },
  },

  mounted() {
    this.init();
    this.getDevicesDate();
  },

  methods: {
    exportData() {
      const params = {};
      params.DateFrom = this.exportDate.start;
      params.DateTo = this.exportDate.end;
      if (this.filterData.responsiblePie) {
        params.UserID = this.filterData.responsiblePie;
      }
      params.ActionID = 1;
      this.axios
        .post(`exportReport`, params, { responseType: 'blob' })
        .then((response) => {
          if (response) {
            let url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/excel',
              })
            );
            let link = document.createElement('a');
            link.style.position = 'absolute';
            link.style.left = '-9999px';
            link.href = url;
            link.setAttribute('download', 'UserReport.xls');
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },

    drawingChartPie() {
      if (this.responsePieData.length) {
        let pieData = this.devicesList
          .map((id) => {
            if (this.filterData.responsiblePie) {
              var array = this.responsePieData
                .filter((el) => el.UserID == this.filterData.responsiblePie)
                .map((devices) => {
                  if (devices.TypeID === id) {
                    return devices;
                  } else {
                    return {
                      NumberOfAssets: 0,
                    };
                  }
                });
            } else {
              array = this.responsePieData.map((devices) => {
                if (devices.TypeID === id) {
                  return devices;
                } else {
                  return {
                    NumberOfAssets: 0,
                  };
                }
              });
            }
            return array.map((el) => el.NumberOfAssets);
          })
          .filter((el) => el.length);
        if (pieData.length) {
          this.chartPieData.datasets[0].data = pieData.map((el) => {
            if (el.length) {
              return el.reduce((a, b) => a + b);
            }
          });
        } else {
          this.chartPieData.datasets[0].data = [];
        }
      } else {
        this.chartPieData.datasets[0].data = [];
      }
    },

    getData() {
      let rangeDate = {
        start: moment(this.rangeDate.start).format('DD/MM/YYYY'),
        end: moment(this.rangeDate.end).format('DD/MM/YYYY'),
      };
      this.chartsDate.start = moment(this.rangeDate.start).format(
        'YYYY-MM-DD 00:00:00Z'
      );
      this.chartsDate.end = moment(this.rangeDate.end).format(
        'YYYY-MM-DD 23:59:59Z'
      );
      if (this.chartType === 1) {
        this.rangeDateSelectPie = rangeDate;
        this.exportDate.start = this.chartsDate.start;
        this.exportDate.end = this.chartsDate.end;
      } else if (this.chartType === 2) {
        this.rangeDateSelectBar = rangeDate;
      }
      this.getDevicesDate();
    },

    selectChange(e) {
      if (this.chartType === 2) {
        if (this.filterData.daysOptionsBar === 0) {
          this.date_picker = true;
        } else {
          this.rangeDateSelectBar = null;
          let newDate = new Date();
          newDate.setDate(newDate.getDate() - this.filterData.daysOptionsBar);
          if (this.filterData.daysOptionsBar !== 1) {
            this.chartsDate.start = moment(newDate).format(
              'YYYY-MM-DD 00:00:00Z'
            );
            this.chartsDate.end = moment(new Date()).format(
              'YYYY-MM-DD 23:59:59Z'
            );
          } else {
            this.chartsDate.start = moment(newDate).format(
              'YYYY-MM-DD HH:mm:ssZ'
            );
            this.chartsDate.end = moment(new Date()).format(
              'YYYY-MM-DD HH:mm:ssZ'
            );
          }
          this.getDevicesDate();
        }
      } else if (this.chartType === 1) {
        if (this.filterData.daysOptionsPie === 0) {
          this.date_picker = true;
        } else {
          this.rangeDateSelectPie = null;
          let newDate = new Date();
          newDate.setDate(newDate.getDate() - this.filterData.daysOptionsPie);
          if (this.filterData.daysOptionsPie !== 1) {
            this.chartsDate.start = moment(newDate).format(
              'YYYY-MM-DD 00:00:00Z'
            );
            this.chartsDate.end = moment(new Date()).format(
              'YYYY-MM-DD 23:59:59Z'
            );
            this.exportDate.start = this.chartsDate.start;
            this.exportDate.end = this.chartsDate.end;
          } else {
            this.chartsDate.start = moment(newDate).format(
              'YYYY-MM-DD HH:mm:ssZ'
            );
            this.chartsDate.end = moment(new Date()).format(
              'YYYY-MM-DD HH:mm:ssZ'
            );
            this.exportDate.start = this.chartsDate.start;
            this.exportDate.end = this.chartsDate.end;
          }
          this.getDevicesDate();
        }
      }
      if (e.Code !== 0) {
        this.rangeDate = {
          start: '',
          end: '',
        };
      }
    },

    clearDate() {
      this.date_picker = false;
      this.rangeDate.start = '';
      this.rangeDate.end = '';
    },

    getBarData() {
      this.chartBarDataFormat.labels = [];
      if (this.filterData.daysOptionsBar === 1) {
        let newDate = moment(new Date()).format('YYYY-MM-DD HH');
        let getDate = moment(new Date())
          .subtract(23, 'h')
          .format('YYYY-MM-DD HH');
        this.arrayBarDate(newDate, getDate);
      } else if (this.filterData.daysOptionsBar === 0) {
        let newDate = moment(this.rangeDate.end).format('YYYY-MM-DD');
        let getDate = moment(this.rangeDate.start)
          .subtract(this.filterData.daysOptionsBar, 'd')
          .format('YYYY-MM-DD');
        this.arrayBarDate(newDate, getDate);
      } else {
        let newDate = moment(new Date()).format('YYYY-MM-DD');
        let getDate = moment(new Date())
          .subtract(this.filterData.daysOptionsBar, 'd')
          .format('YYYY-MM-DD');
        this.arrayBarDate(newDate, getDate);
      }
      this.getDevicesTypeBar();
    },

    arrayBarDate(newDate, getDate) {
      if (newDate >= getDate) {
        this.chartBarDataFormat.labels.unshift(newDate);
        if (this.filterData.daysOptionsBar === 1) {
          newDate = moment(newDate).subtract(1, 'h').format('YYYY-MM-DD HH');
          this.arrayBarDate(newDate, getDate);
        } else {
          newDate = moment(newDate).subtract(1, 'd').format('YYYY-MM-DD');
          this.arrayBarDate(newDate, getDate);
        }
      }
    },

    init() {
      this.chartBarData.datasets[0].label = this.devicesOptions.find(
        (el) => el.Code === this.filterData.devicesOptionsBar
      ).Name;

      let newDate = new Date();
      newDate.setDate(newDate.getDate() - 6);
      this.chartsDate.start = moment(newDate).format('YYYY-MM-DD 00:00:00Z');
      this.exportDate.start = this.chartsDate.start;

      let params = {
        areaID: localStorage.getItem('areaid'),
      };
      this.axios
        .get(`getUsers`, { params: params })
        .then((response) => {
          if (response) {
            this.responsibleOptions = response.data.map((el) => {
              return {
                name: el.userName,
                userId: el.userID,
              };
            });
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },

    getDevicesDate() {
      this.date_picker = false;
      this.loading = true;
      let params = {
        ActionID: 1,
        DateFrom: this.chartsDate.start,
        DateTo: this.chartsDate.end,
      };
      this.axios.get(`getReport`, { params: params }).then((response) => {
        if (response) {
          if (this.chartType === 1) {
            this.responsePieData = [...response.data];
            this.drawingChartPie();
          } else if (this.chartType === 2) {
            this.responseBarData = [...response.data];
            this.getBarData();
          } else if (this.chartType === 0) {
            this.responsePieData = [...response.data];
            this.responseBarData = [...response.data];
            this.drawingChartPie();
            this.getBarData();
          }
          this.loading = false;
        }
      });
    },

    getDevicesTypeBar() {
      this.chartBarData.datasets[0].data = this.chartBarDataFormat.labels.map(
        (date) => {
          let arrayNumberOfAssets = this.responseBarData
            .filter((obj) => {
              if (this.filterData.daysOptionsBar === 1) {
                var actionDateTime =
                  moment(obj.ActionDate).format('YYYY-MM-DD') +
                  ' ' +
                  obj.ActionTime;
              } else {
                actionDateTime = moment(obj.ActionDate).format('YYYY-MM-DD');
              }
              if (
                obj.TypeID == this.filterData.devicesOptionsBar &&
                actionDateTime == date
              ) {
                if (this.filterData.responsibleBar) {
                  if (obj.UserID == this.filterData.responsibleBar) {
                    return obj;
                  }
                } else {
                  return obj;
                }
              }
            })
            .map((el) => el.NumberOfAssets);
          if (arrayNumberOfAssets.length) {
            return arrayNumberOfAssets.reduce(
              (prev, current) => prev + current
            );
          } else {
            return 0;
          }
        }
      );
      if (this.filterData.daysOptionsBar === 1) {
        this.chartBarData.labels = this.chartBarDataFormat.labels.map((date) =>
          moment(date).format('dddd HH')
        );
      } else {
        this.chartBarData.labels = this.chartBarDataFormat.labels.map((date) =>
          moment(date).format('dddd')
        );
      }
      this.summBarItems = this.chartBarData.datasets[0].data.reduce(
        (a, b) => a + b
      );
      if (this.chartBarData.datasets[0].data.length > 31) {
        this.$refs.barChart.style.width =
          3.3 * this.chartBarData.datasets[0].data.length + '%';
      } else {
        this.$refs.barChart.style.width = '100%';
      }
    },
  },
};
</script>
