<template>
    <div>
        <div v-if="step == 1">
            <h1 class="mb-6">{{$t('auth.6')}}</h1>
            <p class="mb-8 text-gray-dark">{{$t('auth.8')}}</p>

            <div class="mb-1">
                <i-input v-model="username"
                         id="name"
                         :solo="false"
                         :label="$t('auth.3')"
                         :error="v$.username.$error || serverError"
                         :errorMsg="confirmPassError"
                         icon="username"
                         :placeholder="$t('auth.5')">
                </i-input>
            </div>

            <div class="text-end mb-11">
                <i-button text middle to="/login" :title="$t('auth.9')"></i-button>
            </div>

            <i-button @click="recover()" block :title="$t('buttons.1')" :loading="loading"></i-button>
        </div>

        <div v-if="step == 2">
            <h1 class="mb-6">{{$t('auth.10')}}</h1>
            <p class="mb-8 text-gray-dark">{{$t('auth.11')}}</p>

            <div class="mb-1">
                <i-input v-model="password"
                         id="password"
                         :solo="false"
                         :label="$t('auth.12')"
                         :error="v$.password.$error || serverError"
                         :errorMsg="confirmPassError"
                         icon="password"
                         type="password"
                         :placeholder="$t('auth.5')">
                </i-input>
            </div>

            <div class="text-end mb-11">
                <i-button text middle @click="recover()" :title="$t('auth.13')"></i-button>
            </div>

            <i-button @click="reset()" block :title="$t('buttons.1')" :loading="loading"></i-button>
        </div>

        <div v-if="step == 3">
            <set-new-pass :userId="userId" :token="token"></set-new-pass>
        </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {reqRule} from "./../../services/Validation";
    import SetNewPass from './SetNewPass'

    export default {
        components: {
            SetNewPass
        },
        data() {
            return {
                url: 'forgotPassword',
                loading: false,
                step: 1,

                username: null,
                password: null,

                userId: null,
                serverError: null,

                v$: useVuelidate(),
            }
        },

        computed: {
            confirmPassError() {
                let result;
                if (this.serverError) result = this.serverError;
                else {
                    if (this.password_confirm) result = this.$t('errors.2');
                    if (!this.password_confirm) result = this.$t('errors.1');
                }
                return result;
            }
        },

        methods: {
            recover() {
                this.serverError = null;
                this.v$.username.$touch();
                if (this.v$.username.$invalid) {
                    return;
                } else {
                    this.loading = true;
                    let data = {
                        loginName: this.username,
                    };
                    this.axios.post(this.url, data)
                        .then(response => {
                            if (response && response.data.status == 200) {
                                this.password = null;
                                this.v$.username.$reset();
                                this.v$.password.$reset();
                                this.step = 2;
                                this.loading = false;
                            } else {
                                this.serverError = response.data.message;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            if (error) {
                                this.serverError = error.response.data.message;
                                this.loading = false;
                            }
                        })
                }
            },

            reset() {
                this.serverError = null;
                this.v$.password.$touch();
                if (this.v$.password.$invalid) {
                    return;
                } else {
                    this.loading = true;
                    let data = {
                        username: this.username,
                        password: this.password
                    };
                    this.axios.post('login', data)
                        .then(response => {
                            if (response && response.data.status && response.data.status == 401) {
                                this.serverError = 'Invalid password';
                                this.loading = false;
                            } else {
                                this.token = response.data.token;
                                this.userId = response.data.id;
                                // localStorage.setItem('token', this.token);
                                this.step = 3;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            if (error) {
                                this.serverError = error.response.data.message;
                                this.loading = false;
                            }
                        })
                }
            },
        },

        validations() {
            return {
                username: reqRule,
                password: reqRule,
            }
        }
    }
</script>